import React, { useEffect } from 'react';
import { Box, Typography, styled } from '@mui/material';
import { colors } from '../../ui/shared/Colors';
import { useAppDispatch } from '../../hooks';
import { setFullscreen } from '../../Redux/UI/UISlice';
import { Button } from '../../ui/buttons/Button';
import { typography } from '../../ui/shared/TypographySharedElements';
import { useNavigate } from 'react-router-dom';
import { FullPageContainer } from '../Register/Shared/Containers';

export const ResetLinkSentPage = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, []);

  return (
    <FullPageContainerWithBg>
      <ResetLinkModalContainer>
        <ResetLinkInnerModalContainer>
          <ResetLinkSentHeadingContainer>
            <ResetLinkHeadingIcon className="material-symbols-outlined">mail</ResetLinkHeadingIcon>
          </ResetLinkSentHeadingContainer>
          <ResetLinkSentTypographyContainer>
            <ResetLinkSentHeadline>Password reset link sent</ResetLinkSentHeadline>
            <ResetLinkSentSubText>
              If your email is in our system, you will recieve a password reset link. This link will expire after
              fifteen minutes.
            </ResetLinkSentSubText>
          </ResetLinkSentTypographyContainer>
        </ResetLinkInnerModalContainer>
        <ButtonContainer>
          <Button
            isPrimary={false}
            onClick={() => {
              navigate('/');
            }}
          >
            Close
          </Button>
        </ButtonContainer>
      </ResetLinkModalContainer>
    </FullPageContainerWithBg>
  );
};

const FullPageContainerWithBg = styled(FullPageContainer, {
  name: 'FullPageContainerWithBg',
})({
  backgroundColor: colors.SystemGray500,
  justifyContent: 'center',
  alignItems: 'center',
});

const ResetLinkModalContainer = styled(Box, {
  name: 'ResetLinkModalContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  width: '100%',
  maxWidth: '520px',
  justifyContent: 'space-between',
  borderRadius: '16px',
  backgroundColor: colors.SystemWhite,
});

const ResetLinkInnerModalContainer = styled(Box, {
  name: 'ResetLinkInnerModalContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  paddingBottom: '64px',
});

const ResetLinkSentHeadingContainer = styled(Box, {
  name: 'ResetLinkSentHeadingContainer',
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  padding: '50px 0',
  borderRadius: '12px',
  backgroundColor: colors.THOrangeLight,
});

const ResetLinkHeadingIcon = styled('i', {
  name: 'ResetLinkHeadingIcon',
})({
  width: '120px',
  height: '120px',
  fontSize: '120px',
  color: colors.SystemGray900,
});

const ResetLinkSentTypographyContainer = styled(Box, {
  name: 'ResetLinkTypographyContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});

const ResetLinkSentHeadline = styled(Typography, {
  name: 'ResetLinkSentHeadline',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  textAlign: 'center',
  fontSize: typography.headlineLarge.fontSize,
  lineHeight: typography.headlineLarge.lineHeight,
  letterSpacing: '-1px',
  fontWeight: 700,
});

const ResetLinkSentSubText = styled(Typography, {
  name: 'ResetLinkSentSubText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  textAlign: 'center',
  ...typography.bodyLargeRegular,
});

const ButtonContainer = styled(Box, {
  name: 'ButtonContainer',
})({
  margin: '0 auto',
});
