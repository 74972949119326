import { Box, styled } from '@mui/material';

export const FormCont = styled('form', {
  name: 'ExternalTicketsFormCont',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  maxWidth: '520px',
  width: '100%',
  gap: '40px',
});

export const FormSectionCont = styled(Box, {
  name: 'ExternalTicketsFormSectionCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});
