import { Box, styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { Button } from '../../../ui/buttons/Button';
// import TikTokIcon from '../../../Images/Icons/TikTok.svg';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import { RightHandSideComponentTitle, RightHandSideHeadingIcon } from '../Shared/SharedElements';

interface Instagram {
  instagramUsername: string;
}

export const Instagram: FC<Instagram> = ({ instagramUsername }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <RightHandSideComponentContainer>
      <ResizeContainer>
        <InstagramHeadingContainer>
          <InstagramInnerHeadingContainer>
            {/* <RightHandSideHeadingIcon src={TikTokIcon}/> */}
            <RightHandSideComponentTitle>Instagram</RightHandSideComponentTitle>
          </InstagramInnerHeadingContainer>
          <Button
            isPrimary={false}
            disabled={false}
            size="small"
            onClick={() => window.open('https://instagram.com/' + instagramUsername)}
          >
            Open in Instagram
          </Button>
        </InstagramHeadingContainer>
        {/* {isLoading &&
                <TikTokSkeleton/>
            } */}
        {/* <TikTokEmbed link={tikTokLink} setIsLoading={setIsLoading} isLoading={isLoading}/>     */}
      </ResizeContainer>
    </RightHandSideComponentContainer>
  );
};

const InstagramHeadingContainer = styled(Box, {
  name: 'InstagramHeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const ResizeContainer = styled(Box, {
  name: 'ResizeContainer',
})({});

const InstagramInnerHeadingContainer = styled(Box, {
  name: 'InstagramInnerHeadingContainer',
})({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});
