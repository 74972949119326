import { Avatar, Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { breakpoints } from '../../../ui/shared/MediaQueries';

export interface SingleVenueReviewProps {
  person: {
    firstName: string;
    lastName: string;
    title: string;
    avatarWebp: string;
  };
  venue?: {
    id?: string;
    name?: string;
    avatarWebp?: string;
  };
  reviewText: string;
}

export const SingleVenueReview: FC<SingleVenueReviewProps> = ({ venue, person, reviewText }) => {
  return (
    <SingleVenueReviewContainer>
      {venue && (
        <SingleVenueReviewTitleRowContainer>
          <VenueAvatar src={venue?.avatarWebp} />
          <VenueName>{venue?.name}</VenueName>
        </SingleVenueReviewTitleRowContainer>
      )}
      <VenuesReviewText>{reviewText}</VenuesReviewText>
      <SingleVenueReviewPersonContainer>
        <SingleVenueReviewPersonAvatar src={person.avatarWebp} />
        <SingleVenueReviewPersonDescriptionCont>
          <SingleVenueReviewPersonDescriptionName>{`${person.firstName} ${person.lastName}`}</SingleVenueReviewPersonDescriptionName>
          <SingleVenueReviewPersonDescriptionTitle>{person.title}</SingleVenueReviewPersonDescriptionTitle>
        </SingleVenueReviewPersonDescriptionCont>
      </SingleVenueReviewPersonContainer>
    </SingleVenueReviewContainer>
  );
};

const SingleVenueReviewContainer = styled(Box, {
  name: 'SingleVenueReviewContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '35px 32px',
  backgroundColor: colors.SystemGray700,
  maxWidth: '420px',
  width: '100%',
  margin: '0 12px',
  minHeight: '365px',
});

const SingleVenueReviewTitleRowContainer = styled(Box, {
  name: 'SingleVenueReviewTitleRowContainer',
})({
  display: 'flex',
  gap: '12px',
  paddingBottom: '24px',
  alignItems: 'center',
});

const VenueAvatar = styled(Avatar, {
  name: 'VenueAvatar',
})({
  width: '60px',
  height: '60px',
  borderRadius: '50%',
});

const VenueName = styled(Typography, {
  name: 'VenueName',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.headlineSmall,
});

const VenuesReviewText = styled(Typography, {
  name: 'VenuesReviewText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.bodyMediumRegular,
});

const SingleVenueReviewPersonContainer = styled(Box, {
  name: 'SingleVenueReviewPersonContainer',
})({
  display: 'flex',
  gap: '16px',
  alignItems: 'center',
  paddingTop: '40px',
  marginTop: 'auto',
});

const SingleVenueReviewPersonAvatar = styled(Avatar, {
  name: 'SingleVenueReviewPersonAvatar',
})({
  width: '48px',
  height: '48px',
  borderRadius: '50%',
});

const SingleVenueReviewPersonDescriptionCont = styled(Box, {
  name: 'SingleVenueReviewPersonDescriptionCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',
});

const SingleVenueReviewPersonDescriptionName = styled(Typography, {
  name: 'SingleVenueReviewPersonDescriptionName',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.bodySmallBold,
});

const SingleVenueReviewPersonDescriptionTitle = styled(Typography, {
  name: 'SingleVenueReviewPersonDescriptionTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.bodySmallRegular,
});
