import {
  Chip,
  InputLabel,
  OutlinedInput as MuiOutlinedInput,
  Select as MuiSelect,
  styled,
  SelectProps,
  FormControl,
  Avatar,
  Autocomplete,
  TextField,
} from '@mui/material';
import Lo, { uniq } from 'lodash';
import React, { Dispatch, FC, SetStateAction, SyntheticEvent, forwardRef, useCallback, useMemo } from 'react';
import { SetURLSearchParams } from 'react-router-dom';
import AvatarImg from '../../Images/Avatar.webp';
import { colors } from '../shared/Colors';
import { typography } from '../shared/TypographySharedElements';

interface MultipleSelectChipProps {
  selectValue: SingleOption[];
  setSelectValue: Dispatch<SetStateAction<SingleOption[]>>;
  setSearch: SetURLSearchParams;
  search: string;
  label: string;
  options: SingleOption[];
  placeholder: string;
  handleChangeOuter: (value: string | string[], isDelete?: boolean) => void;
  isLoading: boolean;
  dataCy?: string;
}
export interface SingleOption {
  label: string;
  id: string;
  avatar: string | null;
}

export const MultipleSelectChip: FC<MultipleSelectChipProps> = forwardRef<unknown, MultipleSelectChipProps>(
  (
    {
      selectValue,
      isLoading,
      setSelectValue,
      label,
      options,
      placeholder,
      handleChangeOuter,
      setSearch,
      search,
      dataCy,
    },
    ref,
  ) => {
    const handleChange = useCallback(
      (event: SyntheticEvent<Element, Event>, value: any[]) => {
        event.preventDefault();
        event.stopPropagation();

        setSelectValue(uniq(value));
        handleChangeOuter(uniq(value));
      },
      [setSelectValue, handleChangeOuter, options],
    );

    const handleRemove = useCallback(
      (e: React.MouseEvent<HTMLElement, MouseEvent>, value: unknown) => {
        const newValue = selectValue.filter((selectedElement) => selectedElement.label !== value);
        setSelectValue(newValue);
        handleChangeOuter(value as string, true);
      },
      [selectValue, setSelectValue],
    );

    const findAvatarSrc = useCallback(
      (label: string) => {
        const element = options.find((el) => el.label === label);
        if (element) {
          return element.avatar;
        } else {
          return null;
        }
      },
      [options],
    );

    const handleInputChange = useMemo(
      () =>
        Lo.debounce(
          (event: unknown, value: string) => {
            setSearch({ search: value });
          },
          250,
          { maxWait: 1000 },
        ),
      [setSearch],
    );

    return (
      <FormControlElement>
        <InputLabelElement>{label}</InputLabelElement>
        <Autocomplete
          multiple
          options={options}
          value={selectValue}
          autoComplete
          noOptionsText="No artists for your current search"
          renderTags={(value: readonly SingleOption[], getTagProps) => {
            return value.map((option: SingleOption, index: number) => {
              const { key, ...tagProps } = getTagProps({ index });
              return (
                <ChipElement
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  key={option.id}
                  avatar={<Avatar src={findAvatarSrc(option.label) ?? AvatarImg} />}
                  label={option.label}
                  clickable
                  onClick={(e) => handleRemove(e, option.label)}
                  deleteIcon={<i className="material-symbols-outlined">close</i>}
                  onDelete={(evt) => handleRemove(evt, option.label)}
                />
              );
            });
          }}
          onChange={handleChange}
          loading={isLoading}
          onInputChange={handleInputChange}
          renderInput={(params) => (
            <TextField
              {...params}
              value={search}
              placeholder="Search for an artist"
              data-cy={dataCy}
            />
          )}
        />
      </FormControlElement>
    );
  },
);

const FormControlElement = styled(FormControl, {
  name: 'FormControlElement',
})({
  width: '100%',
  maxWidth: '100%',
  paddingTop: '23px',
});

const Select = styled(MuiSelect, {
  name: 'Select',
})({
  padding: 0,
  '&.Mui-focused': {
    '.MuiOutlinedInput-notchedOutline': {
      border: `1px solid ${colors.SystemGray400}`,
      borderRadius: '8px',
    },
  },
  '.MuiOutlinedInput-notchedOutline': {
    border: `1px solid ${colors.SystemGray200}`,
    borderRadius: '8px',
  },
});

const OutlinedInput = styled(MuiOutlinedInput, {
  name: 'OutlinedInput',
})<SelectProps<string[]>>(({ disabled }) => ({
  minWidth: '100%',
  fontFamily: 'Satoshi-Variable',
  display: 'flex',
  flexGrow: 1,
  padding: '13px 12px',
  marginTop: '0',
  width: '100%',
  maxWidth: '100%',
  fontSize: typography.bodyLargeRegular.fontSize,
  fontWeight: typography.bodyLargeRegular.fontWeight,
  lineHeight: typography.bodyLargeRegular.lineHeight,
  letterSpacing: typography.bodyLargeRegular.letterSpacing,
  color: disabled ? colors.SystemGray500 : colors.SystemGray900,
  '.MuiSelect-select': {
    padding: '13px 12px',
    '&:focus': {
      borderRadius: '8px',
    },
  },
  '.MuiFormHelperText-root': {
    fontFamily: 'Satoshi-Variable',
    color: colors.SystemGray900,
    ...typography.subtitle,
  },
}));

const ChipElement = styled(Chip, {
  name: 'ChipElement',
})({
  fontFamily: 'Satoshi-Variable',
  ...typography.bodyMediumBold,
  paddingRight: '28px',
  zIndex: 20,
  '.MuiSvgIcon-root': {
    width: '20px',
    height: '20px',
    pointerEvents: 'none',
  },
  '.MuiChip-deleteIcon': {
    fontSize: '20px',
    color: colors.SystemBlack,
    position: 'absolute',
    right: '12px',
    top: '50%',
    transform: 'translate(0, -50%)',
    zIndex: 5,
    marginRight: 0,
  },
});

const InputLabelElement = styled(InputLabel, {
  name: 'InputLabelElement',
})<{ error?: boolean }>(({ error }) => ({
  top: '0px',
  left: '4px',
  fontFamily: 'Satoshi-Variable',
  transform: 'none',
  color: error ? colors.AlertRed : colors.SystemGray900,
  ...typography.bodyMediumRegular,
  '&.Mui-focused': {
    color: colors.SystemGray900,
  },
}));
