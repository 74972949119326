import React, { useEffect } from 'react';
import { useGetAllShowsQuery, useGetAllVenuesQuery } from '../Redux/API/PublicAPI';
import LoadingWrapper from '../Components/Layout/LoadingWrapper';
import BannerImage from '../Components/Images/BannerImage';
import CoverImage from '../Images/Banners/showsbanner.webp';
import FeaturedShowBanner from '../Components/Collections/FeaturedShowBanner';
import ShowSorter from '../Components/Layout/ShowSorter/ShowSorter';
import { useDispatch } from 'react-redux';
import { setDarkMode } from '../Redux/UI/UISlice';

export default function Shows() {
  const shows = useGetAllShowsQuery();
  const venues = useGetAllVenuesQuery();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setDarkMode({ status: true }));
    return () => {
      dispatch(setDarkMode({ status: false }));
    };
  }, [dispatch]);
  return (
    <LoadingWrapper queryResponse={[shows, venues]}>
      <section className="w-full">
        <BannerImage
          src={CoverImage}
          height="h-auto h-[59vh] md:h-[50vh]"
          imageStyle={{ objectPosition: '100% 50%' }}
        >
          <FeaturedShowBanner
            showID="61913369" //roots
            // "32731557"
          />
        </BannerImage>
      </section>
      <ShowSorter />
    </LoadingWrapper>
  );
}
