import { Box, styled } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { Button } from '../buttons/Button';
import { useParams } from 'react-router-dom';
import { HatchLinksListProps } from './HatchLinksList';
import { HatchLinkNew } from './HatchLinkNew';

export const HatchLinksListNew: FC<HatchLinksListProps> = ({
  links,
  maxRenderNumber,
  openInNewTab,
  darkMode,
  userType,
  userId,
}) => {
  const { profileID, type } = useParams();
  userType = userType || type;
  userId = userId || profileID;
  const HatchLinks = useMemo(() => {
    if (maxRenderNumber) {
      return links.slice(0, maxRenderNumber).map((singleLink) => {
        return (
          <HatchLinkNew
            key={singleLink.url}
            {...singleLink}
            darkMode={darkMode}
            openInNewTab={openInNewTab}
          />
        );
      });
    } else {
      return links.map((singleLink) => {
        return (
          <HatchLinkNew
            key={singleLink.url}
            {...singleLink}
            darkMode={darkMode}
            openInNewTab={openInNewTab}
          />
        );
      });
    }
  }, [links, maxRenderNumber, openInNewTab]);

  return (
    <HatchLinksListContainer>
      {HatchLinks}
      {links?.length > maxRenderNumber && (
        <Button
          isPrimary={true}
          disabled={false}
          size="large"
          onClick={() =>
            window.open(`${process.env.REACT_APP_PUBLIC_URL}/hl/${userType === 'user' ? 'artist' : userType}/${userId}`)
          }
        >
          View all in HatchLinks
        </Button>
      )}
    </HatchLinksListContainer>
  );
};

const HatchLinksListContainer = styled(Box, {
  name: 'HatchLinksListContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
});
