import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

import dayjs from 'dayjs';
import Img from '../../../Components/Images/Img';

interface SmallShowCardProps {
  id: string;
  date: string;
  venue: {
    id: string;
    name: string;
    avatarWebP: string;
    location?: {
      address: string;
      city: string;
      state: string;
      zip: string | number;
      metadata?: any;
    };
  };
  showTitle: string;
  customLabel?: string;
  alternativeLabel?: boolean;
}

export const SmallShowCard: FC<SmallShowCardProps> = ({
  id,
  date,
  venue,
  showTitle,
  customLabel,
  alternativeLabel,
}) => {
  const parsedDate = new Date(date);
  const month = dayjs(parsedDate).format('M');
  const day = parsedDate.getDate();
  const venueState = venue.location?.metadata?.address_components?.[5]?.short_name || venue.location?.state;

  const displayHatchLinkLabel = () => {
    // if custom label is provided then display custom label
    if (customLabel) {
      return <SmallShowCardText>{`${month}/${day} • ${customLabel}`}</SmallShowCardText>;
    }

    // alternative label: date + show title
    if (alternativeLabel) {
      return <SmallShowCardText>{`${month}/${day} • ${showTitle}`}</SmallShowCardText>;
    }

    // no custom label, no alternative label option - then dispaly default label (date + city)
    return (
      <SmallShowCardText>{`${month}/${day} • ${venue.location?.city && venue.location?.state ? `${venue.location?.city}, ${venueState}` : venue.name}`}</SmallShowCardText>
    );
  };

  return (
    <SmallShowCardContainer
      onClick={() => {
        window.open(`/shows/${id}`);
      }}
    >
      <SmallShowCardVenueIconContainer>
        <SmallShowCardVenueIcon src={venue.avatarWebP} />
      </SmallShowCardVenueIconContainer>
      {displayHatchLinkLabel()}
    </SmallShowCardContainer>
  );
};

export const SmallShowCardContainer = styled(Box, {
  name: 'ShowCardContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  padding: '12px 84px 12px 12px',
  backgroundColor: colors.SystemWhite,
  borderRadius: '28px',
  gap: '32px',
  width: 'calc(100% - 40px)',
  margin: '0 20px',
});

export const SmallShowCardVenueIconContainer = styled(Box, {
  name: 'ShowCardVenueIconContainer',
})({
  position: 'relative',
  width: '40px',
  height: '40px',
  backgroundColor: colors.SystemGray100,
  borderRadius: '999px',
});

export const SmallShowCardVenueIcon = styled(Img, {
  name: 'ShowCardVenueIcon',
})({
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  width: '100%',
  height: '100%',
  borderRadius: '999px',
});

export const SmallShowCardText = styled(Typography, {
  name: 'ShowCardText',
})({
  width: 'calc(100% - 72px)',
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.buttonLarge,
  textAlign: 'center',
});
