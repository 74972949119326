import React from 'react';
import dayjs from 'dayjs';
import Form from '../../Components/Inputs/Form';
import { useAppSelector } from '../../hooks';
import { useEditProfileMutation } from '../../Redux/API/UserAPI';
import BannerAvatarUpload from '../../Components/Inputs/InputTypes/BannerAvatarUpload';
import { getDisplayName } from '../../Helpers/HelperFunctions';

export default function EditArtistProfileForm() {
  const [editProfile] = useEditProfileMutation();

  const user = useAppSelector((state) => state.user.data);

  return (
    <div className="flex flex-col mt-9 relative z-0">
      <BannerAvatarUpload
        form="editProfile"
        bannerField={'type.artist.banner'}
        avatar={user.avatarWebP ?? user.avatar}
        banner={user.type.artist.bannerWebP ?? user.type.artist.banner}
      />
      <div className="flex flex-col">
        <h1 className="text-center text-4xl font-black satoshi-override">{getDisplayName('user', user)}</h1>
        <p className="text-xs text-center text-gray-400 satoshi-override">
          TuneHatch Artist since {dayjs(user.created).format('MMMM DD, YYYY')}
        </p>
      </div>

      <Form
        name="editProfile"
        className="flex flex-wrap p-2 satoshi-override"
        keepOnDismount
        media={true}
        submitFn={editProfile}
        doneLabel="Save"
        doneIcon="done"
        fixedNav
        formMap={[
          [
            {
              fieldType: 'title',
              className: 'w-full flex-col text-center text-2xl font-black mt-2 satoshi-override',
              defaultValue: 'The Basics',
            },
            {
              fieldType: 'dropdown',
              field: 'type.artist.genre',
              label: 'Genre:',
              defaultValue: user?.type?.artist?.genre?.toLowerCase(),
              containerClassName: 'flex flex-col mt-4 font-black satoshi-override',
              className: 'pr-1',
              name: 'genre',
              optionMap: {
                rock: 'Rock',
                pop: 'Pop',
                metal: 'Metal',
                hip_hop: 'Hip Hop',
                jazz: 'Jazz',
                country: 'Country',
                electronic: 'Electronic',
                indie: 'Indie',
                other: 'other',
              },
            },
            {
              field: 'type.artist.subgenre',
              label: 'Subgenre',
              placeholder: 'Subgenre',
              name: 'subgenre',
              defaultValue: user?.type?.artist?.subgenre,
              containerClassName: 'flex flex-grow pr-1 pl-1 satoshi-override',
            },
            {
              field: 'type.artist.stagename',
              fieldType: 'text',
              label: 'Stage Name',
              placeholder: 'Stage Name',
              name: 'stageName',
              defaultValue: user?.type?.artist?.stagename,
              clickToEnable: !user.type?.artist?.stagename,
              containerClassName: 'flex w-full',
            },
            {
              field: 'about',
              fieldType: 'textarea',
              label: 'About',
              placeholder: 'About',
              name: 'about',
              defaultValue: user?.about,
              containerClassName: 'flex w-full p-2 mt-2',
            },
            {
              field: 'type.artist.primaryCity',
              fieldType: 'place',
              label: 'Primary City',
              className: 'flex-grow',
              name: 'primaryCity',
              defaultValue: user?.type?.artist?.primaryCity,
            },
            {
              field: 'type.artist.secondaryCity',
              fieldType: 'place',
              label: 'Secondary City',
              name: 'secondaryCity',
              defaultValue: user?.type?.artist?.secondaryCity,
            },
            {
              fieldType: 'title',
              className: 'w-full flex-col text-center text-2xl font-black mt-2',
              defaultValue: 'Social Content',
            },
            {
              fieldType: 'title',
              className: 'w-full flex-col text-center text-sm text-gray-400 mt-2',
              defaultValue:
                'TuneHatch allows you to dynamically display your favorite content from social media directly on your profile. Add items below to see them automatically added.',
            },
            {
              field: 'type.artist.socials.spotifyLink',
              label: 'Spotify',
              placeholder: 'Spotify',
              name: 'spotifyLink',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.type?.artist?.socials?.spotifyLink,
            },
            {
              field: 'type.artist.socials.instagram.link',
              label: 'Instagram',
              placeholder: 'Instagram',
              name: 'instagram',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.type?.artist?.socials?.instagram?.link,
            },
            {
              field: 'type.artist.socials.youtubeLink',
              label: 'Youtube',
              placeholder: 'Youtube',
              name: 'youtubeLink',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.type?.artist?.socials?.youtubeLink,
            },
            {
              field: 'type.artist.socials.tikTokLink',
              label: 'TikTok',
              placeholder: 'TikTok',
              name: 'tikTokLink',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.type?.artist?.socials?.tikTokLink,
            },
            {
              fieldType: 'title',
              className: 'w-full flex-col text-center text-2xl font-black mt-2',
              defaultValue: 'HatchLinks',
            },
            {
              fieldType: 'title',
              className: 'w-full flex-col text-center text-sm text-gray-400 mt-2',
              defaultValue: 'You can add up to five external links here to update your HatchLink and profile.',
            },
            {
              field: 'username',
              label: 'HatchLinks Username',
              placeholder: '@username',
              containerClassName: 'flex w-full',
              defaultValue: user?.username,
            },
            {
              field: 'hatchlink.0.url',
              label: 'Link 1',
              placeholder: 'URL',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[0]?.url,
            },
            {
              field: 'hatchlink.0.text',
              label: 'Label 1',
              placeholder: 'Label',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[0]?.text,
            },
            {
              field: 'hatchlink.1.url',
              label: 'Link 2',
              placeholder: 'URL',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[1]?.url,
            },
            {
              field: 'hatchlink.1.text',
              label: 'Label 2',
              placeholder: 'Label',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[1]?.text,
            },
            {
              field: 'hatchlink.2.url',
              label: 'Link 3',
              placeholder: 'URL',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[2]?.url,
            },
            {
              field: 'hatchlink.2.text',
              label: 'Label 3',
              placeholder: 'Label',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[2]?.text,
            },
            {
              field: 'hatchlink.3.url',
              label: 'Link 4',
              placeholder: 'URL',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[3]?.url,
            },
            {
              field: 'hatchlink.3.text',
              label: 'Label 4',
              placeholder: 'Label',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[3]?.text,
            },
            {
              field: 'hatchlink.4.url',
              label: 'Link 5',
              placeholder: 'URL',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[4]?.url,
            },
            {
              field: 'hatchlink.4.text',
              label: 'Label 5',
              placeholder: 'Label',
              containerClassName: 'flex w-1/2 pr-1',
              className: 'text-xs',
              defaultValue: user?.externalLinks?.[4]?.text,
            },
            {
              field: 'type.artist.enabled',
              placeholder: 'Enable Artist Features',
              fieldType: 'toggleSlider',
              defaultValue: user.type?.artist?.enabled,
            },
            {
              field: 'customOptions.alternativeHatchLinkLabel',
              placeholder: 'Display Show Title on the HatchLink',
              fieldType: 'toggleSlider',
              defaultValue: user.customOptions?.alternativeHatchLinkLabel,
            },
          ],
        ]}
      />
    </div>
  );
}
