import Spotify from '../../Images/Icons/Spotify.webp';
import HatchedIcon from '../../Images/Icons/HatchedIcon2.webp';
import AustinTX from '../../Images/Badges/austintx.png';
import NashvilleTN from '../../Images/Badges/nashvilletn.png';
import ProvidenceRI from '../../Images/Badges/providenceri.png';
import SignedIcon from '../../Images/Badges/Signed.svg';
import { ArtistObject } from './Models/Artist';
import { Venue } from './Models/Venue';
import { Type } from './Models/Type';

export interface IBadge {
  image?: string;
  customImage?: string;
  type?: string;
  description?: string;
  name: string;
  label: string;
  awardText: string;
  criteria?: (target: ArtistObject) => boolean;
  icon?: React.ReactNode;
  category: BadgeCategoryEnum;
}

export enum BadgeCategoryEnum {
  ABOUT = 'About',
  VENUES = 'Venues',
  CITIES = 'Cities',
}

export const BADGES: IBadge[] = [
  {
    image: HatchedIcon,
    name: 'hatched',
    label: 'Hatched',
    awardText: 'Awarded to artists who have verified their profile and connected to Stripe for payouts.',
    criteria: (target: ArtistObject) => target.stripeEnabled,
    category: BadgeCategoryEnum.ABOUT,
  },
  {
    name: 'spotify',
    image: Spotify,
    type: 'milestone',
    label: 'Spotify',
    description: 'obtained a Spotify Score over 25.',
    awardText: 'Awarded to artists who obtain a Spotify Score over 25',
    criteria: (target: ArtistObject) => target.spotifyScore > 25,
    category: BadgeCategoryEnum.ABOUT,
  },
  {
    name: 'signed',
    image: SignedIcon,
    label: 'Signed',
    awardText: 'User is signed with a label',
    criteria: (target: ArtistObject) => !!target.type?.artist?.recordLabelName,
    category: BadgeCategoryEnum.ABOUT,
  },
];
const CITY_BADGES: IBadge[] = [
  {
    name: 'Austin',
    image: AustinTX,
    label: 'Austin',
    awardText: 'Played at Austin, TX, USA',
    category: BadgeCategoryEnum.CITIES,
  },
  {
    name: 'Nashville',
    image: NashvilleTN,
    label: 'Austin',
    awardText: 'Played at Nashville, TN, USA',
    category: BadgeCategoryEnum.CITIES,
  },
  {
    name: 'Providence',
    image: ProvidenceRI,
    label: 'Austin',
    awardText: 'Played at Providence, RI, USA',
    category: BadgeCategoryEnum.CITIES,
  },
];

export const getBadges = (type: Type, target: ArtistObject, venues: { [key: string]: Venue }) => {
  const badges: IBadge[] = [];
  if (type === 'artist') {
    BADGES.forEach((badge) => {
      if (badge.criteria(target)) {
        badges.push(badge);
      }
    });
    target.performances?.forEach((performance) => {
      if (venues?.[performance.venueID]) {
        let badge = {
          name: venues[performance.venueID].name,
          label: venues[performance.venueID].name,
          image: venues[performance.venueID].avatarWebP ?? venues[performance.venueID].avatar,
          awardText: 'Awarded to artists who have given their first performance at ' + venues[performance.venueID].name,
          category: BadgeCategoryEnum.VENUES,
        };
        badges.push(badge);
        const foundCityBadge = CITY_BADGES.find(
          (singleBadge) => singleBadge.name === venues[performance.venueID].location?.city,
        );
        if (foundCityBadge && !badges.find((addedBadge) => addedBadge.name === foundCityBadge.name)) {
          badges.push(foundCityBadge);
        }
      }
    });
  }
  return badges;
};

// shows: {
//     icon: "mic",
//     label: "Shows",
//     description: 'This is the total number of TuneHatch shows played.',
//     info: "shows",
// },
// venues: {
//     icon: "holiday_village",
//     label: "Venues",
//     description: 'This is the total number of TuneHatch venues performed in.',
//     info: "venues",
// },
// cities: {
//     icon: "pin_drop",
//     label: "Cities",
//     description: 'This is total number of cities for TuneHatch performances.',
//     info: "cities",
// },
