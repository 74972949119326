import React, { useCallback, useEffect, useState } from 'react';
import Img from '../../Components/Images/Img';
import { typography } from '../../ui/shared/TypographySharedElements';
import { useAppDispatch } from '../../hooks';
import { setFullscreen } from '../../Redux/UI/UISlice';
import { useGetAllVenuesQuery, useGetAllArtistsQuery, useGetAllShowrunnerGroupsQuery } from '../../Redux/API/PublicAPI';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { getDisplayName } from '../../Helpers/HelperFunctions';
import IconLabel from '../../Components/Labels/IconLabel';
import { HatchLinksHeading } from './HatchLinkHeading';
import AvatarImage from '../../Images/Avatar.webp';
import { styled } from '@mui/material/styles';
import { Box, Divider, Typography } from '@mui/material';
import { colors } from '../../ui/shared/Colors';
import { Button } from '../../ui/buttons/Button';
import { PUBLIC_URL_NOPORT } from '../../Helpers/configConstants';
import { breakpoints } from '../../ui/shared/MediaQueries';
import { FastAverageColor } from 'fast-average-color';
import InstagramIcon from '../../Images/Icons/instagram_hl.svg';
import SpotifyIcon from '../../Images/Icons/spotify_hl.svg';
import TikTokIcon from '../../Images/Icons/tiktok_hl.svg';
import YTIcon from '../../Images/Icons/youtube_hl.svg';
import { HatchLinksListNew } from '../../ui/HatchLinks/HatchLinksListNew';
import { SmallUpcomingShows } from '../ProfilePage/UpcomingShows/SmallUpcomingShows';

import ChickLogo from '../../Images/ChickLogo.webp';

export const HatchLinksPage = () => {
  const dispatch = useAppDispatch();
  const venues = useGetAllVenuesQuery();
  const artists = useGetAllArtistsQuery();
  const showrunners = useGetAllShowrunnerGroupsQuery();
  const { profileID, type }: any = useParams();

  const currentData: any =
    artists.data && type === 'artist'
      ? artists.data[profileID]
      : showrunners.data && type === 'showrunner'
        ? showrunners.data[profileID]
        : venues.data && type === 'venue'
          ? venues.data[profileID]
          : null;

  const displayName = getDisplayName(type, currentData);
  const socials =
    type === 'artist'
      ? {
          instagram: currentData?.type?.artist?.socials?.instagram,
          spotifyLink: currentData?.type?.artist?.socials?.spotifyLink,
          tikTokLink: currentData?.type?.artist?.socials?.tikTokLink,
          youtubeLink: currentData?.type?.artist?.socials?.youtubeLink,
        }
      : { ...currentData?.socials };

  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, [dispatch]);

  const [_topBgColor, setTopBgColor] = useState('#FDE7C6');

  const getColorsFromAvatar = useCallback(async () => {
    if (currentData?.avatar) {
      const fac = new FastAverageColor();
      try {
        const color = await fac.getColorAsync(currentData.avatar ?? AvatarImage, { algorithm: 'dominant' });
        setTopBgColor(color.hex);
        fac.destroy();
      } catch (err) {
        console.log(err);
        fac.destroy();
      }
    }
  }, [currentData?.avatar]);

  useEffect(() => {
    getColorsFromAvatar();
  }, [getColorsFromAvatar]);

  //TODO still no links for artists in the response
  const mockData = {
    darkMode: true,
    externalLinks: [
      { text: 'My Stuff', url: 'http://example.com' },
      { text: 'TuneHatch', url: 'http://tunehatch.com' },
      { text: 'My Merch', url: 'http://example.com' },
    ],
  };

  if (!currentData || !venues.data) {
    return (
      <div className={`w-full h-full flex-grow flex justify-center items-center`}>
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <HatchLinkMainContainer avatarImg={currentData.avatar}>
      <HatchLinksMainContCover>
        <HatchLinksInnerContainer>
          <HeadingContainer>
            <AvatarCont>
              <UserAvatar
                src={currentData.avatar}
                alt={`Avatar of ${displayName}`}
              />
            </AvatarCont>
            <HatchLinksHeading
              artistsName={displayName}
              stripeEnabled={currentData.stripeEnabled}
            />
            {currentData.th_tags && (
              <IconLabel
                icon="location_on"
                iconClassName="text-[12px] md:text-[24px]"
                className="rounded-full p-1 pl-2 pr-2 flex items-center justify-center flex-shrink bg-blue-400 text-center text-black text-[10px] md:text-[20px]"
              >
                {currentData.th_tags[0]}
              </IconLabel>
            )}
            <SocialLinksCont>
              {socials.instagram && (
                <SocialIconCont>
                  <a
                    target="_blank"
                    href={`https://www.instagram.com/${socials.instagram}`}
                  >
                    <SocialIcon src={InstagramIcon} />
                  </a>
                </SocialIconCont>
              )}
              {socials.spotifyLink && (
                <SocialIconCont>
                  <a
                    target="_blank"
                    href={socials.spotifyLink}
                  >
                    <SocialIcon src={SpotifyIcon} />
                  </a>
                </SocialIconCont>
              )}
              {socials.tikTokLink && (
                <SocialIconCont>
                  <a
                    target="_blank"
                    href={`${socials.tikTokLink}`}
                  >
                    <SocialIcon src={TikTokIcon} />
                  </a>
                </SocialIconCont>
              )}
              {socials.youtubeLink && (
                <SocialIconCont>
                  <a
                    target="_blank"
                    href={`${socials.youtubeLink}`}
                  >
                    <SocialIcon src={YTIcon} />
                  </a>
                </SocialIconCont>
              )}
            </SocialLinksCont>
          </HeadingContainer>
          <SmallUpcomingShows
            userId={profileID}
            isMobile={false}
            type={type}
            isAlternativeLabelView={currentData?.customOptions?.alternativeHatchLinkLabel}
          />
          <DividerHL />
          <HatchLinksListNew
            darkMode
            openInNewTab
            userType={type}
            userId={profileID}
            links={currentData?.externalLinks || []}
            maxRenderNumber={currentData?.externalLinks?.length}
          />
          <CTACont>
            <CallToActionText>
              Made with <CTALink href="https://tunehatch.com/">tunehatch.com</CTALink>
            </CallToActionText>
            <Button
              onClick={() => window.open(PUBLIC_URL_NOPORT)}
              type="button"
              isPrimary={true}
              size="large"
            >
              Create Your Own HatchLink
            </Button>
          </CTACont>
        </HatchLinksInnerContainer>
      </HatchLinksMainContCover>
    </HatchLinkMainContainer>
  );
};
const CTACont = styled(Box, {
  name: 'CTACont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  marginTop: '28px',
  alignItems: 'center',
});

const CallToActionText = styled(Typography, {
  name: 'CallToActionText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodySmallBold,
  textAlign: 'center',
});
const CTALink = styled('a', {
  name: 'CTALink',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodySmallBold,
  textAlign: 'center',
  textDecoration: 'underline',
  cursor: 'pointer',
});

const HeadingContainer = styled(Box, {
  name: 'HeadingContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  alignItems: 'center',
  gap: '24px',
});

const HatchLinkMainContainer = styled(Box, {
  name: 'HatchLinkMainContainer',
  shouldForwardProp: (prop) => prop !== 'avatarImg',
})<{ avatarImg: string }>(({ avatarImg }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '64px',
  alignItems: 'center',
  justifyContent: 'center',
  flexBasis: '100vh',
  background: `center / cover no-repeat url(${avatarImg ? avatarImg : ChickLogo}),rgba(255, 255, 255, 0.35)`,
  backgroundBlendMode: 'overlay',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
}));

const HatchLinksMainContCover = styled(Box, {
  name: 'HatchLinksMainContCover',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minWidth: '100vw',
  flexBasis: '100vh',
  backdropFilter: 'blur(100px)',
  padding: '80px 0',
  [`@media ${breakpoints.md}`]: {
    padding: '32px 0',
  },
});
const HatchLinksInnerContainer = styled(Box, {
  name: 'HatchLinksInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  maxWidth: '800px',
  width: '100%',
  gap: '32px',
  zIndex: 2,
});
const AvatarCont = styled(Box, {
  name: 'AvatarCont',
})({
  position: 'relative',
  width: '140px',
  height: '0',
  paddingBottom: '140px',
  borderRadius: '999px',
  boxShadow: `0px 0px 0px 8px ${colors.SystemWhite}`,
  backgroundColor: colors.SystemWhite,
  [`@media ${breakpoints.md}`]: {
    width: '100px',
    paddingBottom: '100px',
  },
});

const UserAvatar = styled(Img, {
  name: 'UserAvatar',
})({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
  borderRadius: '999px',
});

const SocialLinksCont = styled(Box, {
  name: 'SocialLinksCont',
})({
  display: 'flex',
  gap: '32px',
});

const SocialIconCont = styled(Box, {
  name: 'SocialIconCont',
})({
  position: 'relative',
  width: '40px',
  height: '0',
  paddingBottom: '40px',
  [`@media ${breakpoints.md}`]: {
    width: '36px',
    paddingBottom: '36px',
  },
});

const SocialIcon = styled(Img, {
  name: 'SocialIcon',
})({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
});

const DividerHL = styled(Divider, {
  name: 'DividerHL',
})({
  width: 'calc(100% - 40px)',
  margin: '0 20px',
  height: '1px',
  border: 0,
  backgroundColor: colors.SystemGray100,
});
