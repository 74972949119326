import React, { FC, useEffect, useMemo, useState } from 'react';
import Form, { FormMapProps } from '../../Components/Inputs/Form';
import { DEFAULT_CALTAGS } from '../../Helpers/shared/calTagsConfig';
import { getVenueLocationCode } from '../../Helpers/shared/getVenueLocationCode';
import { Show } from '../../Helpers/shared/Models/Show';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useGetAllShowrunnerGroupsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { useGetActiveRegionsQuery } from '../../Redux/API/RegionAPI';

import { resetModal } from '../../Redux/UI/UISlice';
import { formAppend, formSplice, formUpdate } from '../../Redux/User/UserSlice';
import { getArtistName } from '../../Helpers/HelperFunctions';
import { Type } from '../../Helpers/shared/Models/Type';

import {
  useCreateShowByArtistMutation,
  useCreateShowBySRGroupMutation,
  useCreateShowByVenueMutation,
  useEditShowDetailsMutation,
} from '../../Redux/API/ShowAPI';
import {
  mapFormToCreateShowByArtistPayload,
  mapFormToCreateShowBySRGroupPayload,
  mapFormToCreateShowByVenuePayload,
  mapFormToEditShowDetailsPayload,
} from './formToPayloadMapper';
import { ExternalTicketsForm } from '../../Pages/ShowForms/ExternalTickets/ExternalTicketsForm';

function useCreateShow(params: { venueId?: string; showrunnerId?: string; artistId?: string }) {
  const [createShowByVenue, createShowByVenueStatus] = useCreateShowByVenueMutation();
  const [createShowBySRGroup, createShowBySRGroupStatus] = useCreateShowBySRGroupMutation();
  const [createShowByArtist, createShowByArtistStatus] = useCreateShowByArtistMutation();

  if (typeof params.venueId === 'string') {
    return {
      createShow: (data: any) => {
        const payload = mapFormToCreateShowByVenuePayload({
          form: data.form,
          venueId: params.venueId,
        });
        return createShowByVenue(payload);
      },
      createStatus: createShowByVenueStatus,
    };
  }
  if (typeof params.showrunnerId === 'string') {
    return {
      createShow: (data: any) => {
        const payload = mapFormToCreateShowBySRGroupPayload({
          form: data.form,
          srGroupId: params.showrunnerId,
        });
        return createShowBySRGroup(payload);
      },
      createStatus: createShowBySRGroupStatus,
    };
  }

  return {
    createShow: (data: any) => {
      const payload = mapFormToCreateShowByArtistPayload(data.form);
      return createShowByArtist(payload);
    },
    createStatus: createShowByArtistStatus,
  };
}

function useEditShowDetails() {
  const [editShowDetails, editShowDetailsStatus] = useEditShowDetailsMutation();
  return {
    editShowDetails: (data: any) => {
      const payload = mapFormToEditShowDetailsPayload(data.form);
      return editShowDetails(payload);
    },
    editDetailsStatus: editShowDetailsStatus,
  };
}

interface CreateShowFormProps {
  venueID?: string;
  showrunnerID?: string;
  artistID?: string;
  defaultDate?: Date | string;
  show?: Show;
  type: Type;
}

const emptyArray: unknown[] = [];
export const CreateShowForm: FC<CreateShowFormProps> = ({
  venueID,
  showrunnerID,
  artistID,
  defaultDate,
  show,
  type,
}) => {
  const dispatch = useAppDispatch();
  const form = useAppSelector((state) => state.user.forms.createShow);
  const [isExternalTicketForm, setIsExternalTicketForm] = useState(false);
  const venues = useGetAllVenuesQuery();
  const venue = useMemo(() => venues?.data?.[venueID || show?.venueID], [venues, show]);
  const defaultVenues = useMemo(
    () =>
      venue !== undefined
        ? [
            {
              id: venue._key,
            },
          ]
        : emptyArray,
    [venue],
  );

  const currentUser = useAppSelector((state) => state.user.data);
  const currentArtist = useMemo(() => {
    return artistID && artistID === currentUser.displayUID
      ? {
          id: artistID,
          name: getArtistName(currentUser),
        }
      : undefined;
  }, [artistID, currentUser, getArtistName]);
  const defaultArtists = useMemo(() => (currentArtist !== undefined ? [currentArtist] : []), [currentArtist]);

  const optionsMap = useMemo(() => {
    let initialOptionsMap = [
      {
        name: currentArtist ? 'Create A Ticket Listing' : "I Know Who's Performing",
        description: 'For shows that already have a confirmed lineup.',
        value: 'IKWP',
      },
    ];
    if (type !== 'venue') {
      initialOptionsMap.push({
        name: 'External ticket link',
        description:
          'Get your link and be able to view all of your shows in the upcoming shows on your TuneHatch profile.',
        value: 'Ext',
      });
    }
    if (type === 'venue') {
      initialOptionsMap.push({
        name: "I'm Looking For Talent",
        description: 'Post a gig and pick from over 1,000 local artists through TuneHatch',
        value: 'Gig',
      });
    }
    return initialOptionsMap;
  }, [artistID]);

  const showrunners = useGetAllShowrunnerGroupsQuery();
  const currentShowrunner = showrunners?.data?.[showrunnerID];

  const activeRegions = useGetActiveRegionsQuery();
  const regions = activeRegions.data;
  const [timezone, setTimezone] = useState('America/Chicago');
  const regionCode = getVenueLocationCode(venue);

  useEffect(() => {
    if (regions && regionCode) {
      regions.forEach((region) => {
        if (region.locations.includes(regionCode)) {
          setTimezone(region.timezone);
        }
      });
    }
  }, [regions, regionCode]);

  const { createShow } = useCreateShow({
    venueId: venueID,
    showrunnerId: showrunnerID,
    artistId: artistID,
  });
  const { editShowDetails } = useEditShowDetails();

  const [ticketTiers, setTicketTiers] = useState(show?.ticket_tiers ? Object.keys(show?.ticket_tiers)?.length : 1);
  const [ticketTierFormData, setTicketTierFormData] = useState([]);

  const generateCalTagMap = () => {
    let optionMap: { [key: string]: any } = {};
    Object.keys(DEFAULT_CALTAGS).forEach((calTag) => {
      optionMap[calTag] = DEFAULT_CALTAGS[calTag as keyof typeof DEFAULT_CALTAGS].label;
    });
    return optionMap;
  };

  useEffect(() => {
    let ticketTierFormData: FormMapProps[] = [];
    Array.from(Array(ticketTiers).keys()).forEach((tier, i) => {
      if (form?.['meta.advancedTicketing']) {
        ticketTierFormData.push({
          fieldType: 'title',
          defaultValue: <>Tier {tier + 1}</>,
          className: '',
        });
      }
      ticketTierFormData.push({
        field: `ticket_tiers.${tier}.name`,
        defaultValue:
          show?.ticket_tiers && Object.keys(show?.ticket_tiers)?.length > 0 ? show?.ticket_tiers[tier]?.name : '',
        fieldType: 'text',
        placeholder: 'Name',
        prerequisite: form?.['meta.advancedTicketing'] || false,
        containerClassName: 'col-span-6',
      });
      ticketTierFormData.push({
        field: `ticket_tiers.${tier}.description`,
        defaultValue:
          show?.ticket_tiers && Object.keys(show?.ticket_tiers)?.length > 0
            ? show?.ticket_tiers[tier]?.description
            : '',
        fieldType: 'text',
        placeholder: 'Description',
        prerequisite: form?.['meta.advancedTicketing'] || false,
        containerClassName: 'col-span-6',
      });
      ticketTierFormData.push({
        field: `ticket_tiers.${tier}.price`,
        defaultValue:
          show?.ticket_tiers && Object.keys(show?.ticket_tiers)?.length > 0 ? show?.ticket_tiers[tier]?.price : '',
        fieldType: 'number',
        placeholder: 'Price*',
        required: true,
        prerequisite: form?.['meta.advancedTicketing'] || false,
        containerClassName: 'col-span-3',
        className: 'w-full',
      });
      ticketTierFormData.push({
        field: `ticket_tiers.${tier}.quantity`,
        defaultValue:
          show?.ticket_tiers && Object.keys(show?.ticket_tiers)?.length > 0 ? show?.ticket_tiers[tier]?.quantity : '',
        fieldType: 'number',
        placeholder: 'Quantity*',
        required: !show ? true : false,
        prerequisite: form?.['meta.advancedTicketing'] || false,
        containerClassName: 'col-span-3',
        className: 'w-full',
      });
    });
    setTicketTierFormData(ticketTierFormData);
  }, [ticketTiers, form?.['meta.advancedTicketing']]);

  useEffect(() => {
    if (form['meta.showType'] === 'Ext') {
      setIsExternalTicketForm(true);
    }
  }, [form['meta.showType']]);

  if (isExternalTicketForm) {
    return (
      <ExternalTicketsForm
        SGID={showrunnerID}
        artistID={artistID}
        type={type}
      />
    );
  }
  return (
    <Form
      name="createShow"
      className="grid grid-cols-6 w-full gap-y-2 p-4 gap-2"
      fixedNav
      additionalAuthParams={{ venueID: venue?._key || show?.venueID }}
      separateFormObject={true}
      clearOnComplete
      successStatusMessage={!show ? 'Show created!' : 'Show Edited!'}
      onComplete={() => (show ? dispatch(resetModal()) : null)}
      submitFn={!show?._key ? createShow : editShowDetails}
      keepOnDismount={show === undefined}
      formMap={[
        !show
          ? [
              {
                fieldType: 'title',
                defaultValue: 'What type of show are you putting on?',
                className: 'col-span-6 text-2xl text-center font-black w-full mb-4',
              },
              {
                //do not include on edit
                field: 'meta.showType',
                required: !show ? true : false,
                fieldType: 'buttonSelectGroup',
                containerClassName: 'w-full col-span-6',
                placeholder: '',
                triggerPageChange: true,
                optionMap: optionsMap,
              },
            ]
          : [],
        [
          {
            fieldType: 'title',
            defaultValue: 'Show Details',
            className: 'text-3xl font-black col-span-6 text-center',
          },
          {
            field: 'showID',
            defaultValue: show?._key,
            prerequisite: show?._key || false,
            hidden: true,
            required: show?._key || false,
          },
          {
            field: 'name',
            placeholder: 'Show Name*',
            defaultValue: !show ? '' : show?.name,
            large: true,
            required: true,
            containerClassName: 'col-span-6',
          },
          {
            field: 'description',
            placeholder: 'Show Description',
            defaultValue: !show ? '' : show?.description,
            fieldType: 'textarea',
            containerClassName: 'col-span-6',
          },
          {
            form: 'createShow',
            field: 'performers',
            placeholder: 'Add Performers...',
            hidden: form['meta.showType'] !== 'IKWP',
            className: 'w-full',
            containerClassName: 'col-span-6',
            defaultValue: defaultArtists,
            fieldType: 'filterInput',
            filterType: 'artist',
            value: form.performers || show?.performers || [],
            selectFn: (e: any) =>
              dispatch(
                formAppend({
                  form: 'createShow',
                  field: 'performers',
                  value: e,
                }),
              ),
            removeFn: (e: any) =>
              dispatch(
                formSplice({
                  form: 'createShow',
                  field: 'performers',
                  key: 'name',
                  value: e,
                }),
              ),
          },
          {
            field: 'starttime',
            mustBeBefore: 'endtime',
            fieldType: 'timestamp',
            timezone: timezone,
            defaultValue: show?.starttime ? new Date(show?.starttime) : new Date(defaultDate).setHours(18, 0, 0, 0),
            placeholder: 'Start Time',
            allowPast: true,
            containerClassName: 'col-span-6 md:col-span-3',
          },
          {
            field: 'endtime',
            mustBeAfter: 'starttime',
            fieldType: 'timestamp',
            timezone: timezone,
            defaultValue: show?.endtime ? new Date(show?.endtime) : defaultDate,
            placeholder: 'End Time',
            allowPast: true,
            containerClassName: 'col-span-6 md:col-span-3',
          },
          {
            //do not include on edit
            field: 'meta.repeatingShow',
            fieldType: 'buttonToggle',
            placeholder: 'Repeating Show',
            containerClassName: 'col-span-6 md:col-span-2 pb-4 md:pb-0',
            prerequisite: !show,
          },
          {
            //do not include on edit
            fieldType: 'component',
            component: 'RepeatingShowForm',
            containerClassName: 'col-span-6 pb-4 md:pb-0',
            componentProps: { form: 'createShow' },
            prerequisite: !show && form?.['meta.repeatingShow'],
          },
          {
            field: 'ticket_cost',
            placeholder: 'Ticket Price*',
            defaultValue: show ? show?.ticket_cost : null,
            prerequisite: !form?.['meta.advancedTicketing'],
            required: !form['meta.advancedTicketing'],
            fieldType: 'number',
            containerClassName: 'col-span-3 md:col-span-2',
            className: 'w-full',
          },
          {
            field: 'doorPrice',
            placeholder: 'Door Price',
            fieldType: 'number',
            prerequisite: !form?.['meta.advancedTicketing'],
            clickToEnable: true,
            defaultValue: show?.doorPrice,
            containerClassName: 'col-span-3 md:col-span-2',
          },
          ...ticketTierFormData,
          {
            field: 'meta.addTicketTier',
            fieldType: 'button',
            prerequisite: form['meta.advancedTicketing'] || false,
            placeholder: 'Add Ticket Tier',
            icon: 'add',
            clickFn: () => setTicketTiers(ticketTiers + 1),
            containerClassName: 'col-span-6 md:col-span-3',
          },
          {
            field: 'meta.advancedTicketing',
            fieldType: 'buttonToggle',
            defaultValue: show?.ticket_tiers ? Object.keys(show?.ticket_tiers)?.length > 0 : false,
            warnWhenData: [
              'ticket_tiers.0.name',
              'ticket_tiers.0.description',
              'ticket_tiers.0.price',
              'ticket_tiers.0.quantity',
            ],
            warnMessage: 'This will clear all ticket tiers for this show. Continue?',
            placeholder: form['meta.advancedTicketing'] ? 'Disable Advanced Ticketing' : 'Advanced Ticketing',
            containerClassName: form['meta.advancedTicketing']
              ? 'col-span-6 md:col-span-3 pb-4 md:pb-0'
              : 'col-span-6 md:col-span-2 pb-4 md:pb-0',
          },
          type !== 'venue'
            ? {
                form: 'createShow',
                field: 'venues',
                placeholder: 'Add Venue...*',
                className: 'w-full',
                limit: 1,
                containerClassName: 'col-span-6 mb-2',
                fieldType: 'filterInput',
                filterType: 'venue',
                defaultValue: defaultVenues,
                required: true,
                value: form.venues ?? emptyArray,
                selectFn: (selectedVenue: any) => {
                  dispatch(
                    formUpdate({
                      form: 'createShow',
                      field: 'venues',
                      value: [selectedVenue],
                    }),
                  );
                },
                removeFn: () =>
                  dispatch(
                    formUpdate({
                      form: 'createShow',
                      field: 'venues',
                      value: emptyArray,
                    }),
                  ),
              }
            : {
                field: 'venueID',
                defaultValue: venue?._key,
                hidden: true,
                required: true,
              },
          currentShowrunner === undefined
            ? {
                form: 'createShow',
                field: 'showrunner',
                placeholder: 'Add Showrunner...',
                className: 'w-full',
                limit: 1,
                containerClassName: 'col-span-6 mb-2',
                fieldType: 'filterInput',
                filterType: 'showrunner',
                defaultValue: show?.showrunner,
                value: form.showrunner || [],
                selectFn: (e: string) =>
                  dispatch(
                    formAppend({
                      form: 'createShow',
                      field: 'showrunner',
                      value: e,
                    }),
                  ),
                removeFn: (e: string) =>
                  dispatch(
                    formSplice({
                      form: 'createShow',
                      field: 'showrunner',
                      key: 'name',
                      value: e,
                    }),
                  ),
              }
            : {
                form: 'createShow',
                field: 'showrunner',
                className: 'w-full',
                limit: 1,
                defaultValue: [{ id: currentShowrunner._key, name: currentShowrunner.name }],
                required: true,
                hidden: true,
              },
          {
            field: 'min_age',
            type: 'number',
            // hidden: !form?.["meta.venueOptions"],
            fieldType: 'number',
            placeholder: 'Minimum Age',
            defaultValue: show?.min_age || venue?.min_age,
            containerClassName: 'col-span-3',
            className: 'w-full',
          },
          {
            field: 'capacity',
            type: 'number',
            // hidden: !form?.["meta.venueOptions"],
            fieldType: 'number',
            placeholder: 'Number of Available Tickets*',
            defaultValue: show ? show?.capacity : venue?.capacity,
            containerClassName: 'col-span-6 md:col-span-3',
            required: !form['meta.advancedTicketing'],
            className: 'w-full',
          },
          {
            field: 'private',
            fieldType: 'toggleSlider',
            defaultValue: !show ? false : show?.private,
            label: 'Private Event',
            containerClassName: 'col-span-6 md:col-span-3 lg:col-span-2 lg:mx-auto',
          },
          {
            field: 'cohosted',
            fieldType: 'toggleSlider',
            label: 'Artists Can Manage Show',
            defaultValue: !show ? false : show?.cohosted,
            containerClassName: 'col-span-6 md:col-span-3 lg:col-span-2 lg:mx-auto pb-2 md:pb-0',
          },
          {
            fieldType: 'dropdown',
            field: 'calTag',
            colorMap: DEFAULT_CALTAGS,
            containerClassName: 'col-span-6 lg:col-span-1',
            label: 'Calendar Color',
            optionMap: generateCalTagMap(),
            defaultValue: show?.calTag || 'green',
            className: 'mx-auto',
          },
        ],
        !show
          ? [
              {
                //do not include this page on edit
                fieldType: 'component',
                component: 'FlyerBuilder',
                containerClassName: 'col-span-6',
                componentProps: {
                  form: 'createShow',
                },
                key: 'FlyerBuilder',
              },
            ]
          : [],
        !show
          ? [
              //do not include this page on edit
              {
                fieldType: 'title',
                defaultValue: 'Payment Agreement',
                className: 'text-2xl w-full font-black col-span-6',
              },
              {
                fieldType: 'title',
                defaultValue: `Choose how artists who perform at your show will be paid. These will be the defaults for artists who are invited or apply.
      You can change specifics on guarantees and production fees for each artist who you invite or receieve an application from.`,
                className: 'col-span-6',
              },
              {
                fieldType: 'component',
                containerClassName: 'col-span-6',
                component: 'PaymentAgreementBuilder',
                componentProps: {
                  form: 'createShow',
                  venueID: venueID,
                },
                key: 'FlyerBuilder',
              },
            ]
          : [],
      ]}
    />
  );
};
