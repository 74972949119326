import { IconButton as MuiIconButton, IconButtonProps as MuiIconButtonProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import React, { FC } from 'react';
import { ButtonIcon } from '../shared/ButtonIcon';
import { colors } from '../shared/Colors';

export interface IconButtonProps extends MuiIconButtonProps {
  isPrimary: boolean;
  iconName?: string;
}

export const IconButton: FC<IconButtonProps> = (props) => {
  return (
    <IconButtonElement {...props}>
      {
        <ButtonIcon
          size={props.size}
          disabled={props.disabled}
          isPrimary={props.isPrimary}
          iconName={props.iconName}
        />
      }
    </IconButtonElement>
  );
};

const IconButtonElement = styled(MuiIconButton, {
  name: 'IconButton',
  shouldForwardProp: (prop) => prop !== 'isPrimary' && prop !== 'iconName',
})<IconButtonProps>(({ isPrimary, disabled, size }) => ({
  display: 'flex',
  alignItems: 'center',
  width: size === 'large' ? '48px' : '36px',
  height: size === 'large' ? '48px' : '36px',
  padding: size === 'large' ? '12px' : '10px',
  borderRadius: '999px',
  backgroundColor: isPrimary ? (disabled ? colors.SystemGray200 : colors.SystemGray900) : colors.SystemGray100,
  color: isPrimary ? colors.SystemWhite : colors.SystemGray900,
  '&.Mui-disabled': {
    backgroundColor: isPrimary ? colors.SystemGray200 : colors.SystemGray100,
    color: isPrimary ? colors.SystemGray300 : colors.SystemGray400,
  },
  '&:hover': {
    backgroundColor: isPrimary
      ? disabled
        ? colors.SystemGray200
        : colors.SystemGray700
      : disabled
        ? colors.SystemGray100
        : colors.SystemGray200,
  },
}));
