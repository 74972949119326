import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button } from '../../../ui/buttons/Button';
import { Box, styled } from '@mui/material';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';
import { AboutHeadlineItem } from './AboutHeadlineItem';
import { LeftHandSideComponentTitle } from '../Shared/SharedElements';
import { LeftSideComponentContainer } from '../Shared/SharedContainers';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../Redux/UI/UISlice';

interface AboutProps {
  about: string;
  location: string;
  genre: string;
  isOwnProfile: boolean;
}

export const About: FC<AboutProps> = ({ about, location, genre, isOwnProfile }) => {
  const [isMoreVisible, setIsMoreVisible] = useState(false);
  const MAX_VISIBLE_CHARS = 250;
  const aboutLongerThanMaxVisibleChars = about.length > MAX_VISIBLE_CHARS;
  const dispatch = useAppDispatch();

  const aboutFirstPart = useMemo(() => {
    if (aboutLongerThanMaxVisibleChars) return about.slice(0, 250);
    return about;
  }, [about, aboutLongerThanMaxVisibleChars]);

  const aboutRest = useMemo(() => {
    if (aboutLongerThanMaxVisibleChars) return about.slice(250, about.length);
  }, [about, aboutLongerThanMaxVisibleChars]);

  const toggleExpanded = useCallback(() => {
    setIsMoreVisible((isMoreVisible) => !isMoreVisible);
  }, [setIsMoreVisible]);
  if (!about && !location && !genre && !isOwnProfile) {
    return null;
  }
  return (
    <LeftSideComponentContainer>
      <LeftHandSideComponentTitle>About</LeftHandSideComponentTitle>
      <AboutContainer>
        {isOwnProfile && !about && !location && !genre ? (
          <Button
            isPrimary={true}
            size="large"
            disabled={false}
            onClick={() => dispatch(openModal({ status: true, component: 'EditProfile' }))}
          >
            Fill in your lacking profile data
          </Button>
        ) : (
          <>
            {location && (
              <AboutHeadlineItem
                text={location}
                icon="distance"
              />
            )}
            {genre && (
              <AboutHeadlineItem
                text={genre}
                icon="music_note"
              />
            )}
            {about && (
              <>
                <Box>
                  <AboutContent>
                    {aboutFirstPart}{' '}
                    <Box
                      component="span"
                      sx={{ display: aboutLongerThanMaxVisibleChars && isMoreVisible ? 'inline' : 'none' }}
                    >
                      {aboutRest}
                    </Box>
                  </AboutContent>
                </Box>
                {aboutLongerThanMaxVisibleChars && (
                  <Box>
                    <Button
                      isPrimary={true}
                      disabled={false}
                      size="small"
                      onClick={toggleExpanded}
                    >
                      {isMoreVisible ? 'Show less' : 'Show more'}
                    </Button>
                  </Box>
                )}
              </>
            )}
          </>
        )}
      </AboutContainer>
    </LeftSideComponentContainer>
  );
};

const AboutContainer = styled(Box, {
  name: 'AboutContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});

const AboutContent = styled(Box, {
  name: 'AboutContent',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodyMediumRegular,
});
