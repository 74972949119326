import { Box, Typography, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction } from 'react';
import Img from '../../../Components/Images/Img';
import { DataCy } from '../../../Helpers/DataCy';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { Button } from '../../../ui/buttons/Button';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../Redux/UI/UISlice';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import VerifiedIcon from '../../../Images/Icons/verified.svg';
import { Type } from '../../../Helpers/shared/Models/Type';
import StripeHandler from '../../../Components/Buttons/StripeHandler';

interface ProfileHeadlineProps {
  avatarSrc: string;
  userName: string;
  stripeEnabled: boolean;
  profileID: string;
  profileType: Type;
  isOwnProfile: boolean;
  isMobile?: boolean;
  setShareHatchLinksOpen: Dispatch<SetStateAction<boolean>>;
  setShareEPKLinkOpen: Dispatch<SetStateAction<boolean>>;
}

export const ProfileHeadline: FC<ProfileHeadlineProps> = ({
  avatarSrc,
  userName,
  stripeEnabled,
  isOwnProfile,
  profileType,
  profileID,
  isMobile,
  setShareHatchLinksOpen,
  setShareEPKLinkOpen,
}) => {
  const dispatch = useAppDispatch();
  return (
    <ProfileHeadlineContainer>
      <UserAvatar
        src={avatarSrc}
        dataCy={DataCy.image('avatar')}
      />
      <ProfileHeadlineInnerContainer>
        <UserName>{userName}</UserName>
        {stripeEnabled && <UserEnabledIcon src={VerifiedIcon}></UserEnabledIcon>}
      </ProfileHeadlineInnerContainer>
      {isOwnProfile && (
        <ProfileHeadlineInnerContainer isMobile={isMobile}>
          <Button
            isPrimary={true}
            size={isMobile ? 'small' : 'large'}
            disabled={false}
            onClick={() => {
              setShareEPKLinkOpen(true);
            }}
          >
            {isMobile ? 'Share EPK' : 'Share electronic press-kit'}
          </Button>
          <Button
            isPrimary={false}
            size={isMobile ? 'small' : 'large'}
            disabled={false}
            onClick={() => {
              setShareHatchLinksOpen(true);
            }}
          >
            Share HatchLink
          </Button>
          {(profileType === 'artist' || profileType === 'user' || profileType === 'showrunner') && (
            <StripeHandler
              viewType={profileType}
              targetID={profileID}
              buttonSize={isMobile ? 'small' : 'large'}
            />
          )}
          <Button
            isPrimary={false}
            size={isMobile ? 'small' : 'large'}
            disabled={false}
            onClick={() =>
              dispatch(
                openModal({
                  status: true,
                  component: profileType === 'artist' || profileType === 'user' ? 'EditProfile' : 'EditArtistProfile',
                }),
              )
            }
            data-cy={DataCy.button('editProfile')}
          >
            Edit Profile
          </Button>
        </ProfileHeadlineInnerContainer>
      )}
    </ProfileHeadlineContainer>
  );
};

const ProfileHeadlineContainer = styled(Box, {
  name: 'ProfilePageHeadlineContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  flexGrow: 1,
  gap: '24px',
  maxWidth: '1200px',
});

const ProfileHeadlineInnerContainer = styled(Box, {
  name: 'ProfileHeadlineInnerContainer',
})<{ isMobile?: boolean }>(({ isMobile }) =>
  isMobile
    ? {
        gap: '12px',
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
      }
    : {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '12px',
      },
);

const UserAvatar = styled(Img, {
  name: 'UserAvatar',
})({
  width: '200px',
  height: '200px',
  borderRadius: '999px',
});

const UserName = styled(Typography, {
  name: 'UserName',
})({
  textAlign: 'center',
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.displaySmall,
});

const UserEnabledIcon = styled('img', {
  name: 'UserEnabledIcon',
})({
  width: '40px',
  height: '40px',
});
