import React, { FC } from 'react';
import dayjs from 'dayjs';
import { ExternalShowCardProps } from './ExternalShowCard';
import { SmallShowCardContainer, SmallShowCardVenueIconContainer, SmallShowCardText } from './SmallShowCard';

export const ExternalSmallShowCard: FC<ExternalShowCardProps> = ({ id, startTime, title, link }) => {
  const parsedDate = new Date(startTime);
  const month = dayjs(parsedDate).format('M');
  const day = parsedDate.getDate();

  return (
    <SmallShowCardContainer
      onClick={() => {
        window.open(link, '_blank');
      }}
    >
      <SmallShowCardVenueIconContainer>{/* <SmallShowCardVenueIcon src={NotTH} /> */}</SmallShowCardVenueIconContainer>
      <SmallShowCardText>{`${month}/${day} • ${title}`}</SmallShowCardText>
    </SmallShowCardContainer>
  );
};
