import { Checkbox as MuiCheckbox, FormControlLabel as MuiFormControlLabel, styled } from '@mui/material';
import { colors } from '../shared/Colors';
import { typography } from '../shared/TypographySharedElements';

export const Checkbox = styled(MuiCheckbox, {
  name: 'Checkbox',
})({
  svg: {
    fontSize: '22px',
    color: colors.SystemGray400,
    strokeWidth: '1px',
  },
  '&.Mui-checked': {
    svg: {
      color: colors.SystemBlack,
    },
  },
});

export const FormControlLabel = styled(MuiFormControlLabel, {
  name: 'FormControlLabel',
})({
  '&.MuiFormControlLabel-root': {
    '.MuiTypography-root': {
      fontFamily: 'Satoshi-Variable',
      ...typography.bodySmallRegular,
    },
    '.MuiFormControlLabel-label': {
      fontFamily: 'Satoshi-Variable',
      ...typography.bodySmallRegular,
    },
  },
});
