import React, { FC, useCallback, useMemo, useState } from 'react';
import { ShowCard } from '../ShowCard/ShowCard';
import { Box, styled, Typography } from '@mui/material';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import { RightHandSideComponentTitle } from '../Shared/SharedElements';
import { UpcomingShow, useGetSortedUpcomingShowsWithExternal } from './useGetUpcomingShows';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { IconButton } from '../../../ui/buttons/IconButton';
import { Type } from '../../../Helpers/shared/Models/Type';
import { openModal } from '../../../Redux/UI/UISlice';
import { Button } from '../../../ui/buttons/Button';
import { useAppDispatch } from '../../../hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { ExternalShow } from '../../../Helpers/shared/Models/Show';
import { isExternalShow } from './helpers';
import { ExternalShowCard } from '../ShowCard/ExternalShowCard';

interface UpcomingShowsProps {
  userId: string;
  isMobile: boolean;
  type?: Type;
  isOwnProfile?: boolean;
}

export const UpcomingShows: FC<UpcomingShowsProps> = ({ userId, isMobile, type, isOwnProfile }) => {
  const navigate = useNavigate();
  const { profileID } = useParams();
  const userID = profileID || userId;
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const upcomingShows = useGetSortedUpcomingShowsWithExternal({ userId, page, isMobile, setIsLoading, type });
  const actualPerPage = isMobile ? 1 : 3;
  const isNextPageEnabled = useMemo(() => {
    const totalViewed = page * actualPerPage;
    return upcomingShows.total > totalViewed;
  }, [isMobile, page, upcomingShows.total]);
  const viewableUpcomingShows = [...(upcomingShows.data ?? [])].slice(
    0 + (page - 1) * actualPerPage,
    page * actualPerPage,
  );
  const isPrevPageEnabled = useMemo(() => {
    return page > 1;
  }, [page]);

  const getEditPath = useCallback(() => {
    if (type === 'artist' || type === 'user') {
      return '/artist/manage-shows';
    } else if (type === 'showrunner') {
      return `/showrunner-tools/${userID}`;
    } else {
      return `/venues/manage/${userID}`;
    }
  }, [type, userID]);

  const setNextPage = useCallback(() => {
    if (isNextPageEnabled) {
      setPage((page) => page + 1);
    }
  }, [isNextPageEnabled]);

  const setPreviousPage = useCallback(() => {
    if (isPrevPageEnabled) {
      setPage((page) => page - 1);
    }
  }, [isPrevPageEnabled]);

  if (isEmpty(upcomingShows)) {
    return null;
  }
  if (isLoading) {
    return (
      <RightHandSideComponentContainer>
        <RightHandSideComponentTitle>Upcoming shows</RightHandSideComponentTitle>
        <Box
          display="block"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      </RightHandSideComponentContainer>
    );
  }

  return (
    <RightHandSideComponentContainer>
      <UpcomingShowsHeadingContainer>
        <RightHandSideComponentTitle>Upcoming shows</RightHandSideComponentTitle>
        {isOwnProfile && (
          <Box
            display="flex"
            gap="8px"
          >
            <Button
              isPrimary={false}
              size="small"
              onClick={() => {
                const path = getEditPath();
                navigate(path);
              }}
            >
              Edit Show
            </Button>
            <Button
              isPrimary={false}
              size="small"
              onClick={() => {
                dispatch(
                  openModal({
                    status: true,
                    component: 'CreateShow',
                    data: {
                      artistID: type === 'artist' || type === 'user' ? userID : null,
                      SRID: type === 'showrunner' ? userID : null,
                      venueID: type === 'venue' ? userID : null,
                      defaultDate: new Date(),
                      viewType: type,
                    },
                  }),
                );
              }}
            >
              Add Show
            </Button>
          </Box>
        )}
      </UpcomingShowsHeadingContainer>
      {!isEmpty(upcomingShows.data) ? (
        <>
          {viewableUpcomingShows.map((upcomingShow) => {
            const externalShow: ExternalShow = isExternalShow(upcomingShow) ? upcomingShow : null;
            const checkedUpcomingShow: UpcomingShow = !isExternalShow(upcomingShow)
              ? (upcomingShow as unknown as UpcomingShow)
              : null;
            return (
              <>
                {isExternalShow(upcomingShow) ? (
                  <ExternalShowCard {...externalShow} />
                ) : (
                  <ShowCard
                    performers={{ artists: checkedUpcomingShow.artists, total: checkedUpcomingShow.artists.length }}
                    showrunnerGroup={checkedUpcomingShow.showrunnerGroups[0]}
                    id={checkedUpcomingShow.id}
                    date={checkedUpcomingShow.startTime}
                    venue={checkedUpcomingShow.venue}
                    name={checkedUpcomingShow.name}
                  />
                )}
              </>
            );
          })}
          <Box
            display="flex"
            justifyContent="center"
            gap="12px"
          >
            <IconButton
              isPrimary={true}
              onClick={setPreviousPage}
              disabled={!isPrevPageEnabled}
              size="large"
              iconName="chevron_left"
            />
            <IconButton
              isPrimary={true}
              onClick={setNextPage}
              disabled={!isNextPageEnabled}
              size="large"
            />
          </Box>
        </>
      ) : (
        <Typography
          padding="60px 0"
          textAlign="center"
          width="100%"
        >
          No upcoming shows
        </Typography>
      )}
    </RightHandSideComponentContainer>
  );
};

const UpcomingShowsHeadingContainer = styled(Box, {
  name: 'YouTubeHeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});
