import { Box, Link, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { ButtonIcon } from '../shared/ButtonIcon';
import { typography } from '../shared/TypographySharedElements';
import { colors } from '../shared/Colors';
import { HatchLinkProps } from './HatchLinksList';

export const HatchLink: FC<HatchLinkProps> = ({ text, url, openInNewTab, darkMode }) => {
  return text && url ? (
    <HatchLinksLinkElement
      href={`${url.includes('http://') || url.includes('https://') ? url : 'http://' + url}`}
      target={openInNewTab ? '_blank' : ''}
    >
      <HatchLinkMainContainer>
        <HatchLinkIconContainer>
          <i className="material-symbols-outlined text-black">open_in_new</i>
        </HatchLinkIconContainer>
        <HatchLinkText>{text}</HatchLinkText>
      </HatchLinkMainContainer>
    </HatchLinksLinkElement>
  ) : (
    <></>
  );
};
const HatchLinksLinkElement = styled(Link, {
  name: 'HatchLinksLinkElement',
})({
  textDecoration: 'none',
});

//todo: separate dark mode
const HatchLinkMainContainer = styled(Box, {
  name: 'HatchLinkMainContainer',
})({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  padding: '20px 0',
  backgroundColor: colors.SystemWhite,
  borderRadius: '12px',
  // Causing issues on larger displays. Recommend assuming full container width and limiting container sizing from above component-level. -RDF
  // maxWidth: '800px',
});

const HatchLinkIconContainer = styled(Box, {
  name: 'HatchLinkIconContainer',
})({
  position: 'absolute',
  left: '24px',
  top: '24px',
});

const HatchLinkText = styled(Typography, {
  name: 'HatchLinkText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.headlineSmall,
});

const CallToActionText = styled(Typography, {
  name: 'CallToActionText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.bodySmallBold,
});
