import { Accordion, AccordionDetails, AccordionSummary, Box, styled, Typography } from '@mui/material';
import React, { FC, useState } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { breakpoints } from '../../../ui/shared/MediaQueries';

interface FAQProps {
  faqData: {
    name: string;
    title: string;
    details: string;
  }[];
}

export const FAQ: FC<FAQProps> = ({ faqData }) => {
  const [expanded, setExpanded] = React.useState<string | false>('faq1');

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    event.preventDefault();
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <FAQMainCont>
      <FAQTitle>Frequently Asked Questions</FAQTitle>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        width="100%"
      >
        {faqData.map((singleFAQ, index) => {
          return (
            <FAQAccordion
              key={singleFAQ.title}
              expanded={expanded === singleFAQ.name}
              onChange={handleChange(singleFAQ.name)}
            >
              <FAQAccordionSummary
                expandIcon={<FAQExpandIcon className="material-symbols-outlined">keyboard_arrow_down</FAQExpandIcon>}
                aria-controls={`panel${index}-content`}
                id={`panel${index}-header`}
              >
                {singleFAQ.title}
              </FAQAccordionSummary>
              <FAQAccordionDetails>{singleFAQ.details}</FAQAccordionDetails>
            </FAQAccordion>
          );
        })}
      </Box>
    </FAQMainCont>
  );
};

const FAQMainCont = styled(Box, {
  name: 'FAQMainCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '60px',
  width: '100%',
  maxWidth: '800px',
  [`@media ${breakpoints.md}`]: {
    gap: '40px',
  },
});

const FAQTitle = styled(Typography, {
  name: 'FAQTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.displaySmall,
  textAlign: 'center',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    padding: '0 20px',
    ...typography.headlineMedium,
  },
});

const FAQExpandIcon = styled('i', {
  name: 'FAQExpandIcon',
})({
  width: '24px',
  height: '24px',
  color: colors.THOrange,
});

const FAQAccordion = styled(Accordion, {
  name: 'FAQAccordion',
})({
  borderBottom: `1px solid ${colors.SystemGray600}`,
  backgroundColor: 'transparent',
  '&:last-child': {
    borderBottom: 'none',
  },
});

const FAQAccordionSummary = styled(AccordionSummary, {
  name: 'FAQAccordionSummary',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.headlineSmall,
});

const FAQAccordionDetails = styled(AccordionDetails, {
  name: 'FAQAccordionDetails',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.buttonLarge,
});
