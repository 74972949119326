import React, { useEffect, useState } from 'react';
import { Type } from '../../Helpers/shared/Models/Type';
import { APIURL } from '../../Helpers/configConstants';
import axios from 'axios';
import { useAppSelector } from '../../hooks';
import { Button } from '../../ui/buttons/Button';
import LabelButton from './LabelButton';

export default function StripeHandler(props: {
  viewType: Type;
  targetID: string;
  stripeID?: string;
  className?: string;
  useLabelButton?: boolean;
  buttonSize?: 'small' | 'medium' | 'large';
}) {
  const user = useAppSelector((state) => state.user.data);
  const [stripeLink, setStripeLink] = useState(null);
  const getStripeAccount = async () => {
    let stripeRequest = await axios.post(APIURL + `ind/stripe-request`, {
      SECRET_UID: user.uid,
      venueID: props.viewType === 'venue' ? props.targetID : null,
      viewType: props.viewType,
      targetID: props.targetID,
      stripeID: props.stripeID,
    });
    setStripeLink(stripeRequest.data.url);
  };

  const openStripe = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    window.open(stripeLink, '_blank').focus();
  };

  useEffect(() => {
    if (props.viewType && props.targetID && !stripeLink && user.uid) {
      getStripeAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, props]);

  return !props.useLabelButton ? (
    <Button
      formTarget="_blank"
      isPrimary={false}
      size={props.buttonSize || 'large'}
      disabled={!stripeLink}
      href={stripeLink || null}
      onClick={(e) => {
        openStripe(e);
      }}
    >
      {!stripeLink ? 'Loading Payouts Securely...' : 'Manage Payouts'}
    </Button>
  ) : (
    <div className="flex">
      {stripeLink ? (
        //label button for legacy support

        <LabelButton
          className={`${props.className ? props.className : 'bg-amber-500 text-white'}`}
          link={stripeLink}
          newTab
        >
          Manage Payouts
        </LabelButton>
      ) : (
        <LabelButton className={`${props.className ? props.className : 'bg-amber-500 text-white'}`}>
          Loading Payouts Securely...
        </LabelButton>
      )}
    </div>
  );
}
