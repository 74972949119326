import { Box, styled } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { ShowDetailsHeading } from './ShowDetailsHeading';
import {
  useGetAllArtistsQuery,
  useGetAllShowrunnerGroupsQuery,
  useGetAllVenuesQuery,
  useGetShowQuery,
} from '../../Redux/API/PublicAPI';
import { useParams } from 'react-router-dom';
import { getDisplayName, renderPageTitle } from '../../Helpers/HelperFunctions';
import ReactPixel from 'react-facebook-pixel';
import { ShowDetailsUsersInfo } from './ShowDetailsUsersInfo';
import { ShowDetailsSingleUser } from './ShowDetailsSingleUserInfo';
import { ShowDetailsGallery } from './ShowDetailsGallery';
import { isEmpty } from 'lodash';
import { ShowDetailsMapWithAddr } from './ShowDetailsMapWithAddr';
import { breakpoints } from '../../ui/shared/MediaQueries';
import { ShowDetailsAbout } from './ShowDetailsAbout';
import { ShowDetailsMedia } from './ShowDetailsMedia';

interface ShowDetailsProps {
  showID?: string;
  applicationDetails?: boolean;
  manageView?: boolean;
}

export const ShowDetails: FC<ShowDetailsProps> = ({ showID, applicationDetails, manageView }) => {
  const showId = useParams()?.showID ?? showID;
  const showQuery = useGetShowQuery({
    showId: showId,
  });
  const show = showQuery.data;
  const [videoLoading, setVideoLoading] = useState(true);
  const venuesQuery = useGetAllVenuesQuery();
  const venue = venuesQuery.data?.[show?.venueID];
  const artists = useGetAllArtistsQuery();
  const showrunnersQuery = useGetAllShowrunnerGroupsQuery();
  const showrunnerId = show?.legacyShowrunner?.[0]?.uid || show?.legacyShowrunner?.[0]?.id;
  const showrunner = showrunnersQuery.data?.[showrunnerId];

  useEffect(() => {
    if (show?.name) {
      renderPageTitle(show.name);
    }
  }, [show]);

  useEffect(() => {
    if (show?.metaPixel) {
      ReactPixel.init(show.metaPixel);
      ReactPixel.pageView();
    }
  }, [show]);
  if (isEmpty(show)) {
    return null;
  }
  return (
    <ShowDetailsMainCont flyerUrl={show?.flyerWebP ?? show?.flyer}>
      <ShowDetailsMainContCover>
        <ShowDetailsInnerCont>
          <ShowDetailsHeading
            show={show}
            location={{
              address: venue?.location?.address,
              city: venue?.location?.city,
              state: venue?.location?.state,
              zip: venue?.location?.zip,
            }}
            venue={venue}
            applicationDetails={applicationDetails}
            manageView={manageView}
          />
          {!isEmpty(show?.performers) && (
            <ShowDetailsUserCont>
              <ShowDetailsUsersInfo type="Artists">
                {show?.performers?.map((performer) => (
                  <ShowDetailsSingleUser
                    key={performer?.id}
                    name={
                      performer?.id == '0' ? performer?.name : getDisplayName('artist', artists?.data?.[performer?.id])
                    }
                    avatarUrl={artists?.data?.[performer?.id]?.avatarWebP ?? artists?.data?.[performer?.id]?.avatar}
                    type="user"
                    id={performer?.id}
                  />
                ))}
              </ShowDetailsUsersInfo>
            </ShowDetailsUserCont>
          )}

          <ShowDetailsVenueShowrunnerCont>
            <ShowDetailsUserCont fullWidth={true}>
              <ShowDetailsUsersInfo type="Venue">
                <ShowDetailsSingleUser
                  name={venue?.name}
                  avatarUrl={venue?.avatarWebP ?? venue?.avatar}
                  venueAddress={venue?.location}
                  type="venue"
                  id={venue?._key}
                />
              </ShowDetailsUsersInfo>
            </ShowDetailsUserCont>
            {showrunner && (
              <ShowDetailsUserCont>
                <ShowDetailsUsersInfo type="Showrunner">
                  <ShowDetailsSingleUser
                    name={showrunner?.name}
                    avatarUrl={showrunner?.avatarWebP ?? showrunner?.avatar}
                    type="showrunner"
                    id={showrunner?._key}
                  />
                </ShowDetailsUsersInfo>
              </ShowDetailsUserCont>
            )}
          </ShowDetailsVenueShowrunnerCont>
          {show?.description && <ShowDetailsAbout about={show?.description} />}

          {show?.media && <ShowDetailsGallery images={show?.media} />}
          {show.youtubeLink && show.youtubeLink.length > 0 && <ShowDetailsMedia link={show.youtubeLink} />}
          <ShowDetailsMapWithAddrBottomCont>
            <ShowDetailsMapWithAddr
              location={{
                address: venue?.location?.address,
                city: venue?.location?.city,
                state: venue?.location?.state,
                zip: venue?.location?.zip,
              }}
              show={show}
              venue={venue}
              isBottomLine={true}
              applicationDetails={applicationDetails}
              manageView={manageView}
            />
          </ShowDetailsMapWithAddrBottomCont>
        </ShowDetailsInnerCont>
      </ShowDetailsMainContCover>
    </ShowDetailsMainCont>
  );
};

const ShowDetailsMainCont = styled(Box, {
  name: 'ShowDetailsMainCont',
  shouldForwardProp: (prop) => prop !== 'flyerUrl',
})<{ flyerUrl: string }>(({ flyerUrl }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minWidth: '100vw',
  background: `url(${flyerUrl})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  flexGrow: 1,
}));

const ShowDetailsMainContCover = styled(Box, {
  name: 'ShowDetailsMainContCover',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  minWidth: '100vw',
  backdropFilter: 'blur(70px)',
});

const ShowDetailsInnerCont = styled(Box, {
  name: 'ShowDetailsInnerCont',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '1280px',
  width: '100%',
  gap: '16px',
  padding: '20px',
});

const ShowDetailsUserCont = styled(Box, {
  name: 'ShowDetailsArtistCont',
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  display: 'flex',
  width: fullWidth !== false ? '100%' : '50%',
  maxWidth: fullWidth !== false ? '800px' : '400px',
  [`@media ${breakpoints.md}`]: {
    width: '100%',
    maxWidth: '100%',
  },
}));

const ShowDetailsVenueShowrunnerCont = styled(Box, {
  name: 'ShowDetailsVenueShowrunnerCont',
})({
  display: 'flex',
  gap: '16px',
  width: '100%',
  maxWidth: '800px',
  justifyContent: 'flex-start',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'column',
    maxWidth: '100%',
  },
});

const ShowDetailsMapWithAddrBottomCont = styled(Box, {
  name: 'ShowDetailsMapWithAddrBottomCont',
})({
  width: '100%',
  maxWidth: '800px',
  paddingBottom: '120px',
  [`@media ${breakpoints.md}`]: {
    paddingBottom: '80px',
  },
});
