import { Box, Menu, Typography, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useState } from 'react';
import { useAppSelector } from '../../hooks';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { DynamicNotification } from './DynamicNotification';
import { NotificationTypes, ParsedNotifications } from '../../Helpers/shared/Models/Notifications';
import LabelButton from '../../Components/Buttons/LabelButton';
import { Button } from '../../ui/buttons/Button';

interface NotificationsMenuProps {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  limit: number;
  sortedNotifications: ParsedNotifications[];
  handleVisibility: (state: boolean) => void;
  clearAllNotifs: () => void;
  notifications: {
    read?: ParsedNotifications[];
    unread?: ParsedNotifications[];
  };
  refEl: Element;
}

export const NotificationsMenu: FC<NotificationsMenuProps> = ({
  refEl,
  isOpen,
  setIsOpen,
  limit,
  sortedNotifications,
  handleVisibility,
  clearAllNotifs,
  notifications,
}) => {
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);
  const [currentLimit, setCurrentLimit] = useState(limit || 2);
  const user = useAppSelector((state) => state.user.data);

  return (
    <NotificationsMenuContainer
      open={isOpen}
      onClose={() => setIsOpen(false)}
      darkMode={darkMode}
      anchorEl={refEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <NotificationsTitle darkMode={darkMode}>Notifications</NotificationsTitle>
      <NotificationsInnerContainer>
        {user?.type?.artist?.enabled && !user.stripeEnabled && (
          <>
            <DynamicNotification
              closeFunction={() => handleVisibility(false)}
              key="payouts"
              id={'notification'}
              data={{}}
              type={NotificationTypes.SET_UP_PAYOUTS}
              timestamp={Date.now()}
            />
          </>
        )}
        {sortedNotifications.length > 0 ? (
          sortedNotifications.map((notification, i) => {
            if (i < currentLimit) {
              return (
                <DynamicNotification
                  closeFunction={() => handleVisibility(false)}
                  key={i}
                  {...notification}
                />
              );
            } else {
              return null;
            }
          })
        ) : (
          <Box
            display="flex"
            flexDirection="column"
          >
            <NotificationSubtitle darkMode={darkMode}>You're all set!</NotificationSubtitle>
            <NotificationText darkMode={darkMode}>Keep an eye out here for any new updates.</NotificationText>
          </Box>
        )}
      </NotificationsInnerContainer>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
      >
        {sortedNotifications?.length > 0 ? (
          <LabelButton
            className="border text-red-400 border-red-400"
            icon="close"
            onClick={() => clearAllNotifs()}
          >
            Clear All Notifications
          </LabelButton>
        ) : (
          <></>
        )}
      </Box>
      {notifications?.read?.length > currentLimit || notifications?.unread?.length > currentLimit ? (
        <Button
          isPrimary={true}
          size="small"
          disabled={false}
          onClick={() => setCurrentLimit(currentLimit + (currentLimit || 5))}
        >
          Show More
        </Button>
      ) : (
        <></>
      )}
    </NotificationsMenuContainer>
  );
};

const NotificationsMenuContainer = styled(Menu, {
  name: 'NotificationIcon',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  '.MuiPaper-root': {
    backgroundColor: darkMode ? colors.SystemBlack : colors.SystemWhite,
  },
}));
const NotificationsInnerContainer = styled(Box, {
  name: 'NotificationsInnerContainer',
})({
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  overflowY: 'scroll',
});
const NotificationsTitle = styled(Typography, {
  name: 'NotificationsTitle',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  fontFamily: 'Satoshi-Variable',
  color: darkMode ? colors.SystemWhite : colors.SystemBlack,
  ...typography.headlineMedium,
  textAlign: 'center',
}));

const NotificationSubtitle = styled(Typography, {
  name: 'NotificationSubtitle',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  fontFamily: 'Satoshi-Variable',
  color: darkMode ? colors.SystemWhite : colors.SystemBlack,
  ...typography.bodyLargeBold,
  textAlign: 'center',
}));

const NotificationText = styled(Typography, {
  name: 'NotificationText',
  shouldForwardProp: (prop) => prop !== 'darkMode',
})<{ darkMode: boolean }>(({ darkMode }) => ({
  fontFamily: 'Satoshi-Variable',
  color: darkMode ? colors.SystemWhite : colors.SystemBlack,
  ...typography.bodySmallRegular,
  textAlign: 'center',
}));
