import { Box, styled } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { HatchLink } from './HatchLink';
import { Button } from '../buttons/Button';
import { useParams } from 'react-router-dom';
import { Type } from '../../Helpers/shared/Models/Type';
export interface HatchLinkProps {
  text: string;
  url: string;
  darkMode?: boolean;
  openInNewTab?: boolean;
}

export interface HatchLinksListProps {
  links: HatchLinkProps[];
  maxRenderNumber: number;
  openInNewTab?: boolean;
  darkMode?: boolean;
  userType?: Type | string;
  userId?: string;
}

export const HatchLinksList: FC<HatchLinksListProps> = ({
  links,
  maxRenderNumber,
  openInNewTab,
  darkMode,
  userType,
  userId,
}) => {
  const { profileID } = useParams();
  const userID = profileID || userId;
  const HatchLinks = useMemo(() => {
    if (maxRenderNumber) {
      return links.slice(0, maxRenderNumber).map((singleLink) => {
        return (
          <HatchLink
            key={singleLink.url}
            {...singleLink}
            darkMode={darkMode}
            openInNewTab={openInNewTab}
          />
        );
      });
    } else {
      return links.map((singleLink) => {
        return (
          <HatchLink
            key={singleLink.url}
            {...singleLink}
            darkMode={darkMode}
            openInNewTab={openInNewTab}
          />
        );
      });
    }
  }, [links, maxRenderNumber, openInNewTab]);

  console.log(userType, 'userType');

  return (
    <HatchLinksListContainer>
      {HatchLinks}
      <Button
        isPrimary={true}
        disabled={false}
        size="large"
        onClick={() =>
          window.open(
            `${process.env.REACT_APP_PUBLIC_URL}/hl/${userType === 'user' || userType === 'u' ? 'artist' : userType}/${userID}`,
          )
        }
      >
        View all in HatchLinks
      </Button>
    </HatchLinksListContainer>
  );
};

const HatchLinksListContainer = styled(Box, {
  name: 'HatchLinksListContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  gap: '16px',
});
