import React, { useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { setFullscreen } from '../../Redux/UI/UISlice';
import { LoadingPage } from '../Register/Loading';
import { Box, Typography, styled } from '@mui/material';
import { ResetPasswordForm } from './ResetPasswordForm';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';
import { FullPageContainer, SideContainer, SideContainerInner } from '../Register/Shared/Containers';
import {
  UserSelectionHeadline,
  SubRegistrationText,
  SubRegistrationLink,
  RightHandSideTitle,
  RightHandSideFootText,
} from '../Register/Shared/RegistrationTypographies';

export const ResetPasswordPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, []);

  if (isLoading) {
    return (
      <FullPageContainer>
        <LoadingPage loadingText="Signing you in" />
      </FullPageContainer>
    );
  }

  return (
    <FullPageContainer>
      <SideContainer isLeft={true}>
        <SideContainerInner>
          <Box
            display="flex"
            flexDirection="column"
            gap="8px"
          >
            <UserSelectionHeadline>Reset your password</UserSelectionHeadline>
            <ResetPasswordSubtitle>
              Fill in your email and we'll send you a link to reset your password.
            </ResetPasswordSubtitle>
          </Box>
          <ResetPasswordForm setIsLoading={setIsLoading} />
          <SubRegistrationText>
            <SubRegistrationLink to={'/login'}>Back to sign in</SubRegistrationLink>
          </SubRegistrationText>
        </SideContainerInner>
      </SideContainer>
      <SideContainer>
        <RightHandSideTitle>TuneHatch</RightHandSideTitle>
        <RightHandSideFootText>© 2024 TuneHatch Inc.</RightHandSideFootText>
      </SideContainer>
    </FullPageContainer>
  );
};

const ResetPasswordSubtitle = styled(Typography, {
  name: 'ResetPasswordSubtitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodySmallRegular,
});
