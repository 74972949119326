import Lo from 'lodash';
import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import FlyerSharingStatus from '../../Components/FlyerSharingStatus';
import { sortShowsByDate } from '../../Helpers/HelperFunctions';
import { useAppDispatch } from '../../hooks';
import { useGetAllShowsQuery } from '../../Redux/API/PublicAPI';
import { openModal } from '../../Redux/UI/UISlice';

function useUpcomingShows(venueId: string) {
  const usedAllShows = useGetAllShowsQuery();

  const allShows = usedAllShows.data ?? {};
  const shows = useMemo(() => {
    const showList = sortShowsByDate(allShows, 'asc');
    const upcomingShows = showList.filter((show) => {
      return (
        Lo.isNil(Lo.get(show, 'shareStatus')) === false &&
        venueId === Lo.get(show, 'venueID') &&
        new Date(Lo.get(show, 'endtime')).getTime() > Date.now()
      );
    });

    return {
      upcomingShared: upcomingShows.filter((show) => {
        const fbSharedAt = Lo.get(show, 'shareStatus.facebook.sharedAt');
        const igSharedAt = Lo.get(show, 'shareStatus.instagram.sharedAt');

        return typeof fbSharedAt === 'string' || typeof igSharedAt === 'string';
      }),
      upcomingScheduled: upcomingShows.filter((show) => {
        const fbScheduledAt = Lo.get(show, 'shareStatus.facebook.scheduledAt');
        const igScheduledAt = Lo.get(show, 'shareStatus.instagram.scheduledAt');

        return typeof fbScheduledAt === 'string' || typeof igScheduledAt === 'string';
      }),
    };
  }, [allShows, venueId]);

  return {
    isLoading: usedAllShows.isLoading,
    shows,
  };
}

interface UpcomingShowSharesProps {
  venueID: string;
}

function useOpenManageShow() {
  const dispatch = useAppDispatch();

  return (showId: string) =>
    dispatch(
      openModal({
        status: true,
        component: 'ManageShow',
        data: {
          viewType: 'venue',
          showID: showId,
        },
      }),
    );
}

export const UpcomingShowShares: FC<UpcomingShowSharesProps> = ({ venueID }) => {
  const [activeTab, setActiveTab] = useState<'upcomingShared' | 'upcomingScheduled'>('upcomingScheduled');
  const openManageShow = useOpenManageShow();
  const { isLoading, shows } = useUpcomingShows(venueID);

  if (isLoading === true) {
    return (
      <>
        <h1 className="text-2xl font-black p-2">Social Media Shares</h1>
        <ContentBox>{'Loading...'}</ContentBox>
      </>
    );
  }

  const listedShows = activeTab === 'upcomingShared' ? shows.upcomingShared : shows.upcomingScheduled;
  return (
    <>
      <h1 className="text-2xl font-black p-2">Social Media Shares</h1>
      <ContentBox className={'w-11/12 mx-auto'}>
        <TabBox>
          <TabButton
            onClick={() => setActiveTab('upcomingScheduled')}
            isActive={activeTab === 'upcomingScheduled'}
          >
            Scheduled
          </TabButton>
          <TabButton
            onClick={() => setActiveTab('upcomingShared')}
            isActive={activeTab === 'upcomingShared'}
          >
            Already shared
          </TabButton>
        </TabBox>
        <ListBox>
          {listedShows.length === 0 && <>There are no shows meeting the criteria</>}
          {listedShows.map((show) => {
            const showId = show.showID ?? show._key;
            return (
              <div key={showId}>
                <ShowHeader>
                  {show.name}
                  {' - '}
                  <ButtonLink onClick={() => openManageShow(showId)}>Manage Show</ButtonLink>
                </ShowHeader>
                <FlyerSharingStatus
                  showID={show._key}
                  useListView={true}
                />
              </div>
            );
          })}
        </ListBox>
      </ContentBox>
    </>
  );
};

const ShowHeader = styled.h1`
  font-weight: 600;
  padding-top: 4px;
`;

const ButtonLink = styled.button`
  font-weight: 500;
  color: rgb(96, 165, 250);

  &:hover {
    color: rgb(124, 184, 255);
  }
`;

const ContentBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const TabBox = styled.div`
  display: flex;
  gap: 5px;
`;

const TabButton = styled.button<{ isActive: boolean }>`
  padding: 5px 15px;
  border-bottom: solid 4px ${(props) => (props.isActive === true ? 'orange' : 'lightgrey')};

  &:hover {
    background: #eaeaea;
  }
`;

const ListBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  & > * {
    border-bottom: solid 1px lightgrey;
  }
`;
