import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { isEmpty } from 'lodash';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../Helpers/HelperFunctions';
import { PromoterFormData } from './PromoterFormData';
import { getClaimData } from '../Shared/ClaimDataHelpers';
import { PromoterFormStep1 } from './PromoterForm1';
import { PromoterFormStep2 } from './PromoterForm2';
import { PromoterFormStep3 } from './PromoterForm3';
import { setFullscreen } from '../../../Redux/UI/UISlice';
import { useAppDispatch } from '../../../hooks';

interface ShowrunnerGroupsData {
  id: string;
  name?: string;
  genre?: string;
  subgenre?: string;
  contact?: {
    phone?: string;
    email?: string;
  };
  role: string;
}

export const PromoterForm = () => {
  const [step, setStep] = useState(1);
  const [avatar, setAvatar] = useState(null);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, []);

  const parsedInitialData = getClaimData();
  const showrunnerGroupData = !isEmpty(parsedInitialData?.showrunnerGroups)
    ? parsedInitialData.showrunnerGroups.find((group: ShowrunnerGroupsData) => group.role === 'admin')
    : null;

  const initialPromoterFormValues: PromoterFormData.Values = {
    groupName: showrunnerGroupData?.name ?? '',
    genre: showrunnerGroupData?.genre ?? 'none',
    subGenre: showrunnerGroupData?.subgenre ?? null,
    firstName: parsedInitialData?.firstName ?? '',
    lastName: parsedInitialData?.lastName ?? '',
    contact: {
      phone: showrunnerGroupData?.contact.phone ?? '',
      email: showrunnerGroupData?.contact.email ?? '',
    },
    primaryCity: '',
    secondaryCity: null,
    socials: {
      instagram: null,
      tiktok: null,
      spotify: null,
      youtube: null,
    },
    about: '',
    roster: [],
  };

  const promoterForm = useForm({
    resolver: zodResolver(PromoterFormData.formSchema),
    defaultValues: initialPromoterFormValues,
    values: initialPromoterFormValues,
    mode: 'all',
  });

  const submitPromoter = async (data: PromoterFormData.Values) => {
    try {
      const token = getCookie('SECRET_UID');
      const dataToSend = {
        body: JSON.stringify({
          name: data.groupName,
          genre: data.genre,
          subgenre: data.subGenre,
          showrunner: {
            firstName: data.firstName,
            lastName: data.lastName,
          },
          contact: {
            phone: data.contact.phone,
            email: data.contact.email,
          },
          socials: {
            instagramLink: data.socials.instagram ?? null,
            youtubeLink: data.socials.youtube ?? null,
            spotifyLink: data.socials.spotify ?? null,
            tikTokLink: data.socials.tiktok ?? null,
          },
          primaryCity: {
            name: data.primaryCity,
            meta: {
              coordinates: '',
            },
          },
          secondaryCity:
            data.secondaryCity !== null
              ? {
                  name: data.secondaryCity,
                  meta: {
                    coordinates: '',
                  },
                }
              : null,
          about: data.about,
          roster: [...data.roster],
        }),
        avatar: avatar,
      };

      const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
        Accept: '*/*',
        'Accept-encoding': 'gzip, deflate, br',
        Connection: 'keep-alive',
      };
      const result = showrunnerGroupData?.id
        ? await axios.patch(
            `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/showrunner-groups/${showrunnerGroupData?.id}`,
            dataToSend,
            {
              headers,
            },
          )
        : await axios.post(
            `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/showrunner-groups`,
            dataToSend,
            {
              headers,
            },
          );

      if (result.status === 201 || result.status === 204) {
        navigate('/register/success/promoter');
      } else {
        window.alert(result.data?.message);
        setStep(1);
      }
    } catch (error: unknown) {
      if (error instanceof axios.AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setStep(2);
      } else {
        setStep(1);
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  return (
    <FormProvider {...promoterForm}>
      <form onSubmit={promoterForm.handleSubmit(submitPromoter)}>
        {step === 1 && (
          <PromoterFormStep1
            step={step}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <PromoterFormStep2
            step={step}
            setStep={setStep}
            setAvatar={setAvatar}
            selectedAvatar={avatar}
          />
        )}
        {step === 3 && (
          <PromoterFormStep3
            step={step}
            setStep={setStep}
            submitPromoter={submitPromoter}
          />
        )}
      </form>
    </FormProvider>
  );
};
