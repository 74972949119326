import Lo from 'lodash';
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import ShowCard, { ShowCardShow } from '../../Components/Cards/ShowCard/ShowCard2';
import LoadingWrapper from '../../Components/Layout/LoadingWrapper';
import { showInProgress, upcomingShows } from '../../Helpers/HelperFunctions';
import { NewModels } from '../../Helpers/shared/Models/NewModels';
import { Showrunner } from '../../Helpers/shared/Models/Showrunner';
import { Venue } from '../../Helpers/shared/Models/Venue';
import { useGetAllShowrunnerGroupsQuery, useGetAllVenuesQuery, useListShowsQuery } from '../../Redux/API/PublicAPI';
import TicketPurchase from '../Modals/TicketPurchase';

// import DoorPurchase from "../Views/Ticketing/DoorPurchase";

function HatchcodeContent(props: { shows: NewModels.Show[]; venue?: Venue; showrunner?: Showrunner }) {
  const { venue, showrunner, shows } = props;
  const allShows = useMemo(() => Lo.keyBy(shows, 'id'), [shows]);
  const [filteredShows, setFilteredShows] = useState<NewModels.Show[] | null>(null);
  const [currentShow, setCurrentShow] = useState<number | boolean | null>(null);
  const [nextShow, setNextShow] = useState<NewModels.Show | null>(null);

  useEffect(() => {
    if (allShows) {
      let ts = upcomingShows(
        allShows,
        venue?.uid ?? venue?._key ?? showrunner?.uid ?? showrunner?._key,
        venue != null ? 'venue' : 'showrunner',
      );
      setFilteredShows(ts);
    }
  }, [allShows]);

  useEffect(() => {
    if (filteredShows?.length) {
      setNextShow(filteredShows?.[0]);
      setCurrentShow(showInProgress(filteredShows[0]));
    }
  }, [filteredShows]);

  const showCardShows = useMemo<ShowCardShow[]>(
    () =>
      (filteredShows ?? []).map((show) => ({
        name: show.name,
        venueID: show.venueID,
        showrunner: show.legacyShowrunner,
        performers: show.performers.map((performer) => ({
          ...performer,
          uid: performer.id,
        })),
        min_age: show.minAge,
        _key: show.id,
        applications: [] as any,
        invites: [] as any,
        ticket_cost: show.ticketCost,
        doorPrice: show.doorPrice,
        ticket_tiers: show.ticketTiers,
        starttime: show.startTime,
        endtime: show.endTime,
      })),
    [filteredShows],
  );
  console.log(nextShow);
  return (
    <>
      {filteredShows && (venue || showrunner) && (
        <>
          <h1 className="text-center text-2xl font-black">Welcome to {venue?.name || showrunner?.name}</h1>
          {nextShow ? (
            currentShow ? (
              <>
                <div className="flex flex-col items-center">
                  <h1 className="text-center">{nextShow.name}</h1>
                  <span className="bg-green-400 p-2 text-xs rounded-full text-white">IN PROGRESS</span>
                  <>
                    {(nextShow.ticketCost || nextShow.doorPrice || nextShow.ticketTiers) && (
                      <div className="flex-col">
                        <h1>Door Purchase</h1>
                        {/* todo: door purchase */}
                        <TicketPurchase
                          doorPurchase
                          showID={nextShow.id}
                          defaultQuantity={1}
                        />
                        {/* <DoorPurchase showID={nextShow._key} doorPrice={(nextShow.doorPrice && nextShow.doorPrice !== "" && nextShow.doorPrice !== "0") ? nextShow.doorPrice : nextShow.ticket_cost} /> */}
                      </div>
                    )}
                    {!nextShow.ticketCost && !nextShow.doorPrice && (
                      <>
                        <div className="sm-pad flex-col">
                          <p className="centered">Welcome to the show!</p>
                          <p className="centered sm-top bold">
                            This is a free show: that means you're good to head on in!
                          </p>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </>
            ) : (
              <>
                <div className="showGallery">
                  <h2 className="centered">Coming up at {venue?.name}</h2>
                  {showCardShows.map((show) => {
                    return <ShowCard show={show} />;
                  })}
                </div>
              </>
            )
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
}

const emptyArray: unknown[] = [];
export default function Hatchcode() {
  const { venueID, SRID } = useParams();
  const listShowsQuery = useListShowsQuery({
    page: 1,
    perPage: 10000,
    venueId: venueID,
    showrunnerId: SRID,
  });
  const shows = listShowsQuery?.data?.data;

  const venuesQuery = useGetAllVenuesQuery();
  const venue = venuesQuery.data?.[venueID];

  const showrunnersQuery = useGetAllShowrunnerGroupsQuery();
  const showrunner = showrunnersQuery.data?.[SRID];

  return (
    <LoadingWrapper queryResponse={[listShowsQuery, venuesQuery, showrunnersQuery]}>
      <HatchcodeContent
        shows={shows ?? (emptyArray as NewModels.Show[])}
        venue={venue}
        showrunner={showrunner}
      />
    </LoadingWrapper>
  );
}
