import Lo from 'lodash';
import z from 'zod';

export namespace PromoterFormData {
  export const step1Schema = z.object({
    groupName: z.string().min(1, 'You must enter your group name'),
    genre: z.string().refine((value) => Lo.isEmpty(value) == false && value !== 'none', 'You must choose a genre'),
    subGenre: z.string().nullable(),
    firstName: z.string().min(1, 'You must enter your first name'),
    lastName: z.string().min(1, 'You must enter your last name'),
    contact: z.object({
      phone: z.string().min(1, 'You must enter your phone number'),
      email: z.string().min(1, 'You must enter your email').email('The provided value must be an email'),
    }),
    primaryCity: z.string().min(1, 'Primary city is required'),
    secondaryCity: z.string().nullable(),
  });
  export type Step1Values = z.output<typeof step1Schema>;
  export const step1Fields = Object.keys(step1Schema.shape) as (keyof Step1Values)[];

  export const step2Schema = z.object({
    socials: z.object({
      instagram: z.string().nullable(),
      tiktok: z.string().nullable(),
      spotify: z.string().nullable(),
      youtube: z.string().nullable(),
    }),
    about: z.string().max(250, 'The message can be at most 250 characters long'),
  });
  export type Step2Values = z.output<typeof step2Schema>;
  export const step2Fields = Object.keys(step2Schema.shape) as (keyof Step2Values)[];

  export const step3Schema = z.object({
    roster: z.string().array().nullable(),
  });

  export const formSchema = step1Schema.merge(step2Schema).merge(step3Schema);
  export type Values = z.TypeOf<typeof formSchema>;
}
