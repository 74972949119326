import React, { useCallback, useEffect, useState } from 'react';
import { useGetAllVenuesQuery } from '../../../../Redux/API/PublicAPI';
import FilterInputDisplay from './FilterInputDisplay';
import { FormKeys } from '../../../../Redux/User/UserSlice';
import { venueTestimonials } from '../../../../Pages/VenuesLP/VenueReviews/VenueTestimonials';

export default function VenueFilterInput(props: {
  searchParams?: string[];
  className?: string;
  limit?: number;
  placeholder?: string;
  selectFn?: any;
  removeFn?: any;
  value: any[];
  defaultValue?: any[];
  form?: FormKeys;
  field?: string;
  dataCy?: string;
}) {
  const venues = useGetAllVenuesQuery();
  const [venuesArray, setVenuesArray] = useState([]);
  const [error, setError] = useState('');

  const selectFnExtended = useCallback((selectedVenueOption: any) => {
    if (selectedVenueOption.id === 0) {
      setError('');
      props.selectFn(selectedVenueOption);
      return;
    }
    const selectedVenue = venues.data[selectedVenueOption.id];
    if (selectedVenue === undefined) {
      return;
    }

    if (selectedVenue.isOpenForManagers !== true) {
      setError(
        `You cannot create a show on behalf of ${selectedVenue.name} because they are a TuneHatch partner venue. Please email ${selectedVenue.name} to receive your official show listing/ticket link.`,
      );
      window.alert(
        `You cannot create a show on behalf of ${selectedVenue.name} because they are a TuneHatch partner venue. Please email ${selectedVenue.name} to receive your official show listing/ticket link.`,
      );
      return;
    }
    setError('');
    props.selectFn(selectedVenueOption);
  }, []);
  useEffect(() => {
    let v: { [key: string]: any }[] = [];

    if (!venues.isLoading) {
      Object.keys(venues.data).forEach((id) => {
        const singleVenue = venues.data[id];
        v.push(singleVenue);
      });
    }

    setVenuesArray(v);
  }, [venues.isLoading, venues.data]);

  return (
    <FilterInputDisplay
      type="venue"
      limit={props.limit}
      placeholder={props.placeholder}
      className={props.className ? props.className : ''}
      data={venuesArray}
      searchParams={props.searchParams || ['name']}
      selectFn={selectFnExtended}
      field={props.field}
      removeFn={props.removeFn}
      value={props.value}
      form={props.form}
      defaultValue={props.defaultValue}
      dataObject={venues.data}
      error={error}
      dataCy={'venue_input_filter'}
    />
  );
}
