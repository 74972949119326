import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CallbackContainer, CallbackText } from './SpotifyCallback';
import { Button } from '../ui/buttons/Button';
import { useUpdateTikTokCredentialsMutation } from '../Redux/API/ArtistAPI';
import { AuthState } from './AuthState';

export function TikTokCallback() {
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchParams] = useSearchParams();
  const [updateCredentials] = useUpdateTikTokCredentialsMutation();

  /**
   * @see https://developers.tiktok.com/doc/login-kit-web/#manage_authorization_response
   *
   * TikTok describes how to use "state" field for preventing request forgery attacks
   * https://developers.tiktok.com/doc/login-kit-web/
   * In short, before sending the request to the provider store the state
   */

  const params = {
    accessCode: searchParams.get('code'),
    error: searchParams.get('error'),
    state: searchParams.get('state'),
  };

  const tikTokState = AuthState.reconstruct('tiktok');

  if (tikTokState.isEqual(params.state) === false) {
    // it means that the user is subject to CSRF https://datatracker.ietf.org/doc/html/rfc6749#section-10.12
    // in such case we CANNOT proceed and send the code to the BE
    // TODO figure out what do to in such case

    throw new Error('The states are not equal. CSRF detected.');
  }

  return (
    <CallbackContainer>
      {!isUpdated ? (
        <>
          <CallbackText>Just one step and your TikTok statistics will be visible for everyone!</CallbackText>
          <Button
            isPrimary={true}
            size="large"
            disabled={false}
            onClick={() => {
              updateCredentials({ tikTokAccessCode: params.accessCode })
                .unwrap()
                .then((result) => {
                  setIsUpdated(true);
                })
                .catch((error) => console.log(error));
            }}
          >
            Update credentials now!
          </Button>
        </>
      ) : (
        <>
          <CallbackText>Thank you! You have succesfully finished updating your Spotify stats.</CallbackText>
          <CallbackText>You can close this page now.</CallbackText>
        </>
      )}
    </CallbackContainer>
  );
}
