import { zodResolver } from '@hookform/resolvers/zod';
import React, { Dispatch, FC, SetStateAction } from 'react';
import { DataCy } from '../../Helpers/DataCy';
import { ApiErrorCodes } from '../../Redux/API/ErrorCodes';
import { isBadRequestResponse } from '../../Redux/API/Request/RequestErrors';
import { TextField } from '../../ui/inputs/TextField';
import { Button } from '../../ui/buttons/Button';
import { FormStyled, LeftHandSideContainerInner } from '../Register/Shared/Containers';
import z from 'zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import axios, { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { setCookie } from '../../Helpers/HelperFunctions';

interface LoginFormProps {
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}
interface LoginFormItems {
  email: string;
  password: string;
}

//note: removed minimum password length for login, as some users may have a password set shorter than 8 characters
const schema = z.object({
  email: z.string({ message: 'The email must present' }).email('The email must be a valid email'),
  password: z.string({ message: 'The password must present' }),
});

export const LoginForm: FC<LoginFormProps> = ({ setIsLoading }) => {
  const navigate = useNavigate();
  const initialLoginState = {
    email: '',
    password: '',
  };
  const loginForm = useForm<LoginFormItems>({
    resolver: zodResolver(schema),
    defaultValues: initialLoginState,
    values: initialLoginState,
    mode: 'all',
  });

  const submitLogin: SubmitHandler<LoginFormItems> = async (data) => {
    setIsLoading(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/tokens`,
        data,
        {
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            // 'Date': Date.now(),
            // 'Connection': 'keep-alive',
            // 'Keep-Alive': 'timeout=5'
          },
        },
      );
      if (result.status === 201) {
        setIsLoading(false);
        setCookie('SECRET_UID', result.data.token);
        navigate('/');
        //login fn does not automatically log user in. Added refresh here as a workaround. RDF
        window.location.reload();
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError) {
        const response = error.response.data;
        if (isBadRequestResponse(response) && response.code === ApiErrorCodes.CredentialsAreInvalid) {
          window.alert('Given credentials are invalid or the user does not exist');
        } else {
          console.warn({ error: error.response });
          window.alert('Unknown server error');
        }
      } else {
        window.alert('Unknown server error');
      }
      setIsLoading(false);
    }
  };
  return (
    <FormStyled onSubmit={loginForm.handleSubmit(submitLogin)}>
      <LeftHandSideContainerInner>
        <TextField
          {...loginForm.register('email')}
          type="email"
          label="Email"
          placeholder="Enter your email"
          error={!!loginForm.formState.errors?.email?.message}
          helperText={!!loginForm.formState.errors?.email?.message && <>{loginForm.formState.errors?.email?.message}</>}
        />
        <TextField
          {...loginForm.register('password')}
          type="password"
          label="Password"
          placeholder="Enter your password"
          error={!!loginForm.formState.errors?.password}
          helperText={
            !!loginForm.formState.errors?.password?.message && <>{loginForm.formState.errors?.password?.message}</>
          }
        />
      </LeftHandSideContainerInner>
      <Button
        isPrimary={true}
        size="large"
        type="submit"
        data-cy={DataCy.button('logIn')}
      >
        Log in
      </Button>
    </FormStyled>
  );
};
