import { Box, Card, CardContent, Divider, Typography, styled, useMediaQuery } from '@mui/material';
import React, { FC, useMemo } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import AvatarImg from '../../../Images/Avatar.webp';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import dayjs from 'dayjs';
import { InfoItem } from './InfoItem';
import { IconAvatar } from '../Shared/SharedElements';

export interface ShowCardProps {
  id: string;
  name: string;
  date: string;
  venue: ShowVenue;
  performers?: Performers;
  showrunnerGroup?: ShowShowrunnerGroup;
}

export interface ShowVenue {
  id: string;
  name: string;
  avatarWebP: string;
}

export interface Performers {
  artists: ShowArtist[];
  total: number | string;
}

export interface ShowArtist {
  id: string;
  firstName: string;
  lastName: string;
  avatarWebP: string;
  stagename?: string;
}

interface ShowShowrunnerGroup {
  id: string;
  name: string;
  avatarWebP: string;
}

export const ShowCard: FC<ShowCardProps> = ({ id, name, date, venue, performers, showrunnerGroup }) => {
  const parsedDate = new Date(date);
  const hours = dayjs(parsedDate).format('h A');
  const month = dayjs(parsedDate).format('MMM');
  const year = parsedDate.getFullYear();
  const day = parsedDate.getDate();
  const isMobile = useMediaQuery(breakpoints.md);
  const numberOfItemsToDisplay = isMobile ? 1 : 2;

  const ArtistsList = useMemo(() => {
    return performers.artists.slice(0, numberOfItemsToDisplay).map((artist) => {
      return (
        <InfoItemInnerContainer key={artist.id}>
          <IconAvatar src={artist.avatarWebP ? artist.avatarWebP : AvatarImg} />
          <InfoItemElementName>
            {artist.stagename ? artist.stagename : `${artist.firstName} ${artist.lastName}`}
          </InfoItemElementName>
        </InfoItemInnerContainer>
      );
    });
  }, [performers, numberOfItemsToDisplay]);

  return (
    <ShowCardContainer
      onClick={() => {
        window.open(`/shows/${id}`);
      }}
    >
      <DateSection>
        <DateSubContainer>
          <SubtitleDateText>{month}</SubtitleDateText>
          <HeadlineDateText>{day}</HeadlineDateText>
          <SubtitleDateText>{year}</SubtitleDateText>
        </DateSubContainer>
        <DateDivider />
        <SubtitleDateText>{hours}</SubtitleDateText>
      </DateSection>
      <ContentSection>
        <Title variant="h5">{name}</Title>
        <InfoItemsContainer>
          <InfoItem label="Venue">
            <InfoItemInnerContainer>
              <IconAvatar src={venue.avatarWebP ? venue.avatarWebP : AvatarImg} />
              <InfoItemElementName>{venue.name}</InfoItemElementName>
            </InfoItemInnerContainer>
          </InfoItem>
          {showrunnerGroup && (
            <InfoItem label="Promoter">
              <InfoItemInnerContainer>
                <IconAvatar src={showrunnerGroup.avatarWebP ? showrunnerGroup.avatarWebP : AvatarImg} />
                <InfoItemElementName>{showrunnerGroup.name}</InfoItemElementName>
              </InfoItemInnerContainer>
            </InfoItem>
          )}
          {performers.artists?.length > 0 && (
            <InfoItem label="Artist">
              {ArtistsList}
              {parseInt(`${performers.total}`) > numberOfItemsToDisplay && (
                <IconAvatar>+{parseInt(`${performers.total}`) - numberOfItemsToDisplay}</IconAvatar>
              )}
            </InfoItem>
          )}
        </InfoItemsContainer>
      </ContentSection>
    </ShowCardContainer>
  );
};

export const ShowCardContainer = styled(Card, {
  name: 'ShowCardContainer',
})({
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '12px',
  backgroundColor: '#F8F7F5',
  boxShadow: 'none',
  minWidth: '208px',
  // This was causing issues on larger displays.
  // maxWidth: "736px",
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'column',
  },
});

export const DateSection = styled(Box, {
  name: 'DateSection',
})({
  backgroundColor: colors.THOrange,
  color: colors.SystemGray900,
  padding: '20px',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '12px 0 0 12px',
  minWidth: '125px',
  width: '125px',
  [`@media ${breakpoints.md}`]: {
    width: '100%',
    flexDirection: 'row',
    borderRadius: '0',
    justifyContent: 'space-between',
  },
});
export const DateSubContainer = styled(Box, {
  name: 'DateSubContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'row',
  },
});
const DateDivider = styled(Divider, {
  name: 'DateDivider',
})({
  width: '100%',
  margin: '12px 0',
  backgroundColor: colors.SystemGray900,
  opacity: '10%',
  [`@media ${breakpoints.md}`]: {
    display: 'none',
  },
});

export const SubtitleDateText = styled(Typography, {
  name: 'SubtitleDateText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.subtitle,
});

export const HeadlineDateText = styled(Typography, {
  name: 'HeadlineDateText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineMedium,
  [`@media ${breakpoints.md}`]: {
    ...typography.subtitle,
  },
});

export const ContentSection = styled(CardContent, {
  name: 'ContentSection',
})({
  display: 'flex',
  padding: '26px 47px 26px 24px',
  flexDirection: 'column',
  gap: '24px',

  [`@media ${breakpoints.md}`]: {
    gap: '32px',
    padding: '20px',
  },
});

export const Title = styled(Typography, {
  name: 'Title',
})({
  color: colors.SystemBlack,
  ...typography.headlineMedium,
  fontFamily: 'Satoshi-Variable',
  [`@media ${breakpoints.md}`]: {
    ...typography.headlineSmall,
  },
});

const InfoItemsContainer = styled(Box, {
  name: 'InfoItemsContainer',
})({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
});

const InfoItemInnerContainer = styled(Box, {
  name: 'InfoItemInnerContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  paddingRight: '12px',
  [`@media ${breakpoints.md}`]: {
    paddingRight: '8px',
  },
});

const InfoItemElementName = styled(Typography, {
  name: 'InfoItemElementName',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.subtitle,
  [`@media ${breakpoints.md}`]: {
    ...typography.buttonSmall,
  },
});
