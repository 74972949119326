import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { ButtonIcon } from '../../../ui/shared/ButtonIcon';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';

interface AboutHeadlineItemProps {
  text: string;
  icon: string;
}

export const AboutHeadlineItem: FC<AboutHeadlineItemProps> = ({ text, icon }) => {
  return (
    <AboutHeadlineContainer>
      <ButtonIcon
        disabled={true}
        isPrimary={false}
        size="large"
        iconName={icon}
      />
      <AboutHeadlineText>{text}</AboutHeadlineText>
    </AboutHeadlineContainer>
  );
};

const AboutHeadlineContainer = styled(Box, {
  name: 'AboutHeadlineContainer',
})({
  display: 'flex',
  alignItems: 'center',
  gap: '2px',
});

const AboutHeadlineText = styled(Typography, {
  name: 'AboutHeadlineText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodyLargeRegular,
});
