import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import { Hero } from './Hero/Hero';
import { VenuesRotator } from './VenuesRotator/VenuesRotator';
import AvatarVenue from '../../Images/Venues/kimbros.webp';
import { PromotionText } from './PromotionText/PromotionText';
import { ShowcaseBoxGroup } from './Showcases/ShowcaseBoxGroup';
import { ShowcaseBox } from './Showcases/ShowcaseBox';
import TicketCoin from '../../Images/VenueLP/ticketCoin.svg';
import HandWithBill from '../../Images/VenueLP/handWithBill.svg';
import Fire from '../../Images/VenueLP/fire.svg';
import InstaCam from '../../Images/VenueLP/instaCam.svg';
import PaperPlane from '../../Images/VenueLP/paperPlane.svg';
import StarMsg from '../../Images/VenueLP/starMessage.svg';
import Box1Img from '../../Images/VenueLP/showcaseBox1.svg';
import Box2Img from '../../Images/VenueLP/showcaseBox2.svg';
import Box3Img from '../../Images/VenueLP/showcaseBox3.svg';
import Box4Img from '../../Images/VenueLP/showcaseBox4.svg';
import Box5Img from '../../Images/VenueLP/showcaseBox5.svg';
import Box6Img from '../../Images/VenueLP/showcaseBox6.svg';
import { VenueReviews } from './VenueReviews/VenueReviews';
import { StepsToTuneHatch } from './StepsToTunehatch/StepsToTuneHatch';
import { Pricing } from './Pricing/Pricing';
import { CTABox } from './CTABox/CTABox';
import { breakpoints } from '../../ui/shared/MediaQueries';
import { useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { isEmpty } from 'lodash';
import { venueTestimonials } from './VenueReviews/VenueTestimonials';

export const VenuesLP: FC = () => {
  const venues: any = useGetAllVenuesQuery();
  console.log(venues);
  return (
    <Box>
      <Hero />
      {!isEmpty(venues.data) && <VenuesRotator venuesList={Object.values(venues.data)} />}
      <PromotionText />
      <ShowcaseBoxGroup
        title="Sell more tickets, settle shows with ease"
        image1={<Box1Img1 src={TicketCoin} />}
        image2={<Box1Img2 src={HandWithBill} />}
      >
        <ShowcaseBox
          text="Manage and track ticket sales effortlessly, with real-time updates and analytics to boost sales."
          imageUrl={Box1Img}
        />
        <ShowcaseBox
          text="Make artist payments stress-free, accurate, and immediate with just one click."
          imageUrl={Box2Img}
        />
      </ShowcaseBoxGroup>
      <ShowcaseBoxGroup
        title="Promote shows faster than ever before"
        image1={<Box2Img1 src={Fire} />}
        image2={<Box2Img2 src={InstaCam} />}
      >
        <ShowcaseBox
          text="Create eye-catching flyers and event listings in seconds, saving you time and effort."
          imageUrl={Box3Img}
        />
        <ShowcaseBox
          text="Update show details, flyers, and more across all platforms through our auto-sync calendar embeds and scheduled media posts, saving you time and effort."
          imageUrl={Box4Img}
        />
      </ShowcaseBoxGroup>
      <ShowcaseBoxGroup
        title="Book great talent, without the hassle"
        image1={<Box3Img1 src={PaperPlane} />}
        image2={<Box3Img2 src={StarMsg} />}
      >
        <ShowcaseBox
          text="Manage all your booking communications in one place, reducing email chaos and streamlining your workflow."
          imageUrl={Box5Img}
        />
        <ShowcaseBox
          text="Discover and book top talent tailored to your venue's needs with our artist EPKs and performance statistics, giving you exactly what you need to book the right artists for you!"
          imageUrl={Box6Img}
        />
      </ShowcaseBoxGroup>
      <VenueReviews testimonials={venueTestimonials} />
      <Pricing />
      <StepsToTuneHatch />
      <CTABox />
    </Box>
  );
};

const Box1Img1 = styled('img', {
  name: 'Box1Img1',
})({
  position: 'absolute',
  width: '168px',
  height: '168px',
  left: '-5%',
  top: '-10%',
  [`@media ${breakpoints.md}`]: {
    width: '85px',
    height: '85px',
    top: '0',
    left: '0',
    transform: 'translate(-50%, -50%)',
  },
});

const Box1Img2 = styled('img', {
  name: 'Box1Img1',
})({
  position: 'absolute',
  width: '150px',
  height: '150px',
  right: '-5%',
  top: '50%',
  [`@media ${breakpoints.md}`]: {
    width: '75px',
    height: '75px',
    top: '80%%',
    right: '0',
  },
});

const Box2Img1 = styled('img', {
  name: 'Box1Img1',
})({
  position: 'absolute',
  width: '131px',
  height: '131px',
  left: '-7%',
  top: '-15%',
  [`@media ${breakpoints.md}`]: {
    width: '65px',
    height: '65px',
    top: '0',
    left: '0',
    transform: 'translate(-50%, -50%)',
  },
});

const Box2Img2 = styled('img', {
  name: 'Box1Img1',
})({
  position: 'absolute',
  width: '114px',
  height: '114px',
  right: '-5%',
  top: '50%',
  [`@media ${breakpoints.md}`]: {
    width: '56px',
    height: '56px',
    top: '100%',
    right: '0',
    transform: 'translate(50%, -50%)',
  },
});

const Box3Img1 = styled('img', {
  name: 'Box1Img1',
})({
  position: 'absolute',
  width: '156px',
  height: '156px',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  [`@media ${breakpoints.md}`]: {
    width: '78px',
    height: '78px',
  },
});

const Box3Img2 = styled('img', {
  name: 'Box1Img1',
})({
  position: 'absolute',
  width: '122px',
  height: '122px',
  right: '-3%',
  top: '-18%',
  [`@media ${breakpoints.md}`]: {
    width: '61px',
    height: '61px',
    top: '-5%',
  },
});
