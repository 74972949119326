import { Avatar, Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

export interface SingleVenueElementProps {
  avatarWebp: string;
  name: string;
}

export const SingleVenueElement: FC<SingleVenueElementProps> = ({ avatarWebp, name }) => {
  return (
    <SingleVenueContainer>
      <VenueAvatar src={avatarWebp} />
      <VenueName>{name}</VenueName>
    </SingleVenueContainer>
  );
};

const SingleVenueContainer = styled(Box, {
  name: 'SingleVenueContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  alignItems: 'center',
});

const VenueAvatar = styled(Avatar, {
  name: 'VenueAvatar',
})({
  width: '100px',
  height: '100px',
  borderRadius: '50%',
  margin: '0 50px',
});

const VenueName = styled(Typography, {
  name: 'VenueName',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray600,
  ...typography.bodySmallRegular,
  textAlign: 'center',
});
