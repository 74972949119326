export const colors = {
  THOrange: '#F99D1B',
  THOrangeLight: '#FDE7C6',
  SystemBlack: '#000000',
  SystemGray900: '#0E0E0E',
  SystemGray800: '#0E0E0E',
  SystemGray700: '#29292D',
  SystemGray600: '#484846',
  SystemGray500: '#91908D',
  SystemGray400: '#A9A8A5',
  SystemGray300: '#C1C0BC',
  SystemGray200: '#D9D8D4',
  SystemGray100: '#F2F0EC',
  SystemWhite: '#FFFFFF',
  AlertRed: '#F42828',
  AlertRedLight: '#FDDFDF',
};
