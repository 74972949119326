import { Avatar, Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { ShowDetailsUserMainText } from './ShowDetailsUsersInfo';
import { useAppDispatch } from '../../hooks';
import { openSidebar } from '../../Redux/UI/UISlice';

interface ShowDetailsSingleUserProps {
  name: string;
  venueAddress?: any;
  avatarUrl?: string;
  type: 'user' | 'venue' | 'showrunner';
  id: string;
  fullWidth?: boolean;
}

export const ShowDetailsSingleUser: FC<ShowDetailsSingleUserProps> = ({
  name,
  venueAddress,
  avatarUrl,
  type,
  id,
  fullWidth,
}) => {
  const dispatch = useAppDispatch();

  return (
    <ShowDetailsSingleUserCont fullWidth={fullWidth}>
      <ShowDetailsAvatar src={avatarUrl} />
      {!!venueAddress ? (
        <ShowDetailsSingleUserWithVenueAddrCont>
          <ShowDetailsTextIconGrp
            onClick={() => {
              dispatch(
                openSidebar({
                  status: true,
                  component: 'ViewProfile',
                  data: { type: type, id: id },
                }),
              );
            }}
          >
            <ShowDetailsUserMainText>{name}</ShowDetailsUserMainText>
            <ShowDetailsUserIcon className="material-symbols-outlined">chevron_right</ShowDetailsUserIcon>
          </ShowDetailsTextIconGrp>
          <Box
            display="flex"
            gap="4px"
            alignItems="center"
          >
            <ShowDetailsUserIcon
              isVenueAddress={true}
              className="material-symbols-outlined"
            >
              distance
            </ShowDetailsUserIcon>
            <ShowDetailsVenueAddrText>{`${venueAddress.city}, ${venueAddress.state}, USA`}</ShowDetailsVenueAddrText>
          </Box>
        </ShowDetailsSingleUserWithVenueAddrCont>
      ) : (
        <ShowDetailsTextIconGrp
          onClick={() => {
            dispatch(
              openSidebar({
                status: true,
                component: 'ViewProfile',
                data: { type: type, id: id },
              }),
            );
          }}
        >
          <ShowDetailsUserMainText>{name}</ShowDetailsUserMainText>
          <ShowDetailsUserIcon className="material-symbols-outlined">chevron_right</ShowDetailsUserIcon>
        </ShowDetailsTextIconGrp>
      )}
    </ShowDetailsSingleUserCont>
  );
};

const ShowDetailsAvatar = styled(Avatar, {
  name: 'ShowDetailsAvatar',
})({
  width: '80px',
  height: '80px',
});

const ShowDetailsSingleUserCont = styled(Box, {
  name: 'ShowDetailsSingleUserCont',
  shouldForwardProp: (prop) => prop !== 'fullWidth',
})<{ fullWidth?: boolean }>(({ fullWidth }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '12px',
  width: fullWidth !== false ? '100%' : 'calc(50% - 12px)',
}));

const ShowDetailsTextIconGrp = styled(Box, {
  name: 'ShowDetailsTextIconGrp',
})({
  display: 'flex',
  gap: '8px',
  cursor: 'pointer',
  alignItems: 'center',
});

const ShowDetailsSingleUserWithVenueAddrCont = styled(Box, {
  name: 'ShowDetailsSingleUserWithVenueAddrCont',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  gap: '8px',
});

const ShowDetailsUserIcon = styled('i', {
  name: 'ShowDetailsUserIcon',
  shouldForwardProp: (prop) => prop !== 'isVenueAddress',
})<{ isVenueAddress?: boolean }>(({ isVenueAddress }) => ({
  width: '24px',
  height: '24px',
  color: isVenueAddress ? colors.SystemGray500 : colors.SystemGray900,
}));

const ShowDetailsVenueAddrText = styled(Typography, {
  name: 'ShowDetailsVenueAddrText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodyLargeRegular,
});
