import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import AppControl from './AppControl';
import { SocketContext, socket } from './Context/socket';
import './Fonts/css/satoshi.css';
import { initFacebook } from './initFacebook';
import './Main.css';
import { store } from './Redux/store';

const container = document.getElementById('root');
const root = createRoot(container);
initFacebook();
root.render(
  <SocketContext.Provider value={socket}>
    <Provider store={store}>
      <AppControl />
    </Provider>
  </SocketContext.Provider>,
);
