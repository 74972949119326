import { Box, InputAdornment, styled } from '@mui/material';
import React, { FC, useCallback, ChangeEvent, Dispatch, SetStateAction, useMemo } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormSectionContainer,
  FormInputGroupWrapper,
  FormInputGroupElementWrapper,
  FormMainContainer,
  FormMainContainerColumn,
} from '../Shared/Containers';
import {
  FormStepHeadlineStepCounter,
  FormStepHeadlineTitle,
  FormSectionTitle,
  LogosInformationText,
  FileInputLikeButtonText,
} from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import kimbros from '../../../Images/Venues/kimbros.webp';
import the5spot from '../../../Images/Venues/the-5-spot.webp';
import springwater from '../../../Images/Venues/springwater-club-lounge.webp';
import teddystavern from '../../../Images/Venues/teddys-tavern.webp';
import raystevens from '../../../Images/Venues/ray-stevens.webp';
import { Avatar } from '../Shared/RegistrationIcons';
import AvatarImg from '../../../Images/Avatar.webp';
import { TextField } from '../../../ui/inputs/TextField';
import { Button } from '../../../ui/buttons/Button';
import { FileInputLikeButton } from '../Shared/Other';
import { ButtonIcon, SpecialButtonIcon } from '../../../ui/shared/ButtonIcon';
import { colors } from '../../../ui/shared/Colors';
import { useFormContext } from 'react-hook-form';
import { VenueFormData } from './VenueFormData';

interface VenueStep3Props extends FormStepProps {
  setAvatar: Dispatch<SetStateAction<File | null>>;
  selectedAvatar: File | null;
}

export const VenueFormStep3: FC<VenueStep3Props> = ({ step, setStep, setAvatar, selectedAvatar }) => {
  const venueForm = useFormContext();

  const avatarPreview = useMemo<string | null>(() => {
    if (selectedAvatar === null) {
      return null;
    }
    return window.URL.createObjectURL(selectedAvatar);
  }, [selectedAvatar]);

  const handleBack = useCallback(() => {
    setStep((step) => step - 2);
  }, [setStep]);

  const handleNextStep = useCallback(async () => {
    const isValid = await venueForm.trigger(VenueFormData.step3Fields);
    if (isValid === true) {
      setStep((step) => step + 1);
    }
  }, [venueForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 3 of 4</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Set up your public profile</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <FormSectionContainer>
            <FormSectionTitle>Upload profile photo</FormSectionTitle>
            <Box
              display="flex"
              alignItems="center"
              gap="16px"
            >
              <Avatar
                src={avatarPreview ?? AvatarImg}
                dataCy={DataCy.image('uploadedAvatar')}
              />
              <Box data-cy={'venueLogo'}>
                <FileInputLikeButton
                  name="avatar"
                  type="file"
                  size="small"
                  onChange={(e: ChangeEvent<HTMLInputElement>) => {
                    setAvatar(e.target.files[0] ?? null);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <ButtonIcon
                          size="small"
                          disabled={false}
                          isPrimary={false}
                          iconName="file_upload"
                        />
                        <FileInputLikeButtonText>Upload</FileInputLikeButtonText>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
              {avatarPreview !== null && (
                <Button
                  isPrimary={false}
                  sx={{ backgroundColor: colors.AlertRedLight }}
                  size="small"
                  startIcon={
                    <SpecialButtonIcon
                      isPrimary={false}
                      disabled={false}
                      size="small"
                      iconName="close"
                    />
                  }
                  onClick={() => setAvatar(null)}
                >
                  Remove
                </Button>
              )}
            </Box>{' '}
            <LogosInformationText>Recommended square aspect ratio</LogosInformationText>
            <LogosInformationText>Look how other venues did it</LogosInformationText>
            <GroupOfLogosExamples>
              <Avatar src={springwater} />
              <Avatar src={kimbros} />
              <Avatar src={raystevens} />
              <Avatar src={the5spot} />
              <Avatar src={teddystavern} />
            </GroupOfLogosExamples>
          </FormSectionContainer>
          <FormSectionContainer>
            <FormSectionTitle>Add social links (optional)</FormSectionTitle>
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...venueForm.register('socials.instagram')}
                  type="text"
                  label="Instagram"
                  placeholder="Paste a link or write @username"
                  error={venueForm.getFieldState('socials.instagram').invalid}
                  helperText={
                    venueForm.getFieldState('socials.instagram').invalid && (
                      <>{venueForm.getFieldState('socials.instagram').error.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...venueForm.register('socials.tiktok')}
                  type="text"
                  label="TikTok"
                  placeholder="Paste a link or write @username"
                  error={venueForm.getFieldState('socials.tiktok').invalid}
                  helperText={
                    venueForm.getFieldState('socials.tiktok').invalid && (
                      <>{venueForm.getFieldState('socials.tiktok').error.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...venueForm.register('socials.spotify')}
                  type="text"
                  label="Spotify"
                  placeholder="Paste a link"
                  error={venueForm.getFieldState('socials.spotify').invalid}
                  helperText={
                    venueForm.getFieldState('socials.spotify').invalid && (
                      <>{venueForm.getFieldState('socials.spotify').error.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...venueForm.register('socials.youtube')}
                  type="text"
                  label="YouTube"
                  placeholder="Paste a link"
                  error={venueForm.getFieldState('socials.youtube').invalid}
                  helperText={
                    venueForm.getFieldState('socials.youtube').invalid && (
                      <>{venueForm.getFieldState('socials.youtube').error.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
          </FormSectionContainer>
          <FormSectionContainer>
            <FormSectionTitle>Tell a few words about your venue to others (optional)</FormSectionTitle>
            <TextField
              {...venueForm.register('about')}
              type="text"
              label="About"
              placeholder="Write something about yourself"
              error={!!venueForm.formState.errors?.about?.message}
              helperText={
                !!venueForm.formState.errors?.about?.message && <>{venueForm.formState.errors?.about?.message}</>
              }
              multiline
              minRows={5}
              maxRows={5}
            />
          </FormSectionContainer>
          <FormMainContainer>
            <Button
              isPrimary={false}
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <FormMainContainerColumn>
              <Button
                isPrimary={true}
                size="large"
                onClick={handleNextStep}
                data-cy={DataCy.button('nextStep')}
              >
                Next
              </Button>
            </FormMainContainerColumn>
          </FormMainContainer>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};

const GroupOfLogosExamples = styled(Box, {
  name: 'GroupOfLogosExamples',
})({
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  alignItems: 'center',
  gap: '16px',
});
