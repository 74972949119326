import { Box, styled } from '@mui/material';
import React, { FC, useState } from 'react';
import { Button } from '../../../ui/buttons/Button';
// import TikTokIcon from '../../../Images/Icons/TikTok.svg';
import { TikTokSkeleton } from './TikTokSkeleton';
import TikTokEmbed from './TikTokEmbed';
import { RightHandSideComponentContainer } from '../Shared/SharedContainers';
import { RightHandSideComponentTitle, RightHandSideHeadingIcon } from '../Shared/SharedElements';

interface TikTokProps {
  tikTokLink: string;
}

export const TikTok: FC<TikTokProps> = ({ tikTokLink }) => {
  const [isLoading, setIsLoading] = useState(true);

  return (
    <RightHandSideComponentContainer>
      <ResizeContainer>
        <TikTokHeadingContainer>
          <TikTokInnerHeadingContainer>
            {/* <RightHandSideHeadingIcon src={TikTokIcon}/> */}
            <RightHandSideComponentTitle>TikTok</RightHandSideComponentTitle>
          </TikTokInnerHeadingContainer>
          <Button
            isPrimary={false}
            disabled={false}
            size="small"
            onClick={() => window.open(tikTokLink)}
          >
            Open in TikTok
          </Button>
        </TikTokHeadingContainer>
        {/* {isLoading &&
                <TikTokSkeleton/>
            } */}
        {/* <TikTokEmbed link={tikTokLink} setIsLoading={setIsLoading} isLoading={isLoading}/>     */}
      </ResizeContainer>
    </RightHandSideComponentContainer>
  );
};

const TikTokHeadingContainer = styled(Box, {
  name: 'TikTokHeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const ResizeContainer = styled(Box, {
  name: 'ResizeContainer',
})({});

const TikTokInnerHeadingContainer = styled(Box, {
  name: 'TikTokInnerHeadingContainer',
})({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
});
