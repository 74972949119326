import { Box, Typography, keyframes, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';

interface LoadingPageProps {
  loadingText: string;
}

export const LoadingPage: FC<LoadingPageProps> = ({ loadingText }) => {
  return (
    <LoaderContainer>
      <LoadingSpinnerContainer>
        <LoadingSpinnerItems />
        <LoadingSpinner />
      </LoadingSpinnerContainer>
      <LoadingText>{loadingText}</LoadingText>
    </LoaderContainer>
  );
};

const LoaderContainer = styled(Box, {
  name: 'LoaderContainer',
})({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '16px',
});

const LoadingSpinnerContainer = styled(Box, {
  name: 'LoadingSpinnerContainer',
})({
  display: 'flex',
  width: '32px',
  height: '32px',
  position: 'relative',
});

const LoadingSpinnerItems = styled(Box, {
  name: 'LoadingSpinner',
})({
  width: '32px',
  height: '32px',
  border: `4px solid ${colors.THOrange}`,
  borderRadius: '100%',
});

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const LoadingSpinner = styled(Box, {
  name: 'LoadingSpinner',
})({
  display: 'block',
  position: 'absolute',
  left: '0',
  right: '0',
  top: 'calc(50% - 7px)',
  animation: `${rotate} 2s linear infinite`,
  backgroundColor: 'white',
  width: '100%',
  height: '14px',
});

const LoadingText = styled(Typography, {
  name: 'LoadingText',
})({
  fontFamily: 'Satoshi-Variable',
  ...typography.headlineMedium,
});
