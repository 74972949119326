import React, { useEffect, useMemo } from 'react';
import { DataCy } from '../Helpers/DataCy';
import {
  useGetAllShowrunnerGroupsQuery, useGetAllShowsQuery, useSRGroupShows,
  // useGetAllShowsQuery,
  // useGetAllVenuesQuery,
} from '../Redux/API/PublicAPI';
import { useParams } from 'react-router-dom';
import ShowCalendar from '../Tools/ShowCalendar';
import ManageShowsList from '../Tools/Venue/ManageShowsList';
import LoadingWrapper from '../Components/Layout/LoadingWrapper';
import LabelButton from '../Components/Buttons/LabelButton';
import { openModal } from '../Redux/UI/UISlice';
import { FilterByShowTime } from '../Helpers/FilterFunctions/ShowFilterFunctions';
import { renderPageTitle } from '../Helpers/HelperFunctions';
import Button from '../Components/Buttons/Button';
import StripeHandler from '../Components/Buttons/StripeHandler';
import { useAppSelector } from '../hooks';
import { Show, ShowManagerType } from '../Helpers/shared/Models/Show';

export default function ManageShowrunner() {
  const { SGID } = useParams();
  const srGroups = useGetAllShowrunnerGroupsQuery();
  const user = useAppSelector((state) => state.user.data);
  const shows = useSRGroupShows({ srGroupId: SGID });
  const showrunner = srGroups.data?.[SGID!];

  useEffect(() => {
    if (showrunner) {
      renderPageTitle('Manage ' + showrunner.name);
    }
  }, [showrunner]);

  return (
    <LoadingWrapper queryResponse={[srGroups]}>
      {showrunner && (
        <>
          <div>
            <h1 className="text-2xl font-black text-center">{showrunner.name}</h1>
            <div className="flex flex-row justify-center mt-2 gap-2">
              <Button
                className="text-xs text-white border bg-amber-400"
                action={openModal({
                  status: true,
                  component: 'EditShowrunnerProfileForm',
                  data: { SRID: SGID },
                })}
                dataCy={DataCy.button('editGroup')}
              >
                Edit Showrunner
              </Button>
              {/* <Button
                className="text-xs text-white border bg-red-400"
                action={openModal({
                  status: true,
                  component: "EditShowrunnerToolbox",
                  data: { SRID: SGID },
                })}
              >
                Showrunner Toolbox
              </Button> */}
              <Button
                inline
                className="text-xs text-white border bg-blue-400"
                link={'/profile/showrunner/' + SGID}
              >
                Showrunner Page
              </Button>
              <StripeHandler
                className="flex justify-center transition-all text-center items-center disabled:bg-gray-400 bg-orange gap-1 p-4
        
                  rounded-md min-w-max w-28 h-11
                  text-white border-gray-300
                   
                  
                  filter hover:brightness-105
                  cursor-pointer
                  
                  text-xs border font-bold"
                useLabelButton
                viewType="showrunner"
                targetID={SGID}
                stripeID={showrunner?.stripe?.id}
              />
            </div>
            <div className="flex justify-center m-2">
              <LabelButton
                className="text-sm text-gray-500 border border-gray-500"
                iconClassName="text-base"
                icon="add"
                action={openModal({
                  status: true,
                  component: 'CreateShow',
                  data: { SRID: SGID, viewType: 'showrunner' },
                })}
              >
                New Show
              </LabelButton>
            </div>
          </div>
          <ShowCalendar
            showrunnerID={SGID}
            viewType="showrunner"
            filterFn={FilterByShowTime}
            shows={shows.data}
          />
          <ManageShowsList
            viewType="showrunner"
            showrunnerID={SGID}
            shows={shows.data}
          />
        </>
      )}
    </LoadingWrapper>
  );
}
