import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { IconAvatar } from '../Shared/SharedElements';
import AvatarImg from '../../../Images/Avatar.webp';
import { SingleArtistPlayedWith } from './TuneStats';

interface RecentlyPlayedWithProps {
  artistsList: SingleArtistPlayedWith[];
}

export const RecentlyPlayedWith: FC<RecentlyPlayedWithProps> = ({ artistsList }) => {
  if (artistsList.length > 0) {
    const artistListTrimmed = artistsList.slice(0, 10);

    return (
      <RecentlyPlayedWithContainer>
        <RecentlyPlayedWithHeading>Recently played with</RecentlyPlayedWithHeading>
        <RecentlyPlayedWithArtistList>
          {artistListTrimmed.map((singleArtist) => {
            return (
              <SingleRecentlyPlayedWithArtist key={singleArtist.id}>
                <IconAvatar src={singleArtist.avatarWebP ? singleArtist.avatarWebP : AvatarImg} />
                <SingleRecentlyPlayedWithArtistName>
                  {singleArtist.stageName
                    ? singleArtist.stageName
                    : `${singleArtist.firstName ? singleArtist.firstName : ''} ${singleArtist.lastName ? singleArtist.lastName : ''}`}
                </SingleRecentlyPlayedWithArtistName>
              </SingleRecentlyPlayedWithArtist>
            );
          })}
        </RecentlyPlayedWithArtistList>
      </RecentlyPlayedWithContainer>
    );
  } else {
    return null;
  }
};

const RecentlyPlayedWithContainer = styled(Box, {
  name: 'RecentlyPlayedWithContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
});

const RecentlyPlayedWithHeading = styled(Typography, {
  name: 'RecentlyPlayedWithHeading',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.subtitle,
});

const RecentlyPlayedWithArtistList = styled(Box, {
  name: 'RecentlyPlayedWithArtistList',
})({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '16px',
});

const SingleRecentlyPlayedWithArtist = styled(Box, {
  name: 'SingleRecentlyPlayedWithArtist',
})({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
});

const SingleRecentlyPlayedWithArtistName = styled(Typography, {
  name: 'SingleRecentlyPlayedWithArtistName',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.buttonSmall,
});
