import { Autocomplete, Avatar, Box, Chip, styled } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { MultipleSelectChip } from '../../../ui/inputs/ChipMultiselect';
import { Button } from '../../../ui/buttons/Button';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormSectionContainer,
} from '../Shared/Containers';
import {
  FormStepHeadlineStepCounter,
  FormStepHeadlineTitle,
  FormSectionTitle,
} from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { Controller, FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';
import axios, { AxiosResponse } from 'axios';
import { useSearchParams } from 'react-router-dom';
import { uniq } from 'lodash';

interface PromoterFormStep3Props extends FormStepProps {
  submitPromoter: SubmitHandler<FieldValues>;
}

interface ArtistType {
  id: string;
  firstname: string;
  lastname: string;
  avatar?: string | null;
  avatarWebP: string | null;
  stageName: string;
}
interface SingleOption {
  label: string;
  id: string;
  avatar: string | null;
}
export const PromoterFormStep3: FC<PromoterFormStep3Props> = ({ step, setStep, submitPromoter }) => {
  const [selectValue, setSelectValue] = useState<SingleOption[]>([]);
  const promoterForm = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [artistList, setArtistsList] = useState<SingleOption[]>([]);
  const [search, setSearch] = useSearchParams();

  const handleToggleRosterElements = useCallback(
    (value: string | string[], isDelete?: boolean) => {
      if (isDelete) {
        const actualValues: string[] = promoterForm.getValues('roster');
        const deletedElements: SingleOption | null = selectValue.find((singleartist) => singleartist.label === value);
        if (deletedElements) {
          const newValues = actualValues.filter((singleValue) => singleValue !== deletedElements.id);
          promoterForm.setValue('roster', newValues);
        }
      } else {
        const filteredOptions = artistList.filter((singleOption) => {
          if (typeof value === 'string') {
            return singleOption.label === value;
          } else {
            return !!value.find((singleValue) => {
              return singleValue === singleOption.label;
            });
          }
        });
        const indexesList = filteredOptions.map((opt) => opt.id);
        promoterForm.setValue('roster', uniq(filteredOptions));
      }
    },
    [artistList, promoterForm],
  );

  const getArtistList = useCallback(async () => {
    try {
      setIsLoading(true);
      const result = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/artists${search.get('search') ? '?phrase=' + search.get('search') : ''}`,
      );
      if (result.status === 200) {
        const artistsListUpdated = result.data.data.map((singleResult: ArtistType) => {
          return {
            id: singleResult.id,
            label: singleResult.stageName
              ? singleResult.stageName
              : `${singleResult.firstname ?? ''} ${singleResult.lastname ?? ''}`,
            avatar: singleResult.avatarWebP,
          };
        });
        setArtistsList(artistsListUpdated);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        window.alert(result.data.message);
      }
    } catch {
      setIsLoading(false);
      window.alert('Unknown server error');
    }
  }, [setArtistsList, search]);

  const handleBack = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  useEffect(() => {
    getArtistList();
  }, [getArtistList]);

  useEffect(() => {
    const newSelectVal = [...selectValue];
    const newRosterIds = newSelectVal.map((val) => val.id);
    promoterForm.setValue('roster', newRosterIds);
  }, [selectValue]);

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 3 of 3</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Add artists you work with (optional)</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <FormSectionContainer>
            <FormSectionTitle>Search for artist you work with</FormSectionTitle>
            <MultipleSelectChip
              selectValue={selectValue}
              setSelectValue={setSelectValue}
              options={artistList}
              handleChangeOuter={handleToggleRosterElements}
              label="Add artists"
              placeholder="Search for an artist"
              setSearch={setSearch}
              search={search.get('search')}
              dataCy={DataCy.select('artists')}
              isLoading={isLoading}
            />
          </FormSectionContainer>
          <Box
            display="flex"
            flexGrow={1}
          >
            <Button
              isPrimary={false}
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <Box
              display="flex"
              flexDirection="column"
              flexGrow={1}
            >
              <Button
                isPrimary={true}
                size="large"
                onClick={promoterForm.handleSubmit(submitPromoter)}
                data-cy={DataCy.button('createPromoter')}
              >
                Next
              </Button>
            </Box>
          </Box>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </Box>
  );
};
