import React, { FC } from 'react';
import { useGetArtistSocialStatisticsQuery } from '../Redux/API/ArtistAPI';
import { AuthState } from './AuthState';
import { Button } from '../ui/buttons/Button';
import { SocialStatAmount } from '../Pages/ProfilePage/SocialStats/SocialStatBox';
import { useAppSelector } from '../hooks';
import { SocialSpecialText } from './SpotifyAuth';

const useAuthorizeTikTokAccountLink = (link: string, artistId: string) => {
  const additionalParams = new URLSearchParams({
    state: AuthState.build({
      internalRedirectUrl: `${window.location.origin}/profile/a/${artistId}`,
      provider: 'tiktok',
    }).toString(),
  }).toString();

  return `${link}&${additionalParams}`;
};

interface ContinueWithTiktokProps {
  authLink: string;
  artistId: string;
}

const ContinueWithTikTok: FC<ContinueWithTiktokProps> = ({ authLink, artistId }) => {
  const tiktokAuthLink = useAuthorizeTikTokAccountLink(authLink, artistId);
  return (
    <Button
      isPrimary={true}
      size="small"
      onClick={() => window.open(tiktokAuthLink)}
    >
      Continue with TikTok
    </Button>
  );
};

interface TikTokAuthProps {
  artistId: string;
}

export const TikTokAuth: FC<TikTokAuthProps> = ({ artistId }) => {
  const userIdFromCookie = useAppSelector((select) => select.user.data).displayUID;
  const isOwnProfile = userIdFromCookie === artistId;
  const stats = useGetArtistSocialStatisticsQuery({ artistId });
  if (stats.isLoading === null || stats.data === undefined) {
    return <SocialSpecialText>Fetching stats...</SocialSpecialText>;
  }

  if (stats.data.tikTok.stats) {
    return <SocialStatAmount>{stats.data.tikTok.stats.likeQty}</SocialStatAmount>;
  }

  if (!isOwnProfile && stats.data.tikTok.isAuthorized === true) {
    return <SocialStatAmount>0</SocialStatAmount>;
  }

  if (stats.data.tikTok.isAuthorized === true) {
    return <SocialSpecialText>User authorised, but no stats to display.</SocialSpecialText>;
  }

  if (stats.data.tikTok.authorizeLink === undefined) {
    throw new Error('If user is not authorized there should be an authorize link!');
  }

  return (
    <>
      {isOwnProfile ? (
        <ContinueWithTikTok
          authLink={stats.data.tikTok.authorizeLink}
          artistId={artistId}
        />
      ) : (
        <SocialStatAmount>0</SocialStatAmount>
      )}
    </>
  );
};
