import React, { FC, useEffect } from 'react';
import { useResolveUsernameQuery } from '../../Redux/API/PublicAPI';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingSpinner from '../../Components/LoadingSpinner';
import ErrorPage from '../404';
import { Box, styled } from '@mui/material';

export const HatchLinkResolver: FC<{}> = ({}) => {
  const { username } = useParams();
  const keys = useResolveUsernameQuery({ username });

  const navigate = useNavigate();

  useEffect(() => {
    if (keys.data?.type) {
      navigate(`/hl/${keys.data.type}/${keys.data.id}`);
    }
  }, [keys.data]);

  return (
    <>
      {keys.isLoading === false && !keys.data?.type ? (
        <ErrorPage />
      ) : (
        <LoadingContainer>
          <LoadingSpinner />
        </LoadingContainer>
      )}
    </>
  );
};

const LoadingContainer = styled(Box, {
  name: 'LoadingContainer',
})({
  justifyContent: 'center',
  width: '100%',
  height: '80vh',
  display: 'flex',
  alignItems: 'center',
});
