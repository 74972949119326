import axios from 'axios';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

interface Props {
  userId: string;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setError: Dispatch<SetStateAction<string | null>>;
}

export const useGetTuneStats = ({ userId, setIsLoading, setError }: Props) => {
  const [tuneStats, setTuneStats] = useState<any>(null);

  const getTuneStats = useCallback(async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/artists/${userId}/performance-record?artistLimit=10`,
        {
          headers: {
            Accept: '*/*',
            'Accept-Encoding': 'gzip, deflate, br',
            Connection: 'keep-alive',
          },
        },
      );
      if (response.status === 200) {
        setIsLoading(false);
        setTuneStats(response.data);
      } else {
        setIsLoading(false);
        setError('Something went wrong');
        setTuneStats(null);
      }
    } catch {
      setError('Something went wrong');
      setIsLoading(false);
      setTuneStats(null);
    }
  }, [userId, setIsLoading, setError]);

  useEffect(() => {
    getTuneStats();
  }, [getTuneStats]);

  return tuneStats;
};
