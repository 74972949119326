import { styled, Typography } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

export const SectionTitle = styled(Typography, {
  name: 'SectionTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodyLargeBold,
});
