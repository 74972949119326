import React, { FC, useState } from 'react';
import { Box, styled } from '@mui/material';
import { SocialStatBox } from './SocialStatBox';
import { LeftSideComponentContainer } from '../Shared/SharedContainers';
import { LeftHandSideComponentTitle } from '../Shared/SharedElements';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { useAppDispatch } from '../../../hooks';
import { openModal } from '../../../Redux/UI/UISlice';
import { Button } from '../../../ui/buttons/Button';
import { SpotifyAuth } from '../../../Test/SpotifyAuth';
import { TikTokAuth } from '../../../Test/TikTokAuth';

interface SocialStatsProps {
  isOwnProfile: boolean;
  socials: any;
}

export const SocialStats: FC<SocialStatsProps> = ({ isOwnProfile, socials }) => {
  const { profileID } = useParams();
  const [isLoading] = useState(false);
  const dispatch = useAppDispatch();
  return (
    <LeftSideComponentContainer>
      <LeftHandSideComponentTitle>Social Stats</LeftHandSideComponentTitle>

      <SocialStatsBoxesContainer>
        {!socials?.instagram && !socials?.spotifyLink && !socials?.tikTokLink && isOwnProfile ? (
          <Button
            isPrimary={true}
            size="large"
            disabled={false}
            onClick={() => dispatch(openModal({ status: true, component: 'EditProfile' }))}
          >
            Fill in your lacking profile data
          </Button>
        ) : (
          <>
            {isLoading ? (
              <Box
                display="block"
                position="relative"
              >
                <LoadingSpinner />
              </Box>
            ) : (
              socials && (
                <>
                  {/* {socials?.instagram && (
                    <SocialStatBox
                      title="Instagram Followers"
                      social={<></>}
                    />
                  )} */}
                  {socials?.spotifyLink && (
                    <SocialStatBox
                      title="Spotify Popularity"
                      social={<SpotifyAuth artistId={profileID} />}
                    />
                  )}
                  {socials?.tikTokLink && (
                    <SocialStatBox
                      title="Tik-Tok Likes"
                      social={<TikTokAuth artistId={profileID} />}
                    />
                  )}
                </>
              )
            )}
          </>
        )}
      </SocialStatsBoxesContainer>
    </LeftSideComponentContainer>
  );
};

const SocialStatsBoxesContainer = styled(Box, {
  name: 'SocialStatsContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
