export const VIEWER_FAQ_DATA = [
  {
    name: 'faq1',
    title: `Do I need an account to purchase tickets?`,
    details: `Nope! You can purchase tickets without creating an account. You will need to provide your email address to receive your tickets.`,
  },
  {
    name: 'faq2',
    title: `How will I get the tickets I purchased?`,
    details: `They will be sent digitally to the email address you provide when you purchase the tickets. You may present your ticket at the venue door for entry to the show.`,
  },
  {
    name: 'faq3',
    title: `How many tickets can I (one person) purchase?`,
    details: `You can buy as many tickets as you'd like for yourself and your guests - as long as they're in stock!`,
  },
  {
    name: 'faq4',
    title: `Do I need to purchase specific seats for my group to sit together?`,
    details: `You do not need to purchase assigned seats unless the venue requires them. Many venues that use TuneHatch do not have assigned or designated group seating.`,
  },
  {
    name: 'faq5',
    title: `Can I buy tickets at the door?`,
    details: `Yes, if the venue offers tickets at the door, you can buy at the door.`,
  },
  {
    name: 'faq6',
    title: `My city, venue, or artist of interest isn’t listed on the TuneHatch website - how do I find shows in my area?`,
    details: `TuneHatch is a new service, and we are working to getting all your favorite stages and bands signed up - all around the country! Do you have a venue or artist in mind? Let us know who at info@tunehatch.com, and we'll reach out to get them signed up!`,
  },
  {
    name: 'faq7',
    title: `Are there fees added to my tickets when I buy them?`,
    details: `Our typical service fee is $1.00 to $3.00 per ticket (not including taxes and credit card processing fees).`,
  },
  {
    name: 'faq8',
    title: `Where do I go to have my tickets resent to me, inquire about refunds, or if I have specific questions about the ticketing process?`,
    details: `For help with tickets, refunds, or any other general questions, please email info@tunehatch.com and a service representative will contact you within 24 hours. To find out details about a specific show please visit tunehatch.com/shows and select your show or contact the venue directly.`,
  },
  {
    name: 'faq9',
    title: `Where do I find if this show is family-friendly or +21?`,
    details: `The age minimum is always printed at the top of the show page, right underneath the Show Name along with other important information, like the venue address.`,
  },
  {
    name: 'faq10',
    title: `There are members of my party that aren’t tech-savvy, can I print paper tickets?`,
    details: `Yes, tickets we email to you can be printed on paper and physically redeemed at the door.`,
  },
];
