import React, { FC, useCallback, useMemo, useState } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { UpcomingShow, useGetSortedUpcomingShowsWithExternal } from './useGetUpcomingShows';
import { isEmpty } from 'lodash';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { Type } from '../../../Helpers/shared/Models/Type';
import { Button } from '../../../ui/buttons/Button';
import { SmallShowCard } from '../ShowCard/SmallShowCard';
import { useGetAllVenuesQuery } from '../../../Redux/API/PublicAPI';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { ExternalShow } from '../../../Helpers/shared/Models/Show';
import { isExternalShow } from './helpers';
import { ExternalSmallShowCard } from '../ShowCard/ExternalSmallShowCard';

interface UpcomingShowsProps {
  userId: string;
  isMobile: boolean;
  type?: Type;
  isOwnProfile?: boolean;
  isAlternativeLabelView?: boolean;
}

export const SmallUpcomingShows: FC<UpcomingShowsProps> = ({
  userId,
  isMobile,
  type,
  isOwnProfile,
  isAlternativeLabelView,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const upcomingShows = useGetSortedUpcomingShowsWithExternal({ userId, page, isMobile, setIsLoading, type });
  const venues = useGetAllVenuesQuery();
  const actualPerPage = 3;
  const isNextPageEnabled = useMemo(() => {
    const totalViewed = page * actualPerPage;
    return upcomingShows.total > totalViewed;
  }, [isMobile, page, upcomingShows.total]);
  const viewableUpcomingShows = [...(upcomingShows.data ?? [])].slice(0, page * actualPerPage);

  const setNextPage = useCallback(() => {
    if (isNextPageEnabled) {
      setPage((page) => page + 1);
    }
  }, [isNextPageEnabled]);
  if (isEmpty(upcomingShows)) {
    return null;
  }

  if (isLoading || !venues.data) {
    return (
      <UpcomingShowsMainCont>
        <SmallUpcomingShowsTitle>Upcoming shows</SmallUpcomingShowsTitle>
        <Box
          display="block"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      </UpcomingShowsMainCont>
    );
  }
  if (isEmpty(upcomingShows.data)) {
    return null;
  }

  return (
    <UpcomingShowsMainCont>
      <UpcomingShowsHeadingContainer>
        <SmallUpcomingShowsTitle>Upcoming shows</SmallUpcomingShowsTitle>
      </UpcomingShowsHeadingContainer>
      {!isEmpty(upcomingShows.data) && !isEmpty(venues.data) ? (
        <>
          <UpcomingShowsListCont>
            {viewableUpcomingShows.map((upcomingShow) => {
              const externalShow: ExternalShow = isExternalShow(upcomingShow) ? upcomingShow : null;
              const checkedUpcomingShow: UpcomingShow = !isExternalShow(upcomingShow)
                ? (upcomingShow as unknown as UpcomingShow)
                : null;
              const venueData = venues.data?.[checkedUpcomingShow?.venue?.id];
              const venueDataToPass = { ...checkedUpcomingShow?.venue, location: venueData?.location };
              return (
                <>
                  {isExternalShow(upcomingShow) ? (
                    <ExternalSmallShowCard {...externalShow} />
                  ) : (
                    <SmallShowCard
                      key={upcomingShow.id}
                      id={upcomingShow.id}
                      date={upcomingShow.startTime}
                      venue={venueDataToPass}
                      showTitle={(upcomingShow as UpcomingShow).name}
                      customLabel={(upcomingShow as UpcomingShow).hatchLinkLabel}
                      alternativeLabel={isAlternativeLabelView}
                    />
                  )}
                </>
              );
            })}
          </UpcomingShowsListCont>
          <Box
            display="flex"
            justifyContent="center"
            gap="12px"
          >
            {isNextPageEnabled && (
              <Button
                isPrimary={false}
                onClick={setNextPage}
                disabled={!isNextPageEnabled}
                size="large"
              >
                Show more
              </Button>
            )}
          </Box>
        </>
      ) : (
        <Typography
          padding="60px 0"
          textAlign="center"
          width="100%"
        >
          No upcoming shows
        </Typography>
      )}
    </UpcomingShowsMainCont>
  );
};

const UpcomingShowsMainCont = styled(Box, {
  name: 'UpcomingShowsMainCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'center',
  width: '100%',
});

const UpcomingShowsHeadingContainer = styled(Box, {
  name: 'YouTubeHeadingContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const UpcomingShowsListCont = styled(Box, {
  name: 'UpcomingShowsListCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  width: '100%',
});

const SmallUpcomingShowsTitle = styled(Typography, {
  name: 'SmallUpcomingShowsTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodyLargeBold,
  textAlign: 'center',
  width: '100%',
});
