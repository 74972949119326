import React, { FC } from 'react';
import { useGetArtistSocialStatisticsQuery } from '../Redux/API/ArtistAPI';
import { AuthState } from './AuthState';
import { Button } from '../ui/buttons/Button';
import { styled, Typography } from '@mui/material';
import { SocialStatAmount } from '../Pages/ProfilePage/SocialStats/SocialStatBox';
import { useAppSelector } from '../hooks';
import { colors } from '../ui/shared/Colors';
import { typography } from '../ui/shared/TypographySharedElements';

function useAuthorizeSpotifyAccountLink(link: string, artistId: string) {
  const additionalParams = new URLSearchParams({
    state: AuthState.build({
      internalRedirectUrl: `${window.location.origin}/profile/a/${artistId}`,
      provider: 'spotify',
    }).toString(),
  }).toString();

  return `${link}&${additionalParams}`;
}

interface ContinueWithSpotifyProps {
  authLink: string;
  artistId: string;
}

const ContinueWithSpotify: FC<ContinueWithSpotifyProps> = ({ authLink, artistId }) => {
  const spotifyAuthLink = useAuthorizeSpotifyAccountLink(authLink, artistId);
  return (
    <Button
      isPrimary={true}
      size="small"
      disabled={false}
      onClick={() => window.open(`${spotifyAuthLink}`)}
    >
      Continue with Spotify
    </Button>
  );
};

interface SpotifyAuthProps {
  artistId: string;
}

export const SpotifyAuth: FC<SpotifyAuthProps> = ({ artistId }) => {
  const userIdFromCookie = useAppSelector((select) => select.user.data).displayUID;
  const isOwnProfile = userIdFromCookie === artistId;
  const stats = useGetArtistSocialStatisticsQuery({ artistId });

  if (stats.isLoading === null || stats.data === undefined) {
    return <SocialSpecialText>Fetching stats...</SocialSpecialText>;
  }

  if (stats.data.spotify.stats) {
    return <SocialStatAmount>{stats.data.spotify.stats.popularity}</SocialStatAmount>;
  }

  if (!isOwnProfile && stats.data.spotify.isAuthorized === true) {
    return <SocialStatAmount>0</SocialStatAmount>;
  }

  if (stats.data.spotify.isAuthorized === true) {
    return <SocialSpecialText>Please make sure that you've logged in with artist acccount.</SocialSpecialText>;
  }

  if (stats.data.spotify.authorizeLink === undefined) {
    throw new Error('If user is not authorized there should be an authorize link!');
  }

  return (
    <>
      {isOwnProfile ? (
        <ContinueWithSpotify
          authLink={stats.data.spotify.authorizeLink}
          artistId={artistId}
        />
      ) : (
        <SocialStatAmount>0</SocialStatAmount>
      )}
    </>
  );
};

export const SocialSpecialText = styled(Typography, {
  name: 'SocialSpecialText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray500,
  ...typography.bodyMediumRegular,
  textWrap: 'wrap',
});
