import { Box, styled } from '@mui/material';
import React, { FC } from 'react';
import { IconButton } from '../../../ui/buttons/IconButton';
import { breakpoints } from '../../../ui/shared/MediaQueries';

export const VenueReviewsButtonBox = ({ next, previous, ...rest }: any) => {
  const {
    carouselState: { currentSlide, totalItems, slidesToShow },
  } = rest;
  console.log(totalItems - 2, currentSlide);
  return (
    <VenueReviewsButtonBoxContainer>
      <IconButtonSpecial
        isPrimary={true}
        iconName="chevron_left"
        disabled={currentSlide === 0}
        size="small"
        onClick={previous}
      />
      <IconButtonSpecial
        isPrimary={true}
        iconName="chevron_right"
        disabled={currentSlide === totalItems - slidesToShow}
        size="small"
        onClick={next}
      />
    </VenueReviewsButtonBoxContainer>
  );
};

const VenueReviewsButtonBoxContainer = styled(Box, {
  name: 'VenueReviewsButtonBoxContainer',
})({
  display: 'flex',
  gap: '12px',
  position: 'absolute',
  top: '-76px',
  right: '50px',
  transform: 'translate(0, -50%)',
  [`@media ${breakpoints.md}`]: {
    top: '-50px',
    left: 0,
    right: 'unset',
    justifyContent: 'center',
  },
});

const IconButtonSpecial = styled(IconButton, {
  name: 'IconButtonSpecial',
})({
  width: '72px',
  height: '72px',
  i: {
    fontSize: '32px',
  },
});
