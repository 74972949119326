export const VENUE_FAQ_DATA = [
  {
    name: 'faq1',
    title: 'I signed up, how do I get started?',
    details: `A great place to get started is by going to your dashboard and creating your first show! Whether you already know who's performing or you need to book the gig, you can do all of that from your dashboard.`,
  },
  {
    name: 'faq2',
    title: 'How do fans buy tickets?',
    details: `Fans can buy presale tickets at TuneHatch.com/shows or via the show listing posted to your website or social media. You can easily auto-update your website though our show calendar embeds and your social media through HatchLink - no more updating multiple websites for every show.`,
  },
  {
    name: 'faq3',
    title: `Where can I monitor my ticket sales?`,
    details: `You and your performers can monitor your ticket sales in real-time by selecting the show on your dashboard and viewing the guestlist or payouts menu.`,
  },
  {
    name: 'faq4',
    title: `How is the money handled?`,
    details: `TuneHatch has partnered with one of the most secure and reliable payment-processing companies on the planet - Stripe. No more dealing with disappearing cash, having a long line at the ATM, or accidentally paying the wrong performers.`,
  },
  {
    name: 'faq5',
    title: `What does TuneHatch cost?`,
    details: `TuneHatch is free to artists, promoters, and venues.  A small service charge is added to each ticket at the time of purchase – usually $2.00 to $4.00 – which is far below industry standard.`,
  },
  {
    name: 'faq6',
    title: `I manage/lead multiple bands - Is TuneHatch right for me?`,
    details: `100% yes. This will be your new favorite tool.  You can book, schedule, track, promote, manage and pay multiple artists for one (or dozens) of shows.`,
  },
  {
    name: 'faq7',
    title: `I am a 3rd party organizer/promoter - Is TuneHatch right for me?`,
    details: `100% yes. This will be your new favorite tool.  You can book, schedule, track, promote, manage and pay multiple artists for one (or dozens) of shows.`,
  },
  {
    name: 'faq8',
    title: `I’m having issues with a performer who played here. Who should I contact?`,
    details: `Please contact the performer directly.`,
  },
  {
    name: 'faq9',
    title: `I’m having trouble with the website.  Is there an ACTUAL PERSON I can speak with?`,
    details: `We are always happy to help in any way we can! Please email info@tunehatch.com and a service representative will contact you within 24 hours. `,
  },
];
