import * as Axios from 'axios';
import { SERVER_URL } from '../../../Helpers/configConstants';
import { getCookie } from '../../../Helpers/HelperFunctions';
import { QueryObject, QueryParser } from '../Utils/QueryParser';
import { isBadRequestResponse, BadRequestError, HttpError } from './RequestErrors';

const client = Axios.default.create({
  baseURL: `${SERVER_URL}/api`,
  timeout: 20000,
  // Every request, regardless of status code resolves
  validateStatus: () => true,
});

export type PutRequestParams = Pick<RequestParams, 'endpoint' | 'data'>;
export async function putRequest(params: PutRequestParams) {
  return await apiRequest({
    ...params,
    method: 'PUT',
    responseType: 'json',
  });
}

export type GetRequestParams = Pick<RequestParams, 'endpoint' | 'query'>;
export async function getRequest(params: GetRequestParams) {
  return await apiRequest({
    ...params,
    method: 'GET',
    responseType: 'json',
  });
}

export type PostRequestParams = Pick<RequestParams, 'endpoint' | 'data' | 'timeout'>;
export async function postRequest(params: PostRequestParams) {
  return await apiRequest({
    ...params,
    method: 'POST',
    responseType: 'json',
  });
}

export type DeleteRequestParams = Pick<RequestParams, 'endpoint'>;
export async function deleteRequest(params: DeleteRequestParams) {
  return await apiRequest({
    ...params,
    method: 'DELETE',
    responseType: 'json',
  });
}

export type PatchRequestParams = Pick<RequestParams, 'endpoint' | 'data'>;
export async function patchRequest(params: PatchRequestParams) {
  return await apiRequest({
    ...params,
    method: 'PATCH',
    responseType: 'json',
  });
}

interface RequestParams {
  endpoint: string;
  method: Axios.Method;
  responseType: Axios.ResponseType;
  query?: QueryObject;
  data?: unknown;
  timeout?: number;
}

async function apiRequest(params: RequestParams): Promise<unknown> {
  const token = getCookie('SECRET_UID');
  const headers = {
    ...(token ? { Authorization: `Bearer ${token}` } : {}),
  };
  const queryParams = params.query ? QueryParser.stringify(params.query) : null;

  const response = await client.request({
    url: `${params.endpoint}${queryParams !== null ? `?${queryParams}` : ''}`,
    method: params.method,
    responseType: params.responseType,
    headers,
    data: params.data,
    timeout: params.timeout,
  });

  throwErrorIfFailure(response);

  return response.data;
}

function throwErrorIfFailure(response: Axios.AxiosResponse) {
  if (response.status >= 400) {
    const data = response.data;
    if (isBadRequestResponse(data)) {
      throw new BadRequestError({
        status: response.status,
        message: response.statusText,
        response: {
          message: data.message,
          code: data.code,
          metadata: data.metadata,
        },
      });
    } else {
      throw new HttpError({
        status: response.status,
        message: response.statusText,
        response: data,
      });
    }
  }
}
