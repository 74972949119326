import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

export interface TitleBarProps {
  formName: string;
  stepTitle: string;
  step: number;
  maxSteps: number;
}

export const TitleBar: FC<TitleBarProps> = ({ formName, stepTitle, step, maxSteps }) => {
  return (
    <TitleBarCont>
      <TitleBarInnerCont>
        <Box>
          <TitleBarStepText>{`${formName} • Step ${step} of ${maxSteps}`}</TitleBarStepText>
        </Box>
        <TitleBarStepTitle>{stepTitle}</TitleBarStepTitle>
      </TitleBarInnerCont>
    </TitleBarCont>
  );
};

const TitleBarCont = styled(Box, {
  name: 'TitleBarCont',
})({
  display: 'flex',
  justifyContent: 'center',
  width: '100%',
  padding: '20px',
  background: colors.SystemGray100,
});

const TitleBarInnerCont = styled(Box, {
  name: 'TitleBarInnerCont',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  maxWidth: '520px',
  width: '100%',
  alignItems: 'center',
});

const TitleBarStepText = styled(Typography, {
  name: 'TitleBarStepText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.bodySmallRegular,
});

const TitleBarStepTitle = styled(Typography, {
  name: 'TitleBarStepTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});
