import { styled } from '@mui/material/styles';
import React, { useCallback } from 'react';
import { Button } from './buttons/Button';
import { ButtonIcon } from './shared/ButtonIcon';
import { Select, TextField } from './inputs/TextField';
import { IconButton } from './buttons/IconButton';
import { typography } from './shared/TypographySharedElements';
import { Box, MenuItem } from '@mui/material';
import { colors } from './shared/Colors';
import { HatchLinksList } from './HatchLinks/HatchLinksList';

const MOCK_HATCH_LINKS = [
  { url: 'https://example.com', text: 'Some exemplary link 1' },
  { url: 'https://example.com', text: 'Some exemplary link 2' },
  { url: 'https://example.com', text: 'Some exemplary link 3' },
  { url: 'https://example.com', text: 'Some exemplary link 4' },
];
export const ComponentsList = () => {
  const renderAllTypographyTypes = useCallback(() => {
    const typographyEntries = Object.entries(typography);
    const elements = [];
    for (const [key, value] of typographyEntries) {
      const StyledEntry = styled('p')({ fontFamily: 'Satoshi-Variable', ...value });
      elements.push(<StyledEntry key={key}>{key}</StyledEntry>);
    }
    return elements;
  }, []);

  const renderAllColors = useCallback(() => {
    const colorEntries = Object.entries(colors);
    const elements = [];
    for (const [key, value] of colorEntries) {
      const StyledColorEntry = styled('div')({
        display: 'block',
        width: '40px',
        height: '40px',
        backgroundColor: value,
        borderRadius: '999px',
        boxShadow: '2px 2px 2px rgb(0,0,0,0.25)',
      });
      elements.push(
        <Box
          padding="24px"
          key={key}
        >
          <p>{key}</p>
          <StyledColorEntry />
        </Box>,
      );
    }
    return elements;
  }, []);

  return (
    <MainWrapper>
      <UISection>
        <SectionHeading>Inputs</SectionHeading>
        <UISectionInnerWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular input</ElementHeadline>
            <TextField
              name="some input 1"
              type="text"
              label={'label'}
              onChange={() => {}}
              placeholder="some placeholder"
              variant="standard"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular input with helper text</ElementHeadline>
            <TextField
              name="some input helper"
              type="text"
              label={'label'}
              onChange={() => {}}
              placeholder="some placeholder"
              helperText="some helper text"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular input with error</ElementHeadline>
            <TextField
              name="some input error"
              type="text"
              label={'label'}
              onChange={() => {}}
              placeholder="some placeholder"
              error={true}
              helperText="some error text"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular input disabled</ElementHeadline>
            <TextField
              name="some input disabled"
              type="text"
              label={'label'}
              onChange={() => {}}
              placeholder="some placeholder"
              helperText="some disabled text"
              disabled={true}
            />
          </SingleElementWrapper>
        </UISectionInnerWrapper>
        <UISectionInnerWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular select</ElementHeadline>
            <Select
              name="some select 1"
              type="text"
              label={'label'}
              select
              onChange={() => {}}
              placeholder="some placeholder"
              defaultValue={'none'}
            >
              <MenuItem
                value="none"
                disabled
              >
                some placeholder
              </MenuItem>
              <MenuItem value={1}>Option 1</MenuItem>
              <MenuItem value={2}>Option 2</MenuItem>
              <MenuItem value={3}>Option 3</MenuItem>
              <MenuItem value={4}>Option 4</MenuItem>
            </Select>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular select with helper text</ElementHeadline>
            <Select
              name="some select helper"
              type="text"
              label={'label'}
              select
              onChange={() => {}}
              defaultValue={'none'}
            >
              <MenuItem
                value="none"
                disabled
              >
                some placeholder
              </MenuItem>
              <MenuItem value={1}>Option 1</MenuItem>
              <MenuItem value={2}>Option 2</MenuItem>
              <MenuItem value={3}>Option 3</MenuItem>
              <MenuItem value={4}>Option 4</MenuItem>
            </Select>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular select with error</ElementHeadline>
            <Select
              name="some input error"
              type="text"
              label={'label'}
              onChange={() => {}}
              placeholder="some placeholder"
              select
              error={true}
              defaultValue={'none'}
            >
              <MenuItem
                value="none"
                disabled
              >
                some placeholder
              </MenuItem>
              <MenuItem value={1}>Option 1</MenuItem>
              <MenuItem value={2}>Option 2</MenuItem>
              <MenuItem value={3}>Option 3</MenuItem>
              <MenuItem value={4}>Option 4</MenuItem>
            </Select>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Regular select disabled</ElementHeadline>
            <Select
              name="some input disabled"
              type="text"
              label={'label'}
              onChange={() => {}}
              placeholder="some placeholder"
              select
              disabled={true}
              defaultValue={'none'}
            >
              <MenuItem value="none">some placeholder</MenuItem>
              <MenuItem value={1}>Option 1</MenuItem>
              <MenuItem value={2}>Option 2</MenuItem>
              <MenuItem value={3}>Option 3</MenuItem>
              <MenuItem value={4}>Option 4</MenuItem>
            </Select>
          </SingleElementWrapper>
        </UISectionInnerWrapper>
      </UISection>
      <UISection>
        <SectionHeading>Buttons large</SectionHeading>
        <UISectionInnerWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Large primary button</ElementHeadline>
            <Button
              name="large button 1"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="large"
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Large primary button with icon</ElementHeadline>
            <Button
              name="large button 2"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="large"
              endIcon={
                <ButtonIcon
                  size="large"
                  disabled={false}
                  isPrimary={true}
                  iconName="chevron_right"
                />
              }
            >
              {' '}
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Large secondary button</ElementHeadline>
            <Button
              name="large button 3"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="large"
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Large secondary button with icon</ElementHeadline>
            <Button
              name="large button 4"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="large"
              endIcon={
                <ButtonIcon
                  size="large"
                  disabled={false}
                  isPrimary={false}
                  iconName="chevron_right"
                />
              }
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled large primary button</ElementHeadline>
            <Button
              name="large button 5"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="large"
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled large primary button with icon</ElementHeadline>
            <Button
              name="large button 6"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="large"
              endIcon={
                <ButtonIcon
                  size="large"
                  disabled={true}
                  isPrimary={true}
                  iconName="chevron_right"
                />
              }
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled large secondary button</ElementHeadline>
            <Button
              name="large button 7"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="large"
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled large secondary button with icon</ElementHeadline>
            <Button
              name="large button 8"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="large"
              endIcon={
                <ButtonIcon
                  size="large"
                  disabled={true}
                  isPrimary={false}
                  iconName="chevron_right"
                />
              }
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
        </UISectionInnerWrapper>
      </UISection>
      <UISection>
        <SectionHeading>Buttons small</SectionHeading>
        <UISectionInnerWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Small primary button</ElementHeadline>
            <Button
              name="small button 1"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="small"
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Small primary button with icon</ElementHeadline>
            <Button
              name="small button 2"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="small"
              endIcon={
                <ButtonIcon
                  size="small"
                  disabled={false}
                  isPrimary={true}
                  iconName="chevron_right"
                />
              }
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Small secondary button</ElementHeadline>
            <Button
              name="small button 3"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="small"
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Small secondary button with icon</ElementHeadline>
            <Button
              name="small button 4"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="small"
              endIcon={
                <ButtonIcon
                  size="small"
                  disabled={false}
                  isPrimary={false}
                  iconName="chevron_right"
                />
              }
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled small primary button</ElementHeadline>
            <Button
              name="small button 5"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="small"
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled small primary button with icon</ElementHeadline>
            <Button
              name="small button 6"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="small"
              endIcon={
                <ButtonIcon
                  size="small"
                  disabled={true}
                  isPrimary={true}
                  iconName="chevron_right"
                />
              }
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled small secondary button</ElementHeadline>
            <Button
              name="small button 7"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="small"
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled small secondary button with icon</ElementHeadline>
            <Button
              name="small button 8"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="small"
              endIcon={
                <ButtonIcon
                  size="small"
                  disabled={true}
                  isPrimary={false}
                  iconName="chevron_right"
                />
              }
              disabled={true}
            >
              Button
            </Button>
          </SingleElementWrapper>
        </UISectionInnerWrapper>
      </UISection>
      <UISection>
        <SectionHeading>Icon buttons large</SectionHeading>
        <UISectionInnerWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Large primary icon button</ElementHeadline>
            <IconButton
              name="large icon button 1"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="large"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Large primary icon button with other icon</ElementHeadline>
            <IconButton
              name="large icon button 1.1"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="large"
              iconName="add"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Large secondary icon button</ElementHeadline>
            <IconButton
              name="large icon button 2"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="large"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled large primary icon button</ElementHeadline>
            <IconButton
              name="large icon button 3"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="large"
              disabled={true}
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled large secondary icon button</ElementHeadline>
            <IconButton
              name="large icon button 4"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="large"
              disabled={true}
            />
          </SingleElementWrapper>
        </UISectionInnerWrapper>
      </UISection>
      <UISection>
        <SectionHeading>Icon buttons small</SectionHeading>
        <UISectionInnerWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Small primary icon button</ElementHeadline>
            <IconButton
              name="small icon button 1"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="small"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Small primary icon button with other icon</ElementHeadline>
            <IconButton
              name="small icon button 1.1"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="small"
              iconName="add"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Small secondary icon button</ElementHeadline>
            <IconButton
              name="small icon button 2"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="small"
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled small primary icon button</ElementHeadline>
            <IconButton
              name="small icon button 3"
              onClick={() => {}}
              type="button"
              isPrimary={true}
              size="small"
              disabled={true}
            />
          </SingleElementWrapper>
          <SingleElementWrapper>
            <ElementHeadline>Disabled small secondary icon button</ElementHeadline>
            <IconButton
              name="small icon button 4"
              onClick={() => {}}
              type="button"
              isPrimary={false}
              size="small"
              disabled={true}
            />
          </SingleElementWrapper>
        </UISectionInnerWrapper>
      </UISection>
      <UISection>
        <SectionHeading>Typography types</SectionHeading>
        {renderAllTypographyTypes()}
      </UISection>
      <UISection>
        <SectionHeading>Colors</SectionHeading>
        <UISectionInnerWrapper>{renderAllColors()}</UISectionInnerWrapper>
      </UISection>
      <UISection sx={{ backgroundColor: colors.SystemGray100 }}>
        <SectionHeading>Hatch links</SectionHeading>
        <HatchLinksList
          links={MOCK_HATCH_LINKS}
          maxRenderNumber={3}
        />
      </UISection>
    </MainWrapper>
  );
};

const UISection = styled('section', {
  name: 'UISection',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
});
const UISectionInnerWrapper = styled('div', {
  name: 'UISectionInnerWrapper',
})({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '24px',
  padding: '24px',
});

const SectionHeading = styled('p', {
  name: 'SectionHeading',
})({ ...typography.headlineMedium, color: 'orange' });

const ElementHeadline = styled('span', {
  name: 'ElementHeadline',
})(typography.headlineSmall);

const MainWrapper = styled('div', {
  name: 'MainWrapper',
})({
  padding: '24px',
});

const SingleElementWrapper = styled('div', {
  name: 'SingleElementWrapper',
  shouldForwardProp: (prop) => prop !== 'grow',
})<{ grow?: boolean }>(({ grow }) => ({
  display: 'inline-flex',
  flexDirection: 'column',
  width: 'calc(25% - 20px)',
}));
