import { Box, Typography, styled } from '@mui/material';
import React, { Dispatch, FC, SetStateAction, useCallback, MouseEvent, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import Img from '../../Components/Images/Img';
import { colors } from '../shared/Colors';
import { Button } from '../buttons/Button';
import { isEmpty } from 'lodash';
import { typography } from '../shared/TypographySharedElements';
import { ButtonIcon } from '../shared/ButtonIcon';

interface DropZoneProps {
  selectedFiles: File[];
  setSelectedFiles: Dispatch<SetStateAction<File[]>>;
  maxFiles: number;
}

export const DropZone: FC<DropZoneProps> = (props: DropZoneProps) => {
  const { setSelectedFiles, selectedFiles } = props;
  const selectedFilesPreview = useMemo(() => {
    return selectedFiles.map((file) => window.URL.createObjectURL(file));
  }, [selectedFiles]);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      setSelectedFiles((prevFiles) => {
        const selectedFiles = [...prevFiles, ...acceptedFiles];
        if (selectedFiles.length > props.maxFiles) {
          return selectedFiles.slice(0, 6);
        }
        return selectedFiles;
      });
    },
    [setSelectedFiles],
  );

  const clearImages = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      event.stopPropagation();
      setSelectedFiles([]);
    },
    [setSelectedFiles],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.png', '.gif', '.jpeg', '.jpg'],
    },
    maxFiles: props.maxFiles,
  });
  const selectedMaxFiles = props.maxFiles === selectedFiles.length;
  return (
    <DropZoneContainer
      selectedFilesPreview={selectedFilesPreview}
      {...getRootProps()}
    >
      <input
        {...getInputProps()}
        disabled={selectedMaxFiles}
      />
      {!isEmpty(selectedFilesPreview) ? (
        <>
          <ImagesContainer>
            {selectedFilesPreview.map((selectedFilePreview) => (
              <ImageStyled
                key={selectedFilePreview}
                src={selectedFilePreview}
              />
            ))}
          </ImagesContainer>
          <ButtonContainer>
            <Button
              isPrimary={false}
              disabled={selectedMaxFiles}
              size="small"
              startIcon={
                <ButtonIcon
                  disabled={selectedMaxFiles}
                  isPrimary={false}
                  size="small"
                  iconName="file_upload"
                />
              }
            >
              Upload more
            </Button>
            <Button
              isPrimary={false}
              disabled={false}
              size="small"
              onClick={clearImages}
              startIcon={
                <ButtonIcon
                  disabled={false}
                  isPrimary={false}
                  size="small"
                  iconName="close"
                />
              }
            >
              Clear all
            </Button>
          </ButtonContainer>
        </>
      ) : (
        <Box
          display="flex"
          width="100%"
          justifyContent="center"
        >
          <DropZoneText>
            Drag and drop your files here or <span>select</span> from your computer
          </DropZoneText>
        </Box>
      )}
    </DropZoneContainer>
  );
};

const DropZoneContainer = styled(Box, {
  name: 'DropZoneContainer',
  shouldForwardProp: (prop) => prop !== 'selectedFilesPreview',
})<{ selectedFilesPreview: string[] }>(({ selectedFilesPreview }) => ({
  display: 'flex',
  flexDirection: isEmpty(selectedFilesPreview) ? 'row' : 'column',
  position: 'relative',
  justifyContent: isEmpty(selectedFilesPreview) ? 'center' : 'flex-start',
  alignItems: isEmpty(selectedFilesPreview) ? 'center' : 'flex-start',
  width: '100%',
  maxWidth: isEmpty(selectedFilesPreview) ? '440px' : '520px',
  minHeight: '50vh',
  border: isEmpty(selectedFilesPreview) ? `1px dashed ${colors.SystemGray200}` : 'none',
  borderRadius: '12px',
  gap: '32px',
}));

const ImagesContainer = styled(Box, {
  name: 'ImagesContainer',
})({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  margin: '0 auto',
  gap: '16px',
});

const DropZoneText = styled(Typography, {
  name: 'DropZoneText',
})({
  width: '50%',
  margin: '0 auto',
  color: colors.SystemGray900,
  fontFamily: 'Satoshi-Variable',
  ...typography.bodySmallRegular,
  span: {
    textDecoration: 'underline',
  },
});
const ButtonContainer = styled(Box, {
  name: 'ButtonContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  width: '100%',
  gap: '12px',
});

export const ImageStyled = styled(Img, {
  name: 'ImageStyled',
})({
  width: '118px',
  height: '76px',
});
