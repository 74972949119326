import { Box, styled, useMediaQuery } from '@mui/material';
import { FastAverageColor } from 'fast-average-color';
import Lo, { isEmpty } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../Components/LoadingSpinner';
import { GENRE_MAP } from '../../Helpers/configConstants';
import { getDisplayName, parseProfileData } from '../../Helpers/HelperFunctions';
import { Type } from '../../Helpers/shared/Models/Type';
import { useAppSelector, useAppDispatch } from '../../hooks';
import AvatarImage from '../../Images/Avatar.webp';
import { useGetAllArtistsQuery, useGetAllShowrunnerGroupsQuery, useGetAllVenuesQuery } from '../../Redux/API/PublicAPI';
import { openModal } from '../../Redux/UI/UISlice';
import { Button } from '../../ui/buttons/Button';
import { HatchLinksList } from '../../ui/HatchLinks/HatchLinksList';
import { breakpoints } from '../../ui/shared/MediaQueries';
import { ShareEPKLink } from '../ShareEPKLink/ShareEPKLink';
import { ShareHatchLinks } from '../ShareHatchLinks/ShareHatchLinks';
import { About } from './About/About';
import { Badges } from './Badges/Badges';
import { ProfileHeadline } from './ProfileHeadline/ProfileHeadline';
import { Spotify } from './Spotify/Spotify';
import { TikTok } from './TikTok/TikTok';
import { TuneStats } from './TuneStats/TuneStats';
import { UpcomingShows } from './UpcomingShows/UpcomingShows';
import { YouTube } from './YouTube/YouTube';
import { SocialStats } from './SocialStats/SocialStats';
import { Instagram } from './Instagram/Instagram';
import { SmallUpcomingShows } from './UpcomingShows/SmallUpcomingShows';

function resolveReadableType(type: string): Type {
  if (type === 'a' || type === 'artist') {
    return 'artist';
  } else if (type === 'v' || type === 'venue') {
    return 'venue';
  } else if (type === 'g' || type === 'showrunner') {
    return 'showrunner';
  } else if (type === 'u' || type === 'user') {
    return 'artist';
  } else {
    throw new Error('Unsupported user type!');
  }
}

function useCurrentData(type: Type, entityId: string): { data: any; isLoading: boolean; type: Type } {
  const venues = useGetAllVenuesQuery();
  const artists = useGetAllArtistsQuery();
  const showrunners = useGetAllShowrunnerGroupsQuery();

  const artistData = Lo.get(artists.data, entityId);
  if (type === 'artist' || (type === 'user' && artistData !== undefined)) {
    return { data: artistData, isLoading: artists.isLoading, type: 'artist' };
  }
  if (type === 'venue') {
    return { data: Lo.get(venues.data, entityId), isLoading: venues.isLoading, type };
  }
  if (type === 'showrunner') {
    return { data: Lo.get(showrunners.data, entityId), isLoading: showrunners.isLoading, type };
  }
  return { data: null, isLoading: false, type };
}

interface ProfilePageProps {
  userType?: string;
  userID?: string;
  forceMobile?: boolean;
}

export const ProfilePage: FC<ProfilePageProps> = ({ userType, userID, forceMobile }) => {
  const user = useAppSelector((select) => select.user.data);
  const possibleUserIDs: string[] = [user.displayUID, ...Object.keys(user.sr_groups), ...user.venues] || [];
  const [shareHatchLinksOpen, setShareHatchLinksOpen] = useState(false);
  const [shareEPKLinkOpen, setShareEPKLinkOpen] = useState(false);

  const [topBgColor, setTopBgColor] = useState('#FDE7C6');
  const { profileID } = useParams();
  const { type } = useParams<string>();
  userID = userID || profileID;
  userType = userType || type;
  console.log(userType);
  const readableType = useMemo(() => resolveReadableType(userType), [userType]);

  const isOwnProfile = possibleUserIDs.includes(profileID);
  const venues = useGetAllVenuesQuery();
  const isMobileBreakpoint = useMediaQuery(breakpoints.md);
  const isMobile = forceMobile || isMobileBreakpoint;
  const dispatch = useAppDispatch();

  const currentData = useCurrentData(readableType, userID);
  const getColorsFromAvatar = useCallback(async () => {
    if (currentData.data?.avatar) {
      const fac = new FastAverageColor();
      try {
        const color = await fac.getColorAsync(currentData.data.avatar ?? AvatarImage, { algorithm: 'dominant' });
        setTopBgColor(color.hex);
        fac.destroy();
      } catch (err) {
        console.log(err);
        fac.destroy();
      }
    }
  }, [currentData.data?.avatar]);

  useEffect(() => {
    getColorsFromAvatar();
  }, [getColorsFromAvatar]);
  if (currentData.isLoading === true) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <LoadingSpinner />
      </Box>
    );
  }
  if (currentData.data === null && isOwnProfile === true) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
      >
        <Button
          isPrimary={false}
          size="large"
          disabled={false}
          onClick={() =>
            dispatch(
              openModal({
                status: true,
                component: readableType === 'artist' || readableType === 'user' ? 'EditProfile' : 'EditArtistProfile',
              }),
            )
          }
        >
          Edit Profile
        </Button>
      </Box>
    );
  }
  if ((currentData.data === undefined || currentData.data === null) && currentData.isLoading === false) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        position="relative"
      >
        {/* The user does not exist. If you recently created your TuneHatch profile, please refresh the page. */}
      </Box>
    );
  }
  if (isMobile) {
    return (
      <ProfilePageMainContainer
        topBgColor={topBgColor}
        isMobile={isMobile}
      >
        <ProfileHeadline
          avatarSrc={currentData.data.avatar}
          profileID={userID}
          profileType={currentData.type}
          stripeEnabled={currentData.data.type?.artist?.stripeEnabled}
          userName={getDisplayName(readableType, currentData.data)}
          isOwnProfile={isOwnProfile}
          setShareHatchLinksOpen={setShareHatchLinksOpen}
          setShareEPKLinkOpen={setShareEPKLinkOpen}
          isMobile={isMobile}
        />
        <ProfilePageContentBlock>
          <ProfilePageBlockLeft>
            <SmallUpcomingShows
              userId={userID}
              isMobile={isMobile}
              type={currentData.type}
            />
            <About
              about={currentData.data.about ?? ''}
              genre={currentData.data.type?.artist?.subgenre || GENRE_MAP[currentData.data.type?.artist?.genre] || ''}
              location={currentData.data.type?.artist?.primaryCity ?? ''}
              isOwnProfile={isOwnProfile}
            />
            {currentData.data.type?.artist?.socials?.spotifyLink && (
              <Spotify spotifyLink={currentData.data.type?.artist?.socials?.spotifyLink} />
            )}
            {currentData.data.type?.artist?.socials?.youtubeLink && (
              <YouTube youTubeLink={currentData.data.type?.artist?.socials?.youtubeLink} />
            )}
            {currentData.data.type?.artist?.socials?.tikTokLink && (
              <TikTok tikTokLink={currentData.data.type?.artist?.socials?.tikTokLink} />
            )}
            {currentData.type === 'artist' && venues.data && (
              <Badges
                badges={parseProfileData('artist', currentData.data, venues.data).badges}
                isOwnProfile={isOwnProfile}
              />
            )}
            {currentData.type === 'artist' && (
              <SocialStats
                isOwnProfile={isOwnProfile}
                socials={currentData.data?.type?.artist?.socials}
              />
            )}
            {currentData.type === 'artist' && <TuneStats userID={userID} />}
            <HatchLinksList
              openInNewTab
              links={currentData.data.externalLinks || []}
              maxRenderNumber={4}
              userId={userID}
              userType={readableType}
            />
          </ProfilePageBlockLeft>
          <ShareHatchLinks
            link={
              !currentData.data.username
                ? `${process.env.REACT_APP_PUBLIC_URL}/hl/${readableType === 'user' ? 'artist' : readableType}/${userID}`
                : `https://tunehatch.link/${currentData.data.username}`
            }
            username={currentData.data.username}
            open={shareHatchLinksOpen}
            setOpen={setShareHatchLinksOpen}
          />
          <ShareEPKLink
            link={`${process.env.REACT_APP_PUBLIC_URL}/profile/${userType === 'user' ? 'artist' : userType}/${userID}`}
            open={shareEPKLinkOpen}
            setOpen={setShareEPKLinkOpen}
          />
        </ProfilePageContentBlock>
      </ProfilePageMainContainer>
    );
  }

  return (
    <ProfilePageMainContainer
      topBgColor={topBgColor}
      isMobile={isMobile}
    >
      <ProfileHeadline
        avatarSrc={currentData.data.avatar}
        profileType={currentData.type}
        profileID={userID}
        stripeEnabled={currentData.data.type?.artist?.stripeEnabled}
        userName={getDisplayName(currentData.type, currentData.data)}
        isOwnProfile={isOwnProfile}
        setShareEPKLinkOpen={setShareEPKLinkOpen}
        setShareHatchLinksOpen={setShareHatchLinksOpen}
      />
      <ProfilePageContentBlock>
        <ProfilePageBlockLeft>
          <About
            about={currentData.data.about ?? ''}
            genre={currentData.data?.type?.artist?.subgenre || GENRE_MAP[currentData.data.type?.artist?.genre] || ''}
            location={currentData.data.type?.artist?.primaryCity ?? ''}
            isOwnProfile={isOwnProfile}
          />
          {currentData.type === 'artist' && (
            <SocialStats
              isOwnProfile={isOwnProfile}
              socials={currentData.data?.type?.artist?.socials}
            />
          )}
          {currentData.type === 'artist' && venues.data && (
            <Badges
              badges={parseProfileData('artist', currentData.data, venues.data).badges}
              isOwnProfile={isOwnProfile}
            />
          )}
          {currentData.type === 'artist' && <TuneStats userID={userID} />}
        </ProfilePageBlockLeft>
        <ProfilePageBlockRight>
          <UpcomingShows
            userId={userID}
            isMobile={isMobile}
            type={currentData.type}
            isOwnProfile={isOwnProfile}
          />
          {currentData.data.type?.artist?.socials?.spotifyLink && (
            <Spotify spotifyLink={currentData.data.type?.artist?.socials?.spotifyLink} />
          )}
          {currentData.data.type?.artist?.socials?.youtubeLink && (
            <YouTube youTubeLink={currentData.data.type?.artist?.socials?.youtubeLink} />
          )}
          {currentData.data.type?.artist?.socials?.instagram && (
            <Instagram instagramUsername={currentData.data.type?.artist?.socials?.instagram} />
          )}
          {currentData.data.type?.artist?.socials?.tikTokLink && (
            <TikTok tikTokLink={currentData.data.type?.artist?.socials?.tikTokLink} />
          )}
          <HatchLinksList
            openInNewTab
            links={currentData.data.externalLinks || []}
            maxRenderNumber={4}
            userId={userID}
            userType={readableType}
          />
        </ProfilePageBlockRight>
      </ProfilePageContentBlock>
      <ShareHatchLinks
        link={
          !currentData.data.username
            ? `${process.env.REACT_APP_PUBLIC_URL}/hl/${readableType === 'user' ? 'artist' : readableType}/${userID}`
            : `https://tunehatch.link/${currentData.data.username}`
        }
        username={currentData.data.username}
        open={shareHatchLinksOpen}
        setOpen={setShareHatchLinksOpen}
      />
      <ShareEPKLink
        link={`${process.env.REACT_APP_PUBLIC_URL}/profile/${readableType === 'user' ? 'artist' : readableType}/${userID}`}
        open={shareEPKLinkOpen}
        setOpen={setShareEPKLinkOpen}
      />
    </ProfilePageMainContainer>
  );
};

const ProfilePageMainContainer = styled(Box, {
  name: 'ProfilePageMainContainer',
  shouldForwardProp: (prop) => prop !== 'topBgColor' && prop !== 'isMobile',
})<{ topBgColor: string; isMobile?: boolean | null }>(({ topBgColor, isMobile }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: isMobile ? '40px' : '64px',
  alignItems: 'center',
  justifyContent: 'center',
  background: `linear-gradient(${topBgColor}, #F8F7F5)`,
  padding: '80px 0',
}));

const ProfilePageContentBlock = styled(Box, {
  name: 'ProfilePageContentBlock',
})({
  display: 'flex',
  gap: '16px',
  maxWidth: '1400px',
  width: '100%',
  justifyContent: 'center',
  marginBottom: '-48px',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'column-reverse',
    alignItems: 'center',
  },
});

const ProfilePageBlockLeft = styled(Box, {
  name: 'BlockLeft',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '400px',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    padding: '0 20px',
  },
});
const ProfilePageBlockRight = styled(Box, {
  name: 'BlockRight',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
  maxWidth: '784px',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    maxWidth: '400px',
  },
});
