import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { breakpoints } from '../../../ui/shared/MediaQueries';

interface ShowcaseBoxProps {
  text: string;
  imageUrl: string;
  darkMode?: boolean;
}

export const ShowcaseBox: FC<ShowcaseBoxProps> = ({ text, imageUrl, darkMode }) => {
  return (
    <ShowcaseBoxContainer darkMode={darkMode}>
      <ShowcaseBoxImage src={imageUrl} />
      <ShowcaseBoxText darkMode={darkMode}>{text}</ShowcaseBoxText>
    </ShowcaseBoxContainer>
  );
};

const ShowcaseBoxContainer = styled(Box, {
  name: 'ShowcaseBoxContainer',
  shouldForwardProp: (props) => props !== 'darkMode',
})<{ darkMode: boolean | null }>(({ darkMode }) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '20px',
  gap: '12px',
  backgroundColor: darkMode ? colors.SystemGray700 : colors.SystemWhite,
  maxWidth: '550px',
  width: '100%',
  borderRadius: '12px',
}));

const ShowcaseBoxImage = styled(Box, {
  name: 'ShowcaseBoxImage',
  shouldForwardProp: (prop) => prop !== 'src',
})<{ src: string }>(({ src }) => ({
  display: 'block',
  maxWidth: '486px',
  height: '0',
  paddingBottom: 'calc((300/486) * 100%)',
  backgroundImage: `url(${src})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  backgroundColor: colors.SystemBlack,
}));

const ShowcaseBoxText = styled(Typography, {
  name: 'ShowcaseBoxText',
  shouldForwardProp: (props) => props !== 'darkMode',
})<{ darkMode: boolean | null }>(({ darkMode }) => ({
  fontFamily: 'Satoshi-Variable',
  color: darkMode ? colors.SystemWhite : colors.SystemBlack,
  padding: '40px',
  ...typography.headlineSmall,
  [`@media ${breakpoints.md}`]: {
    ...typography.bodyLargeRegular,
    padding: '20px',
  },
}));
