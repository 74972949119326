import React, { FC, useState } from 'react';
import { LeftHandSideComponentTitle } from '../Shared/SharedElements';
import { LeftSideComponentContainer } from '../Shared/SharedContainers';
import { Box, Typography, styled } from '@mui/material';
import { TuneStatBox } from './TuneStatBox';
import { RecentlyPlayedWith } from './RecentlyPlayedWith';
import { useParams } from 'react-router-dom';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { useGetTuneStats } from './useGetTuneStats';

export interface SingleArtistPlayedWith {
  id: string;
  avatarWebP?: string | null;
  firstName: string;
  lastName: string;
  stageName?: string;
}

interface TuneStatsProps {
  userID?: string;
}

export const TuneStats: FC<TuneStatsProps> = ({ userID }) => {
  const { profileID } = useParams();
  userID = String(userID || profileID);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const tuneStats = useGetTuneStats({ userId: userID, setIsLoading, setError });

  return (
    <LeftSideComponentContainer>
      <LeftHandSideComponentTitle>Tune Stats</LeftHandSideComponentTitle>
      {error && <Typography>{error}</Typography>}
      {isLoading && !error && (
        <Box
          display="block"
          position="relative"
        >
          <LoadingSpinner />
        </Box>
      )}
      {!error && !isLoading && tuneStats && (
        <>
          <TuneStatsBoxesContainer>
            <TuneStatBox
              title="Shows Played"
              tooltipText="Number of shows played through TuneHatch"
              amount={tuneStats?.showsPerformedAtQty}
            />
            <TuneStatBox
              title="Venues Played"
              tooltipText="Number of venues played at through TuneHatch"
              amount={tuneStats?.venuesPerformedInQty}
            />
            <TuneStatBox
              title="Artists Played With"
              tooltipText="Number of artists played with through TuneHatch"
              amount={tuneStats?.artistsPerformedWithQty}
            />
          </TuneStatsBoxesContainer>
          <RecentlyPlayedWith artistsList={tuneStats?.artistsRecentlyPerformedWith} />
        </>
      )}
    </LeftSideComponentContainer>
  );
};

const TuneStatsBoxesContainer = styled(Box, {
  name: 'TuneStatsContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
});
