import { Button, styled } from '@mui/material';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DataCy } from '../../Helpers/DataCy';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { UserTypeEnum } from './UserTypeEnum';
import { ButtonIcon } from '../../ui/shared/ButtonIcon';
import { useAppDispatch } from '../../hooks';
import { setFullscreen } from '../../Redux/UI/UISlice';
import {
  FullPageContainer,
  LeftHandSideContainerInner,
  RightHandSideInnerContainer,
  RightSideTextContainer,
  SideContainer,
  SideContainerInner,
} from './Shared/Containers';
import {
  RightHandSideFootText,
  RightHandSideTitle,
  SubRegistrationLink,
  SubRegistrationText,
  UserSelectionHeadline,
} from './Shared/RegistrationTypographies';
import { IconBig } from './Shared/RegistrationIcons';
import ArtistRegistration from '../../Images/Registration/ArtistRegistration.svg';
import VenueRegistration from '../../Images/Registration/VenueRegistration.svg';
import ShowrunnerRegistration from '../../Images/Registration/PromoterRegistration.svg';

export const USER_SELECTION_TEXTS: Array<string> = [];

export const VENUE_SELECTION_TEXTS = [
  'Connect with fans, showrunners and artists',
  'Organize shows and sell tickets via TuneHatch',
  'Pay out artists with one-click',
  'Let us handle all tax questions',
];

export const UserTypeSelection = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();
  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, []);

  return (
    <FullPageContainer>
      <SideContainer isLeft={true}>
        <SideContainerInner>
          <UserSelectionHeadline>Create an account</UserSelectionHeadline>
          <LeftHandSideContainerInner>
            <UserTypeSelectionButton
              onClick={() => navigate(`/register/${UserTypeEnum.ARTIST}`)}
              data-cy={DataCy.button('imArtist')}
            >
              <span>I'm an artist</span>
              <ButtonIcon
                size="large"
                disabled={false}
                isPrimary={false}
              />
            </UserTypeSelectionButton>
            <UserTypeSelectionButton
              onClick={() => navigate(`/register/${UserTypeEnum.PROMOTER}`)}
              data-cy={DataCy.button('imPromoter')}
            >
              <span>I'm a promoter</span>
              <ButtonIcon
                size="large"
                disabled={false}
                isPrimary={false}
              />
            </UserTypeSelectionButton>
          </LeftHandSideContainerInner>
          <SubRegistrationText>
            Already have an account? <SubRegistrationLink to={`/login`}>Log in</SubRegistrationLink>
          </SubRegistrationText>
        </SideContainerInner>
      </SideContainer>
      <SideContainer>
        <RightHandSideTitle>TuneHatch</RightHandSideTitle>
        <RightHandSideInnerContainer>
          {generateRightHandSideTexts(USER_SELECTION_TEXTS)}
          {generateRightHandSideImages(UserTypeEnum.ARTIST)}
        </RightHandSideInnerContainer>
        <RightHandSideFootText>© 2024 TuneHatch Inc.</RightHandSideFootText>
      </SideContainer>
    </FullPageContainer>
  );
};

export const generateRightHandSideImages = (userType: string) => {
  if (userType === UserTypeEnum.ARTIST) {
    return (
      <img
        style={{ padding: 100 }}
        src={ArtistRegistration}
      />
    );
  }
  if (userType === UserTypeEnum.PROMOTER) {
    return (
      <img
        style={{ padding: 100 }}
        src={ShowrunnerRegistration}
      />
    );
  }
  if (userType === UserTypeEnum.VENUE) {
    return (
      <img
        style={{ padding: 100 }}
        src={VenueRegistration}
      />
    );
  }
};

export const generateRightHandSideTexts = (textsArray: string[]) => {
  const items = textsArray.map((text) => {
    return (
      <RightSideTextContainer key={text}>
        <IconBig className="material-symbols-outlined">task_alt</IconBig>
        {text}
      </RightSideTextContainer>
    );
  });
  return items;
};

const UserTypeSelectionButton = styled(Button, {
  name: 'UserTypeSelectionButton',
})({
  fontFamily: 'Satoshi-Variable',
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  padding: '48px 24px',
  border: `1px solid ${colors.SystemGray200}`,
  borderRadius: '12px',
  color: colors.SystemGray900,
  textTransform: 'none',
  ...typography.headlineSmall,
});
