import Lo from 'lodash';
import z from 'zod';

export namespace ArtistFormData {
  export const step1Schema = z.object({
    firstName: z.string().min(1, 'You must enter your first name'),
    lastName: z.string().min(1, 'You must enter your last name'),
    stageName: z.string().nullable(),
    genre: z.string().refine((value) => Lo.isEmpty(value) == false && value !== 'none', 'You must choose a genre'),
    subGenre: z.string().nullable(),
    recordLabel: z.discriminatedUnion('signed', [
      z.object({
        signed: z.literal(false),
        label: z.null(),
      }),
      z.object({
        signed: z.literal(true),
        label: z.string().min(1, 'You must enter your record label'),
      }),
    ]),
    primaryCity: z.string().min(1, 'Primary city is required'),
    secondaryCity: z.string().nullable(),
  });
  export type Step1Values = z.output<typeof step1Schema>;
  export const step1Fields = Object.keys(step1Schema.shape) as (keyof Step1Values)[];

  export const step2Schema = z.object({
    instagram: z.string().nullable(),
    tiktok: z.string().nullable(),
    spotify: z.string().nullable(),
    youtube: z.string().nullable(),
    about: z.string().max(250, 'The message can be at most 250 characters long'),
  });

  export const formSchema = step1Schema.merge(step2Schema);
  export type Values = z.TypeOf<typeof formSchema>;
}
