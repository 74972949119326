import { Box, Typography, styled } from '@mui/material';
import React, { FC, PropsWithChildren, ReactNode } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { breakpoints } from '../../../ui/shared/MediaQueries';

interface ShowcaseBoxGroupProps extends PropsWithChildren {
  title: string;
  image1?: ReactNode | null;
  image2?: ReactNode | null;
  bgColor?: string;
  darkMode?: boolean;
}

export const ShowcaseBoxGroup: FC<ShowcaseBoxGroupProps> = ({ title, children, image1, image2, bgColor, darkMode }) => {
  return (
    <ShowcaseBoxMainContainer
      bgColor={bgColor}
      darkMode={darkMode}
    >
      <ShowcaseBoxTitle darkMode={darkMode}>{title}</ShowcaseBoxTitle>
      <ShowcaseBoxGroupWithImagesContainer>
        {image1}
        <ShowcaseBoxGroupContainer>{children}</ShowcaseBoxGroupContainer>
        {image2}
      </ShowcaseBoxGroupWithImagesContainer>
    </ShowcaseBoxMainContainer>
  );
};

const ShowcaseBoxMainContainer = styled(Box, {
  name: 'ShowcaseBoxMainContainer',
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'darkMode',
})<{ bgColor: string | null; darkMode: boolean | null }>(({ bgColor, darkMode }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '40px',
  backgroundColor: bgColor ? bgColor : darkMode ? colors.SystemBlack : colors.SystemGray100,
  alignItems: 'center',
  justifyContent: 'center',
  [`@media ${breakpoints.md}`]: {
    padding: '0 20px',
  },
}));

const ShowcaseBoxTitle = styled(Typography, {
  name: 'ShowcaseBoxTitle',
  shouldForwardProp: (props) => props !== 'darkMode',
})<{ darkMode: boolean | null }>(({ darkMode }) => ({
  fontFamily: 'Satoshi-Variable',
  color: darkMode ? colors.SystemWhite : colors.SystemBlack,
  ...typography.displaySmall,
  width: '100%',
  textAlign: 'center',
  [`@media ${breakpoints.md}`]: {
    ...typography.headlineMedium,
  },
}));

const ShowcaseBoxGroupWithImagesContainer = styled(Box, {
  name: 'ShowcaseBoxGroupWithImagesContainer',
})({
  display: 'block',
  position: 'relative',
  maxWidth: '1120px',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    maxWidth: '560px',
  },
});

const ShowcaseBoxGroupContainer = styled(Box, {
  name: 'ShowcaseBoxGroupContainer',
})({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  gap: '20px',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'column',
  },
});
