import { Avatar, Typography, styled } from '@mui/material';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import Img from '../../../Components/Images/Img';

export const LeftHandSideComponentTitle = styled(Typography, {
  name: 'LeftHandSideComponentTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

export const IconAvatar = styled(Avatar, {
  name: 'IconAvatar',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.subtitle,
  width: '28px',
  height: '28px',
  backgroundColor: colors.SystemBlack,
  borderRadius: '9999px',
});

export const RightHandSideComponentTitle = styled(Typography, {
  name: 'RightHandSideComponentTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});
//todo: extend this from rhsct
export const RightHandSideComponentTitleDark = styled(Typography, {
  name: 'RightHandSideComponentTitleDark',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.headlineSmall,
});

export const RightHandSideHeadingIcon = styled(Img, {
  name: 'YouTubeHeadingIconContainer',
})({
  width: '36px',
  height: '36px',
});
