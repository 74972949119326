import React, { FC } from 'react';
import Carousel from 'react-multi-carousel';
import { SingleVenueElement, SingleVenueElementProps } from './SingleVenueElement';
import { isEmpty } from 'lodash';
import { Box, Typography, styled } from '@mui/material';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { colors } from '../../../ui/shared/Colors';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import { useGetAllVenuesQuery } from '../../../Redux/API/PublicAPI';

interface VenueData extends SingleVenueElementProps {
  [key: string]: any;
}

interface VenuesRotatorProps {
  venuesList: VenueData[];
}

export const VenuesRotator: FC<VenuesRotatorProps> = ({ venuesList }) => {
  const responsive = {
    xxl: {
      breakpoint: { max: 4000, min: 3000 },
      items: 20,
    },
    xl: {
      breakpoint: { max: 3000, min: 1024 },
      items: 7,
    },
    md: {
      breakpoint: { max: 1024, min: 600 },
      items: 4,
    },
    sm: {
      breakpoint: { max: 600, min: 0 },
      items: 3,
    },
  };
  if (!isEmpty(venuesList)) {
    return (
      <VenuesRotatorContainer>
        <VenueRotatorTitle>Already with TuneHatch</VenueRotatorTitle>
        <Carousel
          responsive={responsive}
          swipeable={true}
          showDots={false}
          customTransition="all .5"
          transitionDuration={500}
        >
          {venuesList.map((venue: any) => {
            if (venue.avatarWebP || venue.avatar) {
              return (
                <SingleVenueElement
                  key={venue?.id}
                  name={venue?.name}
                  avatarWebp={venue.avatarWebP ? venue.avatarWebP : venue.avatar}
                />
              );
            }
          })}
        </Carousel>
      </VenuesRotatorContainer>
    );
  }
};

const VenuesRotatorContainer = styled(Box, {
  name: 'VenuesRotatorContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '56px',
  padding: '80px 0',
  [`@media ${breakpoints.md}`]: {
    padding: '80px 20px',
  },
});

const VenueRotatorTitle = styled(Typography, {
  name: 'VenueRotatorTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.headlineMedium,
  width: '100%',
  textAlign: 'center',
});
