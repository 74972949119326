/**
 * This defines the status if venue is already claimed (confirmed by email):
 *  pending-off-platform - invitation sent but not claimed yet
 *  claimed - invitation has been confirmed by venue
 */
export enum VenueClaimStatus {
  PENDING_OFFPLATFORM = 'pending-off-platform',
  CLAIMED = 'claimed',
}

export interface Venue {
  uid: string;
  _key: string;
  name: string;
  description: string;
  avatar?: string;
  avatarWebP?: string;
  banner?: string;
  bannerWebP?: string;
  type: 'venue';
  meta: any;
  themeColor?: string;
  capacity?: string;
  performanceAgreement?: any;
  hasTextContactEnabled?: boolean;
  customBackline?: string;
  min_age?: number;
  address?: string;
  location?: any;
  contact?: any;
  about?: string;
  phone?: string;
  email?: string;
  images?: any[];
  socials?: any;
  venueFee?: string;
  media?: string;
  stripe?: any;
  terminalLocation?: string;
  dealProfiles?: { [key: string]: any };
  isOpenForManagers?: boolean;
  claim_status?: VenueClaimStatus;
}
