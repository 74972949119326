import { Box, Typography, styled } from '@mui/material';
import React, { ReactNode, FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import { typography } from '../../../ui/shared/TypographySharedElements';

interface InfoItemProps {
  label: string;
  children: ReactNode;
}

export const InfoItem: FC<InfoItemProps> = ({ label, children }) => (
  <InfoItemContainer>
    <InfoItemLabel>{label}</InfoItemLabel>
    <Box
      display="flex"
      justifyContent="center"
    >
      {children}
    </Box>
  </InfoItemContainer>
);

const InfoItemContainer = styled(Box, {
  name: 'InfoItemContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    minWidth: '100%',
  },
});

const InfoItemLabel = styled(Typography, {
  name: 'InfoItemLabel',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray500,
  ...typography.bodySmallRegular,
});
