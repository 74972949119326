import { Box, Card, CardContent, Divider, Typography, styled, useMediaQuery } from '@mui/material';
import React, { FC, useEffect, useMemo } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import AvatarImg from '../../../Images/Avatar.webp';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import dayjs from 'dayjs';
import { IconAvatar } from '../../ProfilePage/Shared/SharedElements';
import { IMAGE_URL } from '../../../Helpers/configConstants';

interface TopShowCardProps {
  id: string;
  name: string;
  date: string;
  venue: TopShowVenue;
  performers?: Performers;
  showrunnerGroup?: TopShowShowrunnerGroup;
}

interface TopShowVenue {
  id: string;
  name: string;
  avatarWebP: string;
}

interface Performers {
  artists: TopShowArtist[];
  total: number | string;
}

interface TopShowArtist {
  id: string;
  firstName: string;
  lastName: string;
  avatarWebP: string;
  stagename?: string;
}

interface TopShowShowrunnerGroup {
  id: string;
  name: string;
  avatarWebP: string;
}

export const TopShowCard: FC<TopShowCardProps> = ({ id, name, date, venue, performers, showrunnerGroup }) => {
  const parsedDate = new Date(date);
  const hours = dayjs(parsedDate).format('h A');
  const month = dayjs(parsedDate).format('MMM');
  const day = parsedDate.getDate();
  const year = parsedDate.getFullYear();
  const isMobile = useMediaQuery(breakpoints.md);
  const numberOfItemsToDisplay = isMobile ? 2 : 1;

  // change it here but data required

  const ArtistsList = useMemo(() => {
    return performers.artists.slice(0, numberOfItemsToDisplay).map((artist) => {
      return (
        <InfoItemInnerContainer key={artist.id}>
          <IconAvatar src={artist.avatarWebP ? IMAGE_URL + artist.avatarWebP : AvatarImg} />

          <InfoItemElementName>
            {artist.stagename ? `${artist.stagename}` : `${artist.firstName} ${artist.lastName}`}
          </InfoItemElementName>
        </InfoItemInnerContainer>
      );
    });
  }, [performers, numberOfItemsToDisplay]);

  return (
    <TopShowCardContainer
      onClick={() => {
        window.open(`/shows/${id}`);
      }}
    >
      <DateSection>
        <DateSubContainer>
          <SubtitleDateText>{month}</SubtitleDateText>
          <HeadlineDateText>{day}</HeadlineDateText>
          <SubtitleDateTextMobileOnly>{year}</SubtitleDateTextMobileOnly>
        </DateSubContainer>
        <SubtitleDateText>{hours}</SubtitleDateText>
      </DateSection>
      <ContentSection>
        <Title variant="h5">{name}</Title>
        <InfoItemsContainer>
          <InfoItemInnerContainer>
            <IconAvatar src={venue.avatarWebP ? IMAGE_URL + venue.avatarWebP : AvatarImg} />
            <InfoItemElementName>{venue.name}</InfoItemElementName>
          </InfoItemInnerContainer>
          {showrunnerGroup && (
            <>
              <InfoElementDivider />
              <InfoItemInnerContainer>
                <IconAvatar src={showrunnerGroup.avatarWebP ? IMAGE_URL + showrunnerGroup.avatarWebP : AvatarImg} />
                <InfoItemElementName>{showrunnerGroup.name}</InfoItemElementName>
              </InfoItemInnerContainer>
            </>
          )}
          {performers.artists?.length > 0 && (
            <>
              <InfoElementDivider />
              <Box
                display="flex"
                gap="12px"
              >
                {ArtistsList}
                {parseInt(`${performers.total}`) > numberOfItemsToDisplay && (
                  <IconAvatar>+{parseInt(`${performers.total}`) - numberOfItemsToDisplay}</IconAvatar>
                )}
              </Box>
            </>
          )}
        </InfoItemsContainer>
      </ContentSection>
    </TopShowCardContainer>
  );
};

const TopShowCardContainer = styled(Card, {
  name: 'ShowCardContainer',
})({
  display: 'flex',
  cursor: 'pointer',
  borderRadius: '12px',
  backgroundColor: colors.SystemWhite,
  boxShadow: 'none',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'column',
  },
});

const DateSection = styled(Box, {
  name: 'DateSection',
})({
  color: colors.SystemGray900,
  padding: '16px 0',
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  borderRadius: '12px 0 0 12px',
  minWidth: '80px',
  width: '80px',
  gap: '4px',
  borderRight: `1px solid ${colors.SystemGray100}`,
  [`@media ${breakpoints.md}`]: {
    width: '100%',
    flexDirection: 'row',
    borderRadius: '0',
    justifyContent: 'space-between',
    padding: '16px',
    borderRight: 'none',
    borderBottom: `1px solid ${colors.SystemGray100}`,
  },
});

const DateSubContainer = styled(Box, {
  name: 'DateSubContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'row',
  },
});

const SubtitleDateText = styled(Typography, {
  name: 'SubtitleDateText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.subtitle,
});

const SubtitleDateTextMobileOnly = styled(Typography, {
  name: 'SubtitleDateText',
})({
  display: 'none',
  [`@media ${breakpoints.md}`]: {
    display: 'inline',
    fontFamily: 'Satoshi-Variable',
    color: colors.SystemGray900,
    ...typography.subtitle,
  },
});

const HeadlineDateText = styled(Typography, {
  name: 'HeadlineDateText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineMedium,
  [`@media ${breakpoints.md}`]: {
    ...typography.subtitle,
  },
});

const ContentSection = styled(CardContent, {
  name: 'ContentSection',
})({
  display: 'flex',
  padding: '20px 24px',
  flexDirection: 'column',
  gap: '12px',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    padding: '20px',
  },
});

const Title = styled(Typography, {
  name: 'Title',
})({
  color: colors.SystemBlack,
  ...typography.headlineMedium,
  fontFamily: 'Satoshi-Variable',
  [`@media ${breakpoints.md}`]: {
    ...typography.headlineSmall,
  },
  minHeight: '60px',
  fontSize: '20px',
  lineHeight: '30px',
});

const InfoItemsContainer = styled(Box, {
  name: 'InfoItemsContainer',
})({
  display: 'flex',
  alignItems: 'center',
  gap: '16px',
  [`@media ${breakpoints.md}`]: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
});

const InfoItemInnerContainer = styled(Box, {
  name: 'InfoItemInnerContainer',
})({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '4px',
});

const InfoItemElementName = styled(Typography, {
  name: 'InfoItemElementName',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.subtitle,
  [`@media ${breakpoints.md}`]: {
    ...typography.buttonSmall,
  },
});

const InfoElementDivider = styled(Divider, {
  name: 'InfoElementDivider',
})({
  display: 'block',
  width: '1px',
  backgroundColor: colors.SystemGray100,
  height: '100%',
  [`@media ${breakpoints.md}`]: {
    width: '100%',
    height: '1px',
  },
});
