import { Box, Typography, styled } from '@mui/material';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { IconButton } from '../../../ui/buttons/IconButton';
import { Button } from '../../../ui/buttons/Button';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import { isEmpty } from 'lodash';
import { useGetTopShows } from './useGetTopShows';
import LoadingSpinner from '../../../Components/LoadingSpinner';
import { TopShowCard } from './TopShowCard';
import { useNavigate } from 'react-router-dom';

export const TopShows: FC = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const topShows = useGetTopShows({ setIsLoading });

  const isNextPageEnabled = useMemo(() => {
    const actualPerPage = 3;
    const totalViewed = (page + 1) * actualPerPage;
    return topShows?.total > totalViewed;
  }, [page, topShows]);

  const isPrevPageEnabled = useMemo(() => {
    return page > 0;
  }, [page]);

  const TopShowCards = useMemo(() => {
    if (!isEmpty(topShows?.data)) {
      const topShowsPart = topShows?.data.slice(page * 3, (page + 1) * 3);
      return topShowsPart.map((topShow) => {
        return (
          <TopShowCard
            performers={{ artists: topShow.artists, total: topShow.artists.length }}
            showrunnerGroup={topShow.showrunnerGroups[0]}
            id={topShow.id}
            date={topShow.startTime}
            venue={topShow.venue}
            name={topShow.name}
          />
        );
      });
    }
  }, [topShows?.data, page]);

  const setNextPage = useCallback(() => {
    if (isNextPageEnabled) {
      setPage((page) => page + 1);
    }
  }, [isNextPageEnabled]);

  const setPreviousPage = useCallback(() => {
    if (isPrevPageEnabled) {
      setPage((page) => page - 1);
    }
  }, [isPrevPageEnabled]);

  if (isLoading) {
    return (
      <TopShowsMainContainer>
        <TopShowsTitle>Almost Sold Out</TopShowsTitle>
        <Box
          display="block"
          position="relative"
          bgcolor={colors.SystemWhite}
        >
          <LoadingSpinner />
        </Box>
      </TopShowsMainContainer>
    );
  }
  return (
    <TopShowsMainContainer>
      <TopShowsTitle>Almost Sold Out</TopShowsTitle>
      {!isEmpty(topShows?.data) ? (
        <>
          <Box
            display="flex"
            flexDirection="column"
            gap="16px"
          >
            {TopShowCards}
          </Box>
          <TopShowsBottomRow>
            <ButtonBox>
              <IconButtonWhite
                isPrimary={true}
                onClick={setPreviousPage}
                disabled={!isPrevPageEnabled}
                size="large"
                iconName="chevron_left"
              />
              <IconButtonWhite
                isPrimary={true}
                onClick={setNextPage}
                disabled={!isNextPageEnabled}
                size="large"
              />
            </ButtonBox>
            <ButtonWhite
              disabled={false}
              size="large"
              isPrimary={false}
              onClick={() => navigate('/shows')}
            >
              Show all shows
            </ButtonWhite>
          </TopShowsBottomRow>
        </>
      ) : (
        <Box
          width="100%"
          bgcolor={colors.SystemWhite}
          borderRadius="12px"
        >
          <Typography
            padding="60px 0"
            textAlign="center"
            width="100%"
          >
            Currently there are no top shows.
          </Typography>
        </Box>
      )}
    </TopShowsMainContainer>
  );
};

const TopShowsMainContainer = styled(Box, {
  name: 'TopShowsMainContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  padding: '24px',
  borderRadius: '24px',
  backgroundColor: colors.SystemGray700,
  minWidth: '709px',
  maxWidth: '49%',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    padding: '16px 12px',
    maxWidth: 'calc(100% - 40px)',
    minWidth: 'auto',
  },
});

const TopShowsTitle = styled(Typography, {
  name: 'TopShowsTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.headlineSmall,
  textAlign: 'center',
  [`@media ${breakpoints.md}`]: {
    width: '100%',
  },
});

const TopShowsBottomRow = styled(Box, {
  name: 'TopShowsBottomRow',
})({
  display: 'flex',
  justifyContent: 'space-between',
});

const ButtonBox = styled(Box, {
  name: 'ButtonBox',
})({
  display: 'flex',
  gap: '16px',
});

const IconButtonWhite = styled(IconButton, {
  name: 'IconButtonWhite',
})<{ disabled: boolean }>(({ disabled }) => ({
  backgroundColor: colors.SystemWhite,
  opacity: disabled ? 0.2 : 1,
  '.material-symbols-outlined': {
    color: colors.SystemGray900,
  },
}));

const ButtonWhite = styled(Button, {
  name: 'ButtonWhite',
})({
  backgroundColor: colors.SystemWhite,
  color: colors.SystemGray900,
});
