import { Box, styled, Typography } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import { isEmpty } from 'lodash';
import { breakpoints } from '../../ui/shared/MediaQueries';

interface ShowDetailsGalleryProps {
  images: string[];
}

export const ShowDetailsGallery: FC<ShowDetailsGalleryProps> = ({ images }) => {
  if (isEmpty(images)) {
    return null;
  }
  return (
    <ShowDetailsGalleryCont>
      <ShowDetailsGalleryTitle>Gallery</ShowDetailsGalleryTitle>
      <ShowDetailsGalleryInnerCont>
        {images.map((image) => (
          <ShowDetailsGalleryImage
            key={image}
            image={image}
          />
        ))}
      </ShowDetailsGalleryInnerCont>
    </ShowDetailsGalleryCont>
  );
};

const ShowDetailsGalleryCont = styled(Box, {
  name: 'ShowDetailsGalleryCont',
})({
  padding: '20px',
  maxWidth: '800px',
  width: '100%',
  backgroundColor: colors.SystemWhite,
  borderRadius: '12px',
});

const ShowDetailsGalleryTitle = styled(Typography, {
  name: 'ShowDetailsAboutTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineSmall,
});

const ShowDetailsGalleryInnerCont = styled(Box, {
  name: 'ShowDetailsGalleryInnerCont',
})({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '8px',
  width: '100%',
  [`@media ${breakpoints.md}`]: {
    flexWrap: 'nowrap',
    flexDirection: 'column',
  },
});

const ShowDetailsGalleryImage = styled(Box, {
  name: 'ShowDetailsGalleryImage',
  shouldForwardProp: (prop) => prop !== 'image',
})<{ image: string }>(({ image }) => ({
  width: 'calc(50% - 24px)',
  paddingBottom: '27.5%',
  background: `url(${image})`,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  height: '0',
  [`@media ${breakpoints.md}`]: {
    width: '100%',
    paddingBottom: '54.5%',
  },
}));
