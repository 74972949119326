import { Box, MenuItem, styled } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TH_GENRES } from '../../../Helpers/configConstants';
import { DataCy } from '../../../Helpers/DataCy';
import { Button } from '../../../ui/buttons/Button';
import { Select, TextField } from '../../../ui/inputs/TextField';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormSectionContainer,
  FormInputGroupWrapper,
  FormInputGroupElementWrapper,
  FormMainContainerColumn,
} from '../Shared/Containers';
import { FormToggleGroup, Toggle } from '../Shared/Other';
import {
  FormStepHeadlineStepCounter,
  FormStepHeadlineTitle,
  FormSectionTitle,
  FormSectionSubtitle,
} from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { ArtistFormData } from './ArtistFormData';

export const ArtistFormStep1: FC<FormStepProps> = ({ step, setStep }) => {
  const artistForm = useFormContext<ArtistFormData.Step1Values>();
  const signed = artistForm.watch('recordLabel.signed');

  const handleChangeSigned = useCallback(
    (event: React.MouseEvent<HTMLElement> | any, value: boolean) => {
      artistForm.setValue('recordLabel.signed', value);
      if (value === false) {
        artistForm.resetField('recordLabel.label');
      } else {
        artistForm.setValue('recordLabel.label', '');
      }
    },
    [artistForm],
  );

  const handleNextStep = useCallback(async () => {
    const isValid = await artistForm.trigger(ArtistFormData.step1Fields);
    if (isValid === true) {
      setStep((step) => step + 1);
    }
  }, [artistForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 1 of 2</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Introduce yourself</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <FormSectionContainer>
            <FormSectionTitle>Who are you?</FormSectionTitle>
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...artistForm.register('firstName')}
                  type="text"
                  label="First Name"
                  placeholder="Enter first name"
                  error={!!artistForm.formState.errors?.firstName?.message}
                  helperText={
                    !!artistForm.formState.errors?.firstName?.message && (
                      <>{artistForm.formState.errors?.firstName?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...artistForm.register('lastName')}
                  type="text"
                  label="Last Name"
                  placeholder="Enter last name"
                  error={!!artistForm.formState.errors?.lastName?.message}
                  helperText={
                    !!artistForm.formState.errors?.lastName?.message && (
                      <>{artistForm.formState.errors?.lastName?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
            <TextField
              {...artistForm.register('stageName')}
              type="text"
              label="Stage Name (Optional)"
              placeholder="Enter stage name"
              error={!!artistForm.formState.errors?.stageName?.message}
              helperText={
                !!artistForm.formState.errors?.stageName?.message && (
                  <>{artistForm.formState.errors?.stageName?.message}</>
                )
              }
            />
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <Select
                  {...artistForm.register('genre')}
                  type="text"
                  label="Genre"
                  error={!!artistForm.formState.errors?.genre?.message}
                  helperText={
                    !!artistForm.formState.errors?.genre?.message && <>{artistForm.formState.errors?.genre?.message}</>
                  }
                  select
                  defaultValue="none"
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Select
                  </MenuItem>
                  {TH_GENRES.map((genre) => {
                    return (
                      <MenuItem
                        key={genre}
                        value={genre}
                      >
                        {genre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...artistForm.register('subGenre')}
                  type="text"
                  label="Any sub-genre? (Optional)"
                  placeholder="Enter sub genre"
                  error={!!artistForm.formState.errors?.subGenre?.message}
                  helperText={
                    !!artistForm.formState.errors?.subGenre?.message && (
                      <>{artistForm.formState.errors?.subGenre?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
          </FormSectionContainer>
          <FormSectionContainer>
            <FormSectionTitle>Are you signed? (Optional)</FormSectionTitle>
            <ToggleWithInputContainer>
              <Controller
                name="recordLabel.signed"
                control={artistForm.control}
                render={(field) => {
                  return (
                    <FormToggleGroup
                      {...field}
                      onChange={handleChangeSigned}
                      exclusive
                      aria-label="recordLabel.signed"
                    >
                      <Toggle
                        value={true}
                        selected={artistForm.getValues('recordLabel.signed')}
                        data-cy={DataCy.button('imSigned')}
                      >
                        Yes
                      </Toggle>
                      <Toggle
                        value={false}
                        selected={!artistForm.getValues('recordLabel.signed')}
                        data-cy={DataCy.button('imNotSigned')}
                      >
                        No
                      </Toggle>
                    </FormToggleGroup>
                  );
                }}
              />
              <FormMainContainerColumn>
                <TextField
                  {...artistForm.register('recordLabel.label')}
                  disabled={signed === false}
                  type="text"
                  label="Your label"
                  placeholder="Enter your label"
                  error={!!artistForm.formState.errors?.recordLabel?.label?.message}
                  helperText={
                    !!artistForm.formState.errors?.recordLabel?.label?.message && (
                      <>{artistForm.formState.errors?.recordLabel?.label?.message}</>
                    )
                  }
                />
              </FormMainContainerColumn>
            </ToggleWithInputContainer>
          </FormSectionContainer>
          <FormSectionContainer>
            <FormTitleWithSubtitleContainer>
              <FormSectionTitle>Where do you want to play?</FormSectionTitle>
              <FormSectionSubtitle>
                Your primary city is where you play regularly, when your secondary city is where you play 2nd most
                often.
              </FormSectionSubtitle>
            </FormTitleWithSubtitleContainer>
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...artistForm.register('primaryCity')}
                  type="text"
                  label="Primary City"
                  placeholder="Enter your primary city"
                  error={!!artistForm.formState.errors?.primaryCity?.message}
                  helperText={
                    !!artistForm.formState.errors?.primaryCity?.message && (
                      <>{artistForm.formState.errors?.primaryCity?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...artistForm.register('secondaryCity')}
                  type="text"
                  label="Secondary City (Optional)"
                  placeholder="Enter your secondary city"
                  error={!!artistForm.formState.errors?.secondaryCity?.message}
                  helperText={
                    !!artistForm.formState.errors?.secondaryCity?.message && (
                      <>{artistForm.formState.errors?.secondaryCity?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
          </FormSectionContainer>
          <Button
            isPrimary={true}
            onClick={handleNextStep}
            size="large"
            data-cy={DataCy.button('nextStep')}
          >
            Next
          </Button>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};

const ToggleWithInputContainer = styled(Box, {
  name: 'ToggleWithInputContainer',
})({
  display: 'flex',
  gap: '20px',
  width: '100%',
});
