import { Box, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import PricingImg from '../../../Images/VenueLP/pricing.png';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import CoinHand from '../../../Images/VenueLP/coinHand.svg';
import HandBills from '../../../Images/VenueLP/handBills.svg';

export const Pricing: FC = () => {
  return (
    <PricingContainer>
      <PricingTitle>And how much does it cost? It's free!</PricingTitle>
      <PricingText>
        Our business model is built on adding a service fee on tickets - so we earn by helping you sell more tickets.
      </PricingText>
      <Box position="relative">
        <SubImage1 src={CoinHand} />
        <PricingImage src={PricingImg} />
        <SubImage2 src={HandBills} />
      </Box>
    </PricingContainer>
  );
};

const PricingContainer = styled(Box, {
  name: 'PricingContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '120px 0',
  [`@media ${breakpoints.md}`]: {
    padding: '40px 20px',
  },
});

const PricingTitle = styled(Typography, {
  name: 'PricingTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.displaySmall,
  paddingBottom: '16px',
  [`@media ${breakpoints.md}`]: {
    ...typography.headlineLarge,
  },
});

const PricingText = styled(Typography, {
  name: 'PricingText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.bodyLargeRegular,
  paddingBottom: '60px',
});

export const PricingImage = styled('img', {
  name: 'PricingImage',
})({
  width: '746px',
  height: 'auto',
  padding: '50px',
  [`@media ${breakpoints.md}`]: {
    padding: '20px',
  },
});

const SubImage1 = styled('img', {
  name: 'SubImage1',
})({
  width: '200px',
  height: '200px',
  position: 'absolute',
  left: '-5%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  [`@media ${breakpoints.md}`]: {
    width: '100px',
    height: '100px',
    left: '5%',
    top: '45%',
  },
});

const SubImage2 = styled('img', {
  name: 'SubImage2',
})({
  width: '200px',
  height: '200px',
  position: 'absolute',
  left: '105%',
  top: '35%',
  transform: 'translate(-50%, -50%)',
  [`@media ${breakpoints.md}`]: {
    width: '100px',
    height: '100px',
    left: '95%',
    top: '30%',
  },
});
