import React, { FC } from 'react';
import HatchedArtist from '../../Images/Icons/HatchedArtist.webp';
import { styled } from '@mui/material/styles';
import { typography } from '../../ui/shared/TypographySharedElements';
import { colors } from '../../ui/shared/Colors';
import Img from '../../Components/Images/Img';
import { breakpoints } from '../../ui/shared/MediaQueries';
import { Box } from '@mui/material';

type HatchLinksHeadingProps = {
  stripeEnabled: boolean;
  artistsName: string;
};

export const HatchLinksHeading: FC<HatchLinksHeadingProps> = ({ stripeEnabled, artistsName }) => {
  return (
    <HatchLinksHeadingCont>
      <SectionHeading>{artistsName}</SectionHeading>
      {!!stripeEnabled && (
        <VerifiedIconCont>
          <VerifiedIcon
            src={HatchedArtist}
            alt="Hatched artist icon"
          />
        </VerifiedIconCont>
      )}
    </HatchLinksHeadingCont>
  );
};

const HatchLinksHeadingCont = styled(Box, {
  name: 'HatchLinksHeadingCont',
})({
  display: 'flex',
  justifyContent: 'center',
  gap: '8px',
});

const SectionHeading = styled('p', {
  name: 'SectionHeading',
})({
  ...typography.headlineMedium,
  color: colors.SystemBlack,
  textAlign: 'center',
  [`@media ${breakpoints.md}`]: {
    ...typography.headlineSmall,
  },
});

const VerifiedIconCont = styled(Box, {
  name: 'VerifiedIconCont',
})({
  position: 'relative',
  width: '30px',
  height: '0',
  paddingBottom: '30px',
  [`@media ${breakpoints.md}`]: {
    width: '26px',
    paddingBottom: '26px',
  },
});

const VerifiedIcon = styled(Img, {
  name: 'VerifiedIcon',
})({
  position: 'absolute',
  left: '0',
  top: '0',
  width: '100%',
  height: '100%',
});
