import { Box, Grow, Typography, styled } from '@mui/material';
import React, { FC, useRef, useState } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';
import { breakpoints } from '../../../ui/shared/MediaQueries';
import useOnScreen from './useOnScreen';

export const PromotionText: FC = () => {
  const ref1 = useRef();
  const ref2 = useRef();
  const ref3 = useRef();
  const ref4 = useRef();
  const ref5 = useRef();
  const intersecting1 = useOnScreen(ref1);
  const intersecting2 = useOnScreen(ref2);
  const intersecting3 = useOnScreen(ref2);
  const intersecting4 = useOnScreen(ref2);
  const intersecting5 = useOnScreen(ref2);

  return (
    <PromotionTextContainer>
      <Grow
        in={intersecting1}
        style={{ transformOrigin: '0 0 0' }}
        {...(intersecting1 ? { timeout: 1000 } : {})}
      >
        <PromotionTextParagraph ref={ref1}>We created TuneHatch because we saw how </PromotionTextParagraph>
      </Grow>
      <Grow
        in={intersecting2}
        style={{ transformOrigin: '0 0 0' }}
        {...(intersecting1 ? { timeout: 1000 } : {})}
      >
        <PromotionTextParagraph ref={ref2}>
          booking and promoting shows could really eat up your time.
        </PromotionTextParagraph>
      </Grow>
      <Grow
        in={intersecting3}
        style={{ transformOrigin: '0 0 0' }}
        {...(intersecting1 ? { timeout: 1000 } : {})}
      >
        <PromotionTextParagraph ref={ref3}>Between so many moving pieces, last-minute changes </PromotionTextParagraph>
      </Grow>
      <Grow
        in={intersecting4}
        style={{ transformOrigin: '0 0 0' }}
        {...(intersecting1 ? { timeout: 1000 } : {})}
      >
        <PromotionTextParagraph ref={ref4}>
          and endless emails, things can slip through the cracks.
        </PromotionTextParagraph>
      </Grow>
      <PromotionTextParagraph>&nbsp;</PromotionTextParagraph>
      <Grow
        in={intersecting5}
        style={{ transformOrigin: '0 0 0' }}
        {...(intersecting2 ? { timeout: 1000 } : {})}
      >
        <PromotionTextParagraph ref={ref5}>So, we set out to make it easier.</PromotionTextParagraph>
      </Grow>
    </PromotionTextContainer>
  );
};

const PromotionTextContainer = styled(Box, {
  name: 'PromotionTextContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  margin: '0 auto',
  padding: '120px 40px',
  maxWidth: '980px',
  width: '100%',
  alignItems: 'center',
  [`@media ${breakpoints.md}`]: {
    padding: '40px 20px',
  },
});

const PromotionTextParagraph = styled(Typography, {
  name: 'PromotionTextParagraph',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.headlineLarge,
  textAlign: 'center',
  [`@media ${breakpoints.md}`]: {
    ...typography.headlineLarge,
  },
});
