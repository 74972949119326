import { zodResolver } from '@hookform/resolvers/zod';
import axios, { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getCookie } from '../../../Helpers/HelperFunctions';
import { getClaimData } from '../Shared/ClaimDataHelpers';
import { ArtistFormStep1 } from './ArtistForm1';
import { ArtistFormStep2 } from './ArtistForm2';
import { ArtistFormData } from './ArtistFormData';
import { useAppDispatch } from '../../../hooks';
import { setFullscreen } from '../../../Redux/UI/UISlice';

export const ArtistForm = () => {
  const parsedInitialData = getClaimData();
  const artistInitialData = parsedInitialData?.artist;
  const [step, setStep] = useState(1);
  const [avatar, setAvatar] = useState<File | null>(null);
  const navigate = useNavigate();

  const initialArtistFormValues: ArtistFormData.Values = {
    firstName: parsedInitialData?.firstName ?? '',
    lastName: parsedInitialData?.lastName ?? '',
    stageName: artistInitialData?.stagename ?? null,
    genre: 'none',
    subGenre: null,
    recordLabel: {
      signed: false,
      label: null,
    },
    primaryCity: '',
    secondaryCity: null,
    instagram: null,
    tiktok: null,
    spotify: null,
    youtube: null,
    about: '',
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setFullscreen({ status: true }));
    return () => {
      dispatch(setFullscreen({ status: false }));
    };
  }, []);

  const artistForm = useForm({
    resolver: zodResolver(ArtistFormData.formSchema),
    defaultValues: initialArtistFormValues,
    values: initialArtistFormValues,
    mode: 'all',
  });

  const submitArtist = async (data: ArtistFormData.Values) => {
    try {
      const token = getCookie('SECRET_UID');
      const result = await axios.patch(
        `${process.env.REACT_APP_PUBLIC_URL}:${process.env.REACT_APP_SERVER_PORT}/api/v2/me/artist`,
        {
          body: JSON.stringify({
            firstName: data.firstName,
            lastName: data.lastName,
            stageName: data.stageName,
            genre: data.genre,
            subgenre: data.subGenre,
            recordLabelName: data.recordLabel.signed === true ? data.recordLabel.label : null,
            primaryCity: {
              name: data.primaryCity,
              meta: {
                coordinates: '',
              },
            },
            secondaryCity:
              data.secondaryCity !== null
                ? {
                    name: data.secondaryCity,
                    meta: {
                      coordinates: '',
                    },
                  }
                : null,
            socials: {
              instagramLink: data.instagram ?? null,
              youtubeLink: data.youtube ?? null,
              spotifyLink: data.spotify ?? null,
              tikTokLink: data.tiktok ?? null,
            },
            about: data.about,
          }),
          avatar: avatar,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'multipart/form-data',
            Accept: '*/*',
            'Accept-encoding': 'gzip, deflate, br',
            Connection: 'keep-alive',
          },
        },
      );
      if (result.status === 204) {
        navigate('/register/success/artist');
      } else {
        window.alert(result.data?.message);
        setStep(1);
      }
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response.status === 413) {
        window.alert('Uploaded images are too large');
        setStep(2);
      } else {
        setStep(1);
        window.alert('Unknown server error');
        console.log(error);
      }
    }
  };

  return (
    <FormProvider {...artistForm}>
      <form onSubmit={artistForm.handleSubmit(submitArtist)}>
        {step === 1 && (
          <ArtistFormStep1
            step={step}
            setStep={setStep}
          />
        )}
        {step === 2 && (
          <ArtistFormStep2
            step={step}
            setStep={setStep}
            setAvatar={setAvatar}
            submitArtist={submitArtist}
            selectedAvatar={avatar}
          />
        )}
      </form>
    </FormProvider>
  );
};
