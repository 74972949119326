import { Box, Typography, styled } from '@mui/material';
import React, { FC, ReactElement } from 'react';
import { colors } from '../../../ui/shared/Colors';
import { typography } from '../../../ui/shared/TypographySharedElements';

interface SocialStatBoxProps {
  title: string;
  social: ReactElement;
}

export const SocialStatBox: FC<SocialStatBoxProps> = ({ title, social }) => {
  return (
    <SocialStatBoxMainContainer>
      <SocialStatName>{title}</SocialStatName>
      {social}
    </SocialStatBoxMainContainer>
  );
};

const SocialStatBoxMainContainer = styled(Box, {
  name: 'SocialStatBoxMainContainer',
})({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 28px',
  borderRadius: '12px',
  backgroundColor: 'rgba(242, 240, 236, 0.5)',
});

export const SocialStatName = styled(Typography, {
  name: 'SocialStatName',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.subtitle,
});

export const SocialStatAmount = styled(Typography, {
  name: 'SocialStatAmount',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemBlack,
  ...typography.headlineLarge,
});
