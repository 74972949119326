import { Box, Typography, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { colors } from '../../ui/shared/Colors';
import { typography } from '../../ui/shared/TypographySharedElements';
import HomepageBG from '../../Images/Homepage/HomepageBGCrop.jpg';
import { breakpoints } from '../../ui/shared/MediaQueries';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { setDarkMode, setFullscreen, setTransparentFooter } from '../../Redux/UI/UISlice';
import { useLocation } from 'react-router-dom';
import { HomepageTabs } from './HomepageTabs/HomepageTabs';
import { VenuesContent } from './VenuesContent/VenuesContent';
import { PerformersContent } from './PerformersContent/PerformersContent';
import { ViewersContent } from './ViewersContent/ViewersContent';
import { NavbarLinkButton } from '../Navbar/Navbar';
import TabBottomImg from '../../Images/Homepage/TabBottomImg.png';
import { backgroundSize } from 'html2canvas/dist/types/css/property-descriptors/background-size';

export const Homepage: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const darkMode = useAppSelector((state) => state.ui.grid.darkMode);

  useEffect(() => {
    dispatch(setDarkMode({ status: true }));
    dispatch(setTransparentFooter({ status: true }));
    return () => {
      dispatch(setDarkMode({ status: false }));
      dispatch(setTransparentFooter({ status: false }));
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(setFullscreen({ status: false }));
  }, [location]);

  return (
    <HomepageMainContainer>
      <HomepageMainInnerContainer>
        <HomepageTextWithImageContainer>
          {/* <HomepageSpecialImageBox /> */}

          <Box
            display="flex"
            flexDirection="column"
            width="100%"
            gap="16px"
            maxWidth="1000px"
            alignItems="center"
          >
            <HomepageMainContentContainer>
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                gap={'10px'}
                paddingX={'20px'}
                justifyContent={'center'}
                width={'100%'}
              >
                <HomepageTitle>Book. Promote. Ticket.</HomepageTitle>
                <HomepageSubTitle>
                  TuneHatch enables venues, promoters and artists to connect, promote and ticket live shows. It also
                  empowers audience members to find new music and purchase tickets for their favorite live events.
                </HomepageSubTitle>
                <HomepageButtonsCont>
                  <NavbarLinkButton
                    href="/register/venue"
                    isVenuesLink={true}
                    darkMode={darkMode}
                  >
                    Join as venue
                  </NavbarLinkButton>
                  <NavbarLinkButton
                    href="/register"
                    isVenuesLink={false}
                    darkMode={darkMode}
                  >
                    Join as artist or promoter
                  </NavbarLinkButton>
                </HomepageButtonsCont>
              </Box>
              <Box width="100%">
                <img
                  src={HomepageBG}
                  style={{ width: '100%', position: 'relative' }}
                />
              </Box>
            </HomepageMainContentContainer>
          </Box>
        </HomepageTextWithImageContainer>
        <HomepageTabs
          venuesTabContent={<VenuesContent />}
          performersTabContent={<PerformersContent />}
          viewersTabContent={<ViewersContent />}
        />
        {/* todo: image should be semitransparent; adjusting either of these either does not change the image opacity directly or messes
        with the navbar opacity. @RDF */}
        <ImageBottomBox />
        <BottomImageCover />
      </HomepageMainInnerContainer>
    </HomepageMainContainer>
  );
};

const HomepageMainContentContainer = styled(Box, {
  name: 'HomepageMainContentContainer',
})({
  display: 'flex',
  [`@media ${breakpoints.sm}`]: {
    flexDirection: 'column-reverse',
    gap: '10px',
  },
});

const HomepageMainContainer = styled(Box, {
  name: 'HomepageMainContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0',
  backgroundColor: colors.SystemBlack,
  flexGrow: 1,
  zIndex: 0,
  [`@media ${breakpoints.xl}`]: {
    padding: '24px 0',
    paddingTop: '0',
  },
});

const HomepageMainInnerContainer = styled(Box, {
  name: 'HomepageMainInnerContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: '1280px',
  gap: '40px',
  width: '100%',
  zIndex: 2,
  paddingBottom: '240px',
  position: 'relative',
  [`@media ${breakpoints.md}`]: {
    gap: '60px',
  },
});

const HomepageTextWithImageContainer = styled(Box, {
  name: 'HomepageTextWithImageContainer',
})({
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  justifyContent: 'center',
});

const HomepageSpecialImageBox = styled(Box, {
  name: 'HomepageSpecialImageBox',
})({
  display: 'block',
  position: 'absolute',
  top: '-30px',
  width: '1334.5px',
  paddingBottom: '670.65px',
  backgroundImage: `url(${HomepageBG})`,
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center',
  zIndex: -1,
  [`@media ${breakpoints.md}`]: {
    top: '0',
    paddingBottom: '430px',
    maxWidth: '800px',
    backgroundSize: '800px',
    width: '100%',
    marginTop: '-24px',
  },
});

const HomepageTitle = styled(Typography, {
  name: 'HomepageTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.headlineLarge,
  textAlign: 'center',
  [`@media ${breakpoints.md}`]: {
    ...typography.headlineMedium,
  },
});

const HomepageSubTitle = styled(Typography, {
  name: 'HomepageSubTitle',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemWhite,
  ...typography.bodyLargeRegular,
  textAlign: 'center',
});

const HomepageButtonsCont = styled(Box, {
  name: 'HomepageButtonsCont',
})({
  display: 'flex',
  gap: '12px',
  width: '100%',
  justifyContent: 'center',
  [`@media ${breakpoints.md}`]: {
    maxWidth: '320px',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

const ImageBottomBox = styled(Box, {
  name: 'ImageBottomBox',
})({
  position: 'absolute',
  top: 'calc(100% - 764px + 88px + 24px)',
  height: '764px',
  opacity: '0.9',
  background: `url(${TabBottomImg})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPositionY: '100%',
  filter: 'blur(5px)',
  width: '102vw',
  zIndex: 0,
  [`@media ${breakpoints.md}`]: {
    top: 'calc(100% - 764px + 184px + 24px)',
  },
});
const BottomImageCover = styled(Box, {
  name: 'BottomImageCover',
})({
  position: 'absolute',
  top: 'calc(100% - 764px + 88px + 24px)',
  height: '764px',
  // background: `radial-gradient(ellipse at bottom, #FF9900, transparent)`,
  filter: 'blur(100px)',
  width: '102vw',
  zIndex: 0,
  [`@media ${breakpoints.md}`]: {
    top: 'calc(100% - 764px + 184px + 24px)',
    filter: 'blur(15px)',
  },
});
