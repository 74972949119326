import React, { FC, useCallback, Dispatch, SetStateAction } from 'react';
import { DataCy } from '../../../Helpers/DataCy';
import { DropZone } from '../../../ui/inputs/DropZone';
import { Button } from '../../../ui/buttons/Button';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormSectionContainer,
  FormMainContainerColumn,
  FormMainContainer,
} from '../Shared/Containers';
import {
  FormStepHeadlineStepCounter,
  FormStepHeadlineTitle,
  FormSectionTitle,
} from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { FieldValues, SubmitHandler, useFormContext } from 'react-hook-form';

interface VenueStep3Props extends FormStepProps {
  setVenueImages: Dispatch<SetStateAction<File[] | null>>;
  venueImages: File[];
  submitVenue: SubmitHandler<FieldValues>;
}

export const VenueFormStep4: FC<VenueStep3Props> = ({ step, setStep, setVenueImages, submitVenue, venueImages }) => {
  const venueForm = useFormContext();

  const handleBack = useCallback(() => {
    setStep((step) => step - 1);
  }, [setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 4 of 4</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Upload photos of your venue (optional)</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <FormSectionContainer data-cy={'venueImages'}>
            <FormSectionTitle>Photos of your venue(max 6)</FormSectionTitle>
            <DropZone
              setSelectedFiles={setVenueImages}
              maxFiles={6}
              selectedFiles={venueImages}
            />
          </FormSectionContainer>
          <FormMainContainer>
            <Button
              isPrimary={false}
              size="large"
              onClick={handleBack}
              data-cy={DataCy.button('prevStep')}
            >
              Back
            </Button>
            <FormMainContainerColumn>
              <Button
                isPrimary={true}
                size="large"
                onClick={venueForm.handleSubmit(submitVenue, () => console.log(venueForm.getValues('operatingHours')))}
                data-cy={DataCy.button('createVenue')}
              >
                Next
              </Button>
            </FormMainContainerColumn>
          </FormMainContainer>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
