import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useUpdateSpotifyCredentialsMutation } from '../Redux/API/ArtistAPI';
import { AuthState } from './AuthState';
import { Box, styled, Typography } from '@mui/material';
import { colors } from '../ui/shared/Colors';
import { typography } from '../ui/shared/TypographySharedElements';
import { Button } from '../ui/buttons/Button';

export function SpotifyCallback() {
  const [isUpdated, setIsUpdated] = useState(false);
  const [searchParams] = useSearchParams();
  const [updateCredentials, updateCrdentialsStatus] = useUpdateSpotifyCredentialsMutation();

  /**
   * @see https://developer.spotify.com/documentation/web-api/tutorials/code-flow
   * "If the user accepts your request, then the user is redirected back to the application using the redirect_uri passed on the authorized request described above."
   * - code
   * - state
   * "If the user does not accept your request or if an error has occurred, the response query string contains the following parameters:"
   * - error
   * - state
   *
   * TikTok describes how to use "state" field for preventing request forgery attacks
   * https://developers.tiktok.com/doc/login-kit-web/
   * In short, before sending the request to the provider store the state
   */
  const params = {
    accessCode: searchParams.get('code'),
    error: searchParams.get('error'),
    state: searchParams.get('state'),
  };

  const spotifyState = AuthState.reconstruct('spotify');
  if (spotifyState.isEqual(params.state) === false) {
    // it means that the user is subject to CSRF https://datatracker.ietf.org/doc/html/rfc6749#section-10.12
    // in such case we CANNOT proceed and send the code to the BE
    // TODO figure out what do to in such case
    throw new Error('The states are not equal. CSRF detected.');
  }

  return (
    <CallbackContainer>
      {!isUpdated ? (
        <>
          <CallbackText>
            Thank you! Just one step and your Spotify statistics will be visible for everyone!
          </CallbackText>
          <Button
            isPrimary={true}
            size="large"
            disabled={false}
            onClick={() => {
              updateCredentials({ spotifyAccessCode: params.accessCode })
                .unwrap()
                .then((result) => {
                  setIsUpdated(true);
                })
                .catch((error) => console.log(error));
            }}
          >
            Update credentials now!
          </Button>
        </>
      ) : (
        <>
          <CallbackText>Thank you! You have succesfully finished updating your Spotify stats.</CallbackText>
          <CallbackText>You can close this page now.</CallbackText>
        </>
      )}
    </CallbackContainer>
  );
}

export const CallbackContainer = styled(Box, {
  name: 'CallbackContainer',
})({
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  width: '100%',
  flexGrow: 1,
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '40px',
});

export const CallbackText = styled(Typography, {
  name: 'CallbackText',
})({
  fontFamily: 'Satoshi-Variable',
  color: colors.SystemGray900,
  ...typography.headlineLarge,
  maxWidth: '600px',
  width: '100%',
  textAlign: 'center',
});
