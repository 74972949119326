export const PERFORMER_FAQ_DATA = [
  {
    name: 'faq1',
    title: `I signed up, how do I get started?`,
    details: `A great place to get started is by creating your first show! You can create a show, update your EPK and more from your profile.`,
  },
  {
    name: 'faq2',
    title: `Where do audience members purchase tickets to my shows?`,
    details: `Fans can buy presale tickets at TuneHatch.com/shows or via the show listing posted to your website or social media. You can easily auto-update your website though our show calendar embeds and your social media through HatchLink - no more updating multiple websites for every show.`,
  },
  {
    name: 'faq3',
    title: `Where can I monitor my ticket sales?`,
    details: `You and your performers can monitor your ticket sales in real-time by selecting the show on your dashboard and viewing the guestlist or payouts menu.`,
  },
  {
    name: 'faq4',
    title: `How do I get paid?`,
    details: `TuneHatch has partnered with one of the most secure and reliable payment-processing companies on the planet - Stripe. If the venue created the listing, they will initiate your payout after the show. If you created the listing, you may initiate your payout after the show. To set up your payouts account, please click "manage payouts" on your profile and follow the steps provided.`,
  },
  {
    name: 'faq5',
    title: `What does TuneHatch cost?`,
    details: `TuneHatch is free to artists, promoters, and venues.  A small service charge is added to each ticket at the time of purchase – usually $2.00 to $4.00 – which is far below industry standard.`,
  },
  {
    name: 'faq6',
    title: `I manage/lead multiple bands or I am a 3rd party organizer/promoter -  Is TuneHatch right for me?`,
    details: `100% yes. This will be your new favorite tool.  You can book, schedule, track, promote, manage and pay multiple artists for one (or dozens) of shows.`,
  },
  {
    name: 'faq7',
    title: `I’m having issues with a venue I performed at.  Who should I contact?`,
    details: `Please contact the manager or owner of the venue directly.`,
  },
  {
    name: 'faq8',
    title: `I’m having trouble with the website.  Is there an ACTUAL PERSON I can speak with?`,
    details: `We are always happy to help in any way we can! Please email info@tunehatch.com and a service representative will contact you within 24 hours.`,
  },
];
