import Lo from 'lodash';
import { z } from 'zod';
import { Show } from '../../Helpers/shared/Models/Show';
import { Ticket } from '../../Helpers/shared/Models/Ticket';
import { baseAPI, RTKTags } from './BaseAPI';
import { publicAPI } from './PublicAPI';
import { putRequest, deleteRequest, getRequest, postRequest } from './Request/ApiRequest';
import { handleApiErrors } from './Request/handleErrors';
import { RTKQueryResult, RTKMutationResult } from './RTKTypes';

export namespace VenueApi {
  const metaPageSchema = z.object({
    id: z.string(),
    name: z.string(),
    fbPageId: z.string(),
    igPageId: z.string().nullable(),
  });
  export type MetaPage = z.TypeOf<typeof metaPageSchema>;

  const shareStatusSchema = z.object({
    facebook: z
      .object({
        shareKey: z.string(),
        scheduledAt: z.string().nullable(),
        jobId: z.string().nullable(),
        sharedAt: z.string().nullable(),
        error: z.string().nullable(),
      })
      .nullable(),
    instagram: z
      .object({
        shareKey: z.string(),
        scheduledAt: z.string().nullable(),
        jobId: z.string().nullable(),
        sharedAt: z.string().nullable(),
        error: z.string().nullable(),
      })
      .nullable(),
  });
  export type ShareStatus = z.TypeOf<typeof shareStatusSchema>;

  export namespace GrantMetaAccess {
    const schema = z.object({
      venueId: z.string(),
      fbExchangeToken: z.string(),
    });
    export type Params = z.TypeOf<typeof schema>;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await putRequest({
        endpoint: `/v2/venues/${parsed.venueId}/meta-credentials`,
        data: Lo.omit(parsed, 'venueId'),
      });
    };
  }

  export namespace RevokeMetaAccess {
    const schema = z.object({
      venueId: z.string(),
    });
    export type Params = z.TypeOf<typeof schema>;
    export const Request = async (params: Params) => {
      const parsed = schema.parse(params);
      return await deleteRequest({
        endpoint: `/v2/venues/${parsed.venueId}/meta-credentials`,
      });
    };
  }

  export namespace UpdateShowCreatePermissions {
    const paramsSchema = z.object({
      venueId: z.string(),
      applyToExistingShows: z.boolean(),
      isOpenForManagers: z.boolean(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      return await putRequest({
        endpoint: `/v2/venues/${parsed.venueId}/is-open-for-managers`,
        data: Lo.omit(params, 'venueId'),
      });
    };
  }

  export namespace GetMetaCredentials {
    const paramsSchema = z.object({
      venueId: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    const responseSchema = z.object({
      credentials: z
        .object({
          pages: z.array(metaPageSchema),
        })
        .nullable(),
      requiredScopes: z.array(z.string()),
    });
    export type Response = z.TypeOf<typeof responseSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const response = await getRequest({
        endpoint: `/v2/venues/${parsed.venueId}/meta-credentials`,
      });

      return responseSchema.parse(response);
    };
  }

  export namespace ShareFlyer {
    const paramsSchema = z.object({
      venueId: z.string(),
      showId: z.string(),
      pageId: z.string(),
      shareToInstagram: z.boolean(),
      shareToFacebook: z.boolean(),
      shareAt: z.string().nullable(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    const responseSchema = shareStatusSchema;
    export type Response = z.TypeOf<typeof responseSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const result = await postRequest({
        endpoint: `/v2/venues/${parsed.venueId}/shows/${params.showId}/shared-flyers`,
        data: Lo.omit(parsed, 'venueId', 'showId'),
        timeout: 30000,
      });
      return responseSchema.parse(result);
    };
  }

  export namespace CancelScheduledShare {
    const paramsSchema = z.object({
      venueId: z.string(),
      showId: z.string(),
      jobId: z.string(),
    });
    export type Params = z.TypeOf<typeof paramsSchema>;
    const responseSchema = shareStatusSchema;
    export type Response = z.TypeOf<typeof responseSchema>;
    export const Request = async (params: Params) => {
      const parsed = paramsSchema.parse(params);
      const result = await deleteRequest({
        endpoint: `/v2/venues/${parsed.venueId}/shows/${params.showId}/shared-flyers/${params.jobId}`,
      });
      return responseSchema.parse(result);
    };
  }
}

export const venueAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getShowNotes: builder.query<any, { SECRET_UID: string; showID: string; venueID: string }>({
      query: (args) => ({
        method: 'POST',
        url: '/venue/get-show-notes',
        body: args,
      }),
      providesTags: (result) => [{ type: 'Show', id: result.showID }],
    }),
    getSoldTickets: builder.query<{
      showID: string,
      venueID: string,
      soldTickets: Record<string, any>
    }, Object>({
      query: (ids) => ({
        url: '/venue/get-sold-tickets',
        method: 'POST',
        body: ids,
      }),
      providesTags: (result) =>
        Object.keys(result!).map((res) => {
          return {
            type: 'Ticket',
            id: res,
          };
        }),
    }),
    calculatePayouts: builder.query<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/venue/calculate-payouts',
        body: args,
      }),
      providesTags: (result, error) => [{ type: 'Show', id: result._key }],
    }),
    redeemTicket: builder.mutation<any, any & Pick<Ticket, 'ticketID'>>({
      query: (args: { showID: any; venueID: any; ticketID: string; redeemed: boolean; uid: string }) => ({
        url: '/venue/redeem-ticket',
        method: 'POST',
        body: {
          SECRET_UID: args.uid,
          showID: args.showID,
          venueID: args.venueID,
          ticketID: args.ticketID,
          redeemed: args.redeemed,
        },
      }),
      invalidatesTags: (result, error) => [{ type: 'Ticket' }],
    }),
    editShowNotes: builder.mutation<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/venue/edit-show-notes',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'Venue' }],
    }),
    createVenue: builder.mutation<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/venue/create-new-venue',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'Venue' }],
    }),
    editVenue: builder.mutation<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/venue/edit-venue',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'Venue', id: result._key }],
    }),
    updatePerformanceAgreement: builder.mutation<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/venue/update-performance-agreement',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'Venue', id: result._key }],
    }),
    deleteShow: builder.mutation<any, any>({
      query: (args) => ({
        method: 'POST',
        url: '/venue/delete-show',
        body: args,
      }),
      invalidatesTags: (result, error) => [{ type: 'Show' }],
    }),
    getShowPayoutStatus: builder.query<any, { SECRET_UID: string; showIDs: any[] }>({
      query: (args: { SECRET_UID: string; showIDs: any[] }) => ({
        method: 'POST',
        url: '/venue/get-show-payout-status',
        body: args,
      }),
      providesTags: (result, error) => {
        console.log(result);
        return [{ type: 'Show', id: result.showID }];
      },
    }),
    sendStripeReminder: builder.mutation<any, any>({
      query: (args: { SECRET_UID: string; venueID: string; id: string; showID: string }) => ({
        method: 'POST',
        url: 'venue/send-stripe-reminder-email',
        body: args,
      }),
    }),
    sendPayment: builder.mutation<any, any>({
      query: (args: { paymentObject: object; SECRET_UID: string; venueID: string }) => ({
        method: 'POST',
        url: '/venue/send-payment',
        body: args,
      }),
      invalidatesTags: (result, error) => {
        return [{ type: 'Show', id: result.showID }];
      },
    }),
    checkCashLedger: builder.query<any, any>({
      query: (args: { SECRET_UID: string; venueID: string; showID?: string }) => ({
        method: 'POST',
        url: '/venue/check-cash-ledger',
        body: args,
      }),
      providesTags: (result, error) => {
        return [{ type: 'CashLedger', id: result.showID }];
      },
    }),
    updateCashLedger: builder.mutation<any, any>({
      query: (args: { SECRET_UID: string; venueID: string; showID: string; type: string; amount: number }) => ({
        method: 'POST',
        url: '/venue/update-cash-ledger',
        body: args,
      }),
      invalidatesTags: (result, error) => {
        console.log(result);
        return [{ type: 'CashLedger', id: result.showID }];
      },
    }),
    updateShowCreatePermissions: builder.mutation<unknown, VenueApi.UpdateShowCreatePermissions.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await VenueApi.UpdateShowCreatePermissions.Request(data));
      },
      invalidatesTags: (result, error, data) => [{ type: 'Venue', id: data.venueId }],
    }),
    grantMetaAccess: builder.mutation<unknown, VenueApi.GrantMetaAccess.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await VenueApi.GrantMetaAccess.Request(data));
      },
      invalidatesTags: (res, err, arg) => [{ type: RTKTags.VenueMetaCredentials, id: `${arg.venueId}` }],
    }),
    revokeMetaAccess: builder.mutation<unknown, VenueApi.RevokeMetaAccess.Params>({
      async queryFn(data) {
        return await handleApiErrors(async () => await VenueApi.RevokeMetaAccess.Request(data));
      },
      invalidatesTags: (res, err, arg) => [{ type: RTKTags.VenueMetaCredentials, id: `${arg.venueId}` }],
    }),
    getMetaCredentials: builder.query<VenueApi.GetMetaCredentials.Response, VenueApi.GetMetaCredentials.Params>({
      async queryFn(params) {
        return {
          data: await VenueApi.GetMetaCredentials.Request(params),
        };
      },
      providesTags: (res, err, arg) => [{ type: RTKTags.VenueMetaCredentials, id: `${arg.venueId}` }],
    }),
    shareFlyer: builder.mutation<VenueApi.ShareFlyer.Response, VenueApi.ShareFlyer.Params>({
      async queryFn(params) {
        return {
          data: await VenueApi.ShareFlyer.Request(params),
        };
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const response: VenueApi.ShareFlyer.Response = (await queryFulfilled).data;
        dispatch(
          publicAPI.util.updateQueryData('getAllShows', undefined, (draft) => {
            return {
              ...draft,
              [args.showId]: {
                ...draft[args.showId],
                shareStatus: response,
              } as Show,
            };
          }),
        );
      },
    }),
    cancelScheduledShare: builder.mutation<
      VenueApi.CancelScheduledShare.Response,
      VenueApi.CancelScheduledShare.Params
    >({
      async queryFn(params) {
        return {
          data: await VenueApi.CancelScheduledShare.Request(params),
        };
      },
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const response: VenueApi.CancelScheduledShare.Response = (await queryFulfilled).data;
        dispatch(
          publicAPI.util.updateQueryData('getAllShows', undefined, (draft) => {
            return {
              ...draft,
              [args.showId]: {
                ...draft[args.showId],
                shareStatus: response,
              } as Show,
            };
          }),
        );
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateVenueMutation,
  useDeleteShowMutation,
  useSendStripeReminderMutation,
  useSendPaymentMutation,
  useEditVenueMutation,
  useUpdatePerformanceAgreementMutation,
  useGetShowNotesQuery,
  useEditShowNotesMutation,
  useCalculatePayoutsQuery,
  useGetShowPayoutStatusQuery,
  useGetSoldTicketsQuery,
  useRedeemTicketMutation,
  useCheckCashLedgerQuery,
  useUpdateCashLedgerMutation,
  useUpdateShowCreatePermissionsMutation,
  useGrantMetaAccessMutation,
  useRevokeMetaAccessMutation,
  useShareFlyerMutation,
  useCancelScheduledShareMutation,
  useGetMetaCredentialsQuery,
} = venueAPI;

export const useMetaCredentials = (
  query: VenueApi.GetMetaCredentials.Params,
): RTKQueryResult<VenueApi.GetMetaCredentials.Response> => useGetMetaCredentialsQuery(query);
export const useGrantMetaAccess = (): RTKMutationResult<VenueApi.GrantMetaAccess.Params, unknown> =>
  useGrantMetaAccessMutation();
export const useRevokeMetaAccess = (): RTKMutationResult<VenueApi.RevokeMetaAccess.Params, unknown> =>
  useRevokeMetaAccessMutation();
export const useShareFlyer = (): RTKMutationResult<VenueApi.ShareFlyer.Params, VenueApi.ShareFlyer.Response> =>
  useShareFlyerMutation();
export const useCancelScheduledShare = (): RTKMutationResult<
  VenueApi.CancelScheduledShare.Params,
  VenueApi.CancelScheduledShare.Response
> => useCancelScheduledShareMutation();
