import { MenuItem } from '@mui/material';
import React, { FC, useCallback } from 'react';
import { TH_GENRES } from '../../../Helpers/configConstants';
import { DataCy } from '../../../Helpers/DataCy';
import { TextField, Select } from '../../../ui/inputs/TextField';
import { Button } from '../../../ui/buttons/Button';
import { PromoterFormData } from './PromoterFormData';
import {
  FormStepHeadlineContainer,
  FormStepInnerContainer,
  FormTitleWithSubtitleContainer,
  FormStepFormContainerOuter,
  FormSectionContainer,
  FormInputGroupWrapper,
  FormInputGroupElementWrapper,
  FormMainContainerColumn,
} from '../Shared/Containers';
import {
  FormStepHeadlineStepCounter,
  FormStepHeadlineTitle,
  FormSectionTitle,
  FormSectionSubtitle,
} from '../Shared/RegistrationTypographies';
import { FormStepProps } from '../Shared/Types';
import { useFormContext } from 'react-hook-form';

export const PromoterFormStep1: FC<FormStepProps> = ({ step, setStep }) => {
  const promoterForm = useFormContext();

  const handleNextStep = useCallback(async () => {
    const isValid = await promoterForm.trigger(PromoterFormData.step1Fields);
    if (isValid === true) {
      setStep((step) => step + 1);
    }
  }, [promoterForm, setStep]);

  return (
    <FormMainContainerColumn>
      <FormStepHeadlineContainer>
        <FormStepInnerContainer>
          <FormTitleWithSubtitleContainer>
            <FormStepHeadlineStepCounter>Step 1 of 3</FormStepHeadlineStepCounter>
            <FormStepHeadlineTitle>Introduce yourself</FormStepHeadlineTitle>
          </FormTitleWithSubtitleContainer>
        </FormStepInnerContainer>
      </FormStepHeadlineContainer>
      <FormStepFormContainerOuter>
        <FormStepInnerContainer>
          <FormSectionContainer>
            <FormSectionTitle>Who are you?</FormSectionTitle>
            <TextField
              {...promoterForm.register('groupName')}
              type="text"
              label="Group Name"
              placeholder="Enter group name"
              error={!!promoterForm.formState.errors?.groupName?.message}
              helperText={
                !!promoterForm.formState.errors?.groupName?.message && (
                  <>{promoterForm.formState.errors?.groupName?.message}</>
                )
              }
            />
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <Select
                  {...promoterForm.register('genre')}
                  type="text"
                  label="Genre"
                  error={!!promoterForm.formState.errors?.genre?.message}
                  helperText={
                    !!promoterForm.formState.errors?.genre?.message && (
                      <>{promoterForm.formState.errors?.genre?.message}</>
                    )
                  }
                  select
                  placeholder="Select"
                  defaultValue={'none'}
                >
                  <MenuItem
                    value="none"
                    disabled
                  >
                    Select
                  </MenuItem>
                  {TH_GENRES.map((genre) => {
                    return (
                      <MenuItem
                        key={genre}
                        value={genre}
                      >
                        {genre}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...promoterForm.register('subGenre')}
                  type="text"
                  label="Any sub-genre? (Optional)"
                  placeholder="Enter sub genre"
                  error={!!promoterForm.formState.errors?.subGenre?.message}
                  helperText={
                    !!promoterForm.formState.errors?.subGenre?.message && (
                      <>{promoterForm.formState.errors?.subGenre?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
          </FormSectionContainer>
          <FormSectionContainer>
            <FormSectionTitle>Contact Details</FormSectionTitle>
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...promoterForm.register('firstName')}
                  type="text"
                  label="First Name"
                  placeholder="Enter first name"
                  error={!!promoterForm.formState.errors?.firstName?.message}
                  helperText={
                    !!promoterForm.formState.errors?.firstName?.message && (
                      <>{promoterForm.formState.errors?.firstName?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...promoterForm.register('lastName')}
                  type="text"
                  label="Last Name"
                  placeholder="Enter last name"
                  error={!!promoterForm.formState.errors?.lastName?.message}
                  helperText={
                    !!promoterForm.formState.errors?.lastName?.message && (
                      <>{promoterForm.formState.errors?.lastName?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...promoterForm.register('contact.phone')}
                  type="tel"
                  label="Contact phone"
                  placeholder="Enter contact phone"
                  error={promoterForm.getFieldState('contact.phone').invalid}
                  helperText={
                    promoterForm.getFieldState('contact.phone').invalid && (
                      <>{promoterForm.getFieldState('contact.phone').error?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...promoterForm.register('contact.email')}
                  type="email"
                  label="Contact Email"
                  placeholder="Enter contact email"
                  error={promoterForm.getFieldState('contact.email').invalid}
                  helperText={
                    promoterForm.getFieldState('contact.email').invalid && (
                      <>{promoterForm.getFieldState('contact.email').error?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
          </FormSectionContainer>
          <FormSectionContainer>
            <FormTitleWithSubtitleContainer>
              <FormSectionTitle>Where do you want to play?</FormSectionTitle>
              <FormSectionSubtitle>
                Your primary city is where you play regularly, when your secondary city is where you play 2nd most
                often.
              </FormSectionSubtitle>
            </FormTitleWithSubtitleContainer>
            <FormInputGroupWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...promoterForm.register('primaryCity')}
                  type="text"
                  label="Primary City"
                  placeholder="Enter your primary city"
                  error={!!promoterForm.formState.errors?.primaryCity?.message}
                  helperText={
                    !!promoterForm.formState.errors?.primaryCity?.message && (
                      <>{promoterForm.formState.errors?.primaryCity?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
              <FormInputGroupElementWrapper>
                <TextField
                  {...promoterForm.register('secondaryCity')}
                  type="text"
                  label="Secondary City (Optional)"
                  placeholder="Enter your secondary city"
                  error={!!promoterForm.formState.errors?.secondaryCity?.message}
                  helperText={
                    !!promoterForm.formState.errors?.secondaryCity?.message && (
                      <>{promoterForm.formState.errors?.secondaryCity?.message}</>
                    )
                  }
                />
              </FormInputGroupElementWrapper>
            </FormInputGroupWrapper>
          </FormSectionContainer>
          <Button
            isPrimary={true}
            onClick={handleNextStep}
            size="large"
            data-cy={DataCy.button('nextStep')}
          >
            Next
          </Button>
        </FormStepInnerContainer>
      </FormStepFormContainerOuter>
    </FormMainContainerColumn>
  );
};
